import { forwardRef } from "react";
import styles from "./StripeWrapper.module.css";
import clsx from "clsx";
import useInlineStyles from "src/hooks/global/useInlineStyles";

const StripeWrapper = forwardRef(function StripeWrapper(
  {
    type,
    name,
    label,
    placeholder,
    background,
    mandatory = false,
    onChange: onChangeProp = () => {},
    validation = () => true,
    message,
    disabled,
    link = {},
    value: overideValue,
    setValue: setOverideValue = () => {},
    inActive = false,
    className,
    boxShadow,
    children,
    ...props
  },
  ref
) {
  const style = useInlineStyles({ ...props });

  return (
    <div className={styles["input-wrapper"]}>
      {label && <label className={clsx(styles["label"], "small")}>{label}</label>}
      <div className={clsx(styles["input-container"], className)} style={style}>
        {children}
      </div>
    </div>
  );
});

export default StripeWrapper;
