import clsx from "clsx";
import useStyling from "src/hooks/global/useStyling";
import styles from "./Tooltip.module.css";

const Tooltip = ({ title, text, position, children, className }) => {
  const styling = useStyling(styles);

  return (
    <div className={clsx(styling("container"), className)}>
      <div className={clsx(styling("tooltip-container"), styling(position))}>
        <div className={clsx(styling("tooltip-arrow"), styling(position))}></div>
        <div className={styling("tooltip-content")}>
          <h6>{title}</h6>
          <p>{text}</p>
        </div>
      </div>
      {children}
    </div>
  );
};

export default Tooltip;
