import { useState, useRef, useEffect } from "react";
import styles from "./SFilters.module.css";
import useStyling from "src/hooks/global/useStyling";
import Calendar from "src/components/UI/inputs/Calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-light-svg-icons";
import RadioButtons from "src/components/UI/inputs/RadioButtons";

const getEndOfMonth = (date) => {
  const endOfMonth = new Date(date);
  endOfMonth.setMonth(endOfMonth.getMonth() + 1);
  endOfMonth.setDate(0);
  endOfMonth.setHours(23, 59, 59, 999);
  return endOfMonth;
};

const getNextTwelveMonths = () => {
  const months = [];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  for (let i = 0; i <= 12; i++) {
    const currentDate = new Date();
    currentDate.setDate(1);
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setMonth(currentDate.getMonth() + i);

    let month = monthNames[currentDate.getMonth()];
    let year = currentDate.getFullYear();
    months.push({ month: month, year: year, date: currentDate });
  }

  return months;
};

const SFWhen = ({ when = [], setWhen }) => {
  const styling = useStyling(styles);

  const [selectedMonth, setSelectedMonth] = useState("");

  const calenderRef = useRef();
  const radioRef = useRef();

  useEffect(() => {
    if (!selectedMonth) return;
    calenderRef.current?.refresh();
    setWhen([selectedMonth, getEndOfMonth(selectedMonth)]);
  }, [selectedMonth, setWhen]);

  return (
    <div className={styling("container")}>
      <Calendar
        ref={calenderRef}
        minDate={new Date()}
        range
        value={when}
        setValue={(v) => {
          if (!v.length) return;
          radioRef.current.refresh();
          setWhen(v);
        }}
      />
      <div className={styles["date-content-container"]}>
        <h5>Or go anytime in ...</h5>
        <RadioButtons
          ref={radioRef}
          type="cards"
          value={selectedMonth}
          setValue={setSelectedMonth}
          options={getNextTwelveMonths().map((month) => {
            return {
              label: month.month,
              value: month.date,
              content: (
                <div className={styles["date-radio-card"]}>
                  <FontAwesomeIcon icon={faCalendar} />
                  <h6>{month.month}</h6>
                  <p className="caption-large">{month.year}</p>
                </div>
              ),
            };
          })}
        />
      </div>
    </div>
  );
};

export default SFWhen;
