import SideLayout from "src/components/layout/SideLayout";
import styles from "../EmploymentDetails.module.css";
import useStyling from "src/hooks/global/useStyling";
import FormV2 from "src/components/func/FormV2";
import { useCallback, useMemo } from "react";
import Button from "src/components/UI/buttons/Button";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import Input from "src/components/UI/inputs/Input";
import useAuth from "src/hooks/global/useAuth";
import useFetch from "src/hooks/global/useFetch";
import Dropdown from "src/components/UI/inputs/Dropdown";
import { formatDateYYYY_MM_DD } from "src/utility/format";

const EDPersonalDetials = (props) => {
  const styling = useStyling(styles);
  const { user } = useAuth();

  const { post, isLoading, isError, refresh } = useFetch({
    options: {
      onSuccess: (data) => {
        refresh("initAuth");
      },
    },
  });

  const handleSubmit = useCallback(
    async (data) => {
      return await post({
        entity: "User",
        method: "update",
        criteria: { _id: user._id },
        data,
      });
    },
    [post, user]
  );

  const initial = useMemo(() => {
    return {
      ...user,
      date_of_birth: user?.date_of_birth ? formatDateYYYY_MM_DD(new Date(user?.date_of_birth)) : null,
      passport_expiry: user?.passport_expiry ? formatDateYYYY_MM_DD(new Date(user?.passport_expiry)) : null,
    };
  }, [user]);

  return (
    <SideLayout heading={"Personal Details"} {...props}>
      <div className={styling("form-container")}>
        <FormV2 onSubmit={handleSubmit} initial={initial}>
          <Input name={"first_name"} />
          <Input name={"last_name"} />
          <Dropdown name={"gender"} />
          <Input name={"date_of_birth"} />
          <Dropdown name={"passport_country"} />
          <Input name={"passport_number"} />
          <Input name={"passport_expiry"} />
          <Button
            loading={isLoading}
            error={isError}
            theme={"secondary"}
            type="submit"
            className={styling("save-btn")}
            icon={faSave}
          >
            Save Personal Details
          </Button>
          <Button
            theme={"secondary-transparent"}
            className={styling("save-btn")}
            onClick={() => {
              props.setShow(false);
            }}
          >
            Cancel
          </Button>
        </FormV2>
      </div>
    </SideLayout>
  );
};

export default EDPersonalDetials;
