import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import styles from "./Calendar.module.css";
import ReactCalendar from "react-calendar";
import { stringToDate } from "src/utility/date";
import clsx from "clsx";
import withInputBase from "src/components/HOC/withInputBase";
import useStyling from "src/hooks/global/useStyling";

const days = ["S", "M", "T", "W", "T", "F", "S"];

const Calendar = forwardRef(
  (
    {
      label = "",
      minView = "year",
      minDate,
      maxDate,
      mode = "tap",
      range = false,
      value,
      setValue = () => {},
      required,
      error,
    },
    ref
  ) => {
    const styling = useStyling(styles);

    const [selectedDate, setSelectedDate] = useState(value || [new Date(), new Date()]);
    const startDateRef = useRef(new Date());

    useImperativeHandle(
      ref,
      () => ({
        refresh: () => {
          setSelectedDate(mode !== "drag" && range ? [] : null);
        },
      }),
      [mode, range]
    );

    const handleTouchMove = useCallback(
      (event) => {
        const touch = event.touches[0];
        const targetElement = document.elementFromPoint(touch.clientX, touch.clientY);

        if (targetElement && targetElement.className.includes(styling("tile-controller"))) {
          const dateString = targetElement.getAttribute("data-date");
          const date = stringToDate(dateString);
          if (!minDate || date >= minDate) {
            setSelectedDate([startDateRef.current, date]);
          }
        }
      },
      [startDateRef, minDate, styling]
    );

    useEffect(() => {
      setValue(range ? (selectedDate[1] ? selectedDate : [selectedDate[0], selectedDate[0]]) : selectedDate);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    useEffect(() => {
      if (mode !== "drag") return;
      window.addEventListener("touchmove", handleTouchMove);
      return () => {
        window.removeEventListener("touchmove", handleTouchMove);
      };
    }, [handleTouchMove, mode]);

    return (
      <div className={styling("container")}>
        {label && (
          <p className={clsx(styling("label"))}>
            {label}:{required && <span>*</span>}
          </p>
        )}
        <ReactCalendar
          value={selectedDate}
          className={styling("calendar")}
          onChange={setSelectedDate}
          formatShortWeekday={(locale, date) => days[date.getDay()]}
          minDate={minDate}
          allowPartialRange
          selectRange={mode !== "drag" && range}
          maxDate={maxDate}
          minDetail={minView}
          tileContent={({ date }) => (
            <div
              key={date.toLocaleDateString()}
              className={styling("tile-controller")}
              onTouchStart={() => (startDateRef.current = date)}
              data-date={date.toLocaleDateString()}
            >
              {date.toLocaleDateString()}
            </div>
          )}
        />
        {error && (
          <div className={styling("error-container")}>
            <p className={styling("message")}>{error}</p>
          </div>
        )}
      </div>
    );
  }
);

export default withInputBase(Calendar);
