import withInputBase from "src/components/HOC/withInputBase";
import styles from "./TimeSelector.module.css";
import useStyling from "src/hooks/global/useStyling";
import withInputWrapper from "src/components/HOC/withInputWrapper";
import { forwardRef } from "react";

const roundMinutes = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  const roundedMinutes = Math.round(minutes / 15) * 15;
  return `${String(hours).padStart(2, "0")}:${String(roundedMinutes).padStart(2, "0")}`;
};

const TimeSelector = forwardRef(
  (
    {
      formProps,
      type = "text",
      value,
      setValue,
      submitOnEnter,
      validate,
      defaults: { validate: defaultValidate, required: defaultRequired, ...defaults },
      required,
      specialButton,
      ...props
    },
    ref
  ) => {
    const styling = useStyling(styles);

    return (
      <div className={styling("container")}>
        <input
          ref={ref}
          type="time"
          {...defaults}
          {...props}
          {...formProps}
          onBlur={(e) => {
            const roundedTime = roundMinutes(e.target.value);
            setValue(roundedTime);
          }}
          placeholder="HH:MM"
          step="60"
        />
      </div>
    );
  }
);

export default withInputBase(withInputWrapper(TimeSelector));
