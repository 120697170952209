import styles from "./TermsAndConditions.module.css";
import useStyling from "src/hooks/global/useStyling";

const TermsAndConditions = () => {
  const styling = useStyling(styles);
  return (
    <>
      <h1>Coming soon.</h1>
    </>
  );
};

export default TermsAndConditions;
