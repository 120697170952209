import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ActionsDropdown.module.css";
import useStyling from "src/hooks/global/useStyling";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { motion } from "framer-motion";

const variants = {
  visible: {
    height: "auto",
  },
  hidden: {
    height: 0,
  },
};

const ActionsDropdown = ({ actions = [] }) => {
  const styling = useStyling(styles);
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={styling("container")}>
        <div className={styling("button")} onClick={() => setVisible((prev) => !prev)}>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
        <motion.div
          variants={variants}
          initial={"hidden"}
          animate={visible ? "visible" : "hidden"}
          transition={{ duration: 0.2 }}
          className={styling("dropdown", visible && "visible")}
        >
          {actions.map((action, index) => (
            <div
              key={index}
              className={styling("action")}
              onClick={() => {
                if (action.action) {
                  action.action();
                }
                setVisible(false);
              }}
            >
              <p>{action.label}</p>
            </div>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default ActionsDropdown;
