import { useMemo, useCallback } from "react";
import useProfile from "./useProfile";
import useAuth from "../global/useAuth";
import useFetch from "../global/useFetch";

const useOnboardingComplete = () => {
  const { profile } = useProfile();
  const { user } = useAuth();

  const {
    data: [bankAccount],
    isLoading: bankIsLoading,
  } = useFetch({
    request: { entity: "BankAccount", criteria: { user: user?._id }, id: "BankAccount.GetBankAccount" },
    dependency: user?._id,
  });

  const {
    data: [superDetails],
    isLoading: superIsLoading,
  } = useFetch({
    request: {
      entity: "SuperMembership",
      criteria: { user: user?._id },
      id: "SuperMembership.GetSuperMembership",
    },
    dependency: user?._id,
  });

  const {
    data: [taxDeclaration],
    isLoading: taxIsLoading,
  } = useFetch({
    request: {
      entity: "TaxDeclaration",
      method: "get",
      criteria: { user: user?._id },
      id: "TaxDeclaration.GetTaxDeclaration",
    },
    dependency: user?._id,
  });

  const calculateTodo = useCallback(() => {
    if (bankIsLoading || superIsLoading || taxIsLoading)
      return {
        bio: true,
        qualifications: true,
        workExperience: true,
        personalDetails: true,
        contactDetails: true,
        bankDetails: true,
        taxDetails: true,
        superDetails: true,
        total: true,
      };

    const { bio, qualifications = [], work_experience = [] } = profile || {};
    const { bsb, account_number } = bankAccount || {};
    const { tax_file_number } = taxDeclaration || {};
    const { super_fund } = superDetails || {};
    const { first_name, last_name, phone_number, passport_number, passport_country, passport_expiry } = user || {};

    const allComplete =
      passport_number &&
      passport_country &&
      passport_expiry &&
      bio &&
      qualifications.length > 0 &&
      work_experience.length > 0 &&
      first_name &&
      last_name &&
      phone_number &&
      bsb &&
      account_number &&
      tax_file_number &&
      !!super_fund;

    return {
      bio: !!bio,
      qualifications: qualifications.length > 0,
      workExperience: work_experience.length > 0,
      personalDetails: !!(first_name && last_name && passport_number && passport_country && passport_expiry),
      contactDetails: !!phone_number,
      bankDetails: !!(bsb && account_number),
      taxDetails: !!tax_file_number,
      superDetails: !!super_fund,
      total: allComplete,
    };
  }, [profile, bankAccount, superDetails, taxDeclaration, user, bankIsLoading, superIsLoading, taxIsLoading]);

  const todo = useMemo(calculateTodo, [calculateTodo]);

  return useMemo(
    () => ({
      todo,
      taxDeclaration,
      bankAccount,
      superDetails,
      isLoading: bankIsLoading || superIsLoading || taxIsLoading,
    }),
    [todo, taxDeclaration, bankAccount, superDetails, bankIsLoading, superIsLoading, taxIsLoading]
  );
};

export default useOnboardingComplete;
