import { useEffect, useState } from "react";
import styles from "./SFilters.module.css";
import useStyling from "src/hooks/global/useStyling";
import AddressAutocomplete from "src/components/UI/inputs/AddressAutoComplete";
import Increment from "src/components/UI/inputs/Increment";

const SFWhere = ({ where = {}, setWhere }) => {
  const styling = useStyling(styles);
  const [location, setLocation] = useState({});

  useEffect(() => {
    if (location.place_id) {
      const { longitude, latitude, full_address } = location;
      setWhere((prev) => ({
        ...prev,
        longitude,
        latitude,
        fullAddress: full_address,
      }));
    }
  }, [location, setWhere]);

  return (
    <div className={styling("container")}>
      <AddressAutocomplete
        fullAddress={false}
        onChange={setLocation}
        label={"Location"}
        placeholder={'Try "Sydney"...'}
        initialValue={where.fullAddress}
      />
      <div className={styling("increments")}>
        <Increment
          label={"In range"}
          unit={"km"}
          step={5}
          value={where?.range || 0}
          setValue={(range) => setWhere((prev) => ({ ...prev, range }))}
        />
      </div>
    </div>
  );
};

export default SFWhere;
