import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useBusiness from "../business/useBusiness";
import usePositions from "../business/usePositions";
import useShiftlyLocation from "../business/useShiftlyLocation";
import useAuth from "./useAuth";
import { useCopilotReadable, useCopilotAction } from "@copilotkit/react-core";
import useFetch from "./useFetch";
import moment from "moment";
import useToday from "./useToday";
import responsibilities from "@shiftly/shared/utility/Responsibilities";

const useAI = () => {
  const { user } = useAuth();
  const { activeBusiness } = useBusiness();
  const { activeLocation } = useShiftlyLocation();
  const { availablePositions } = usePositions();
  const navigate = useNavigate();
  const { post, refresh } = useFetch({});

  const { time, today } = useToday();
  const award_code = useMemo(() => activeBusiness?.industry?.award_code, [activeBusiness?.industry?.award_code]);

  /********************************************************** Readable ********************************************************** */

  useCopilotReadable(
    useMemo(
      () => ({
        description: "Contextual information about the current date, time, day of week, month and country.",
        value: {
          time,
          today,
          dayOfWeek: moment(today).format("dddd"),
          month: moment(today).format("MMMM"),
          year: moment(today).format("YYYY"),
          country: "Australia",
          timezone: activeLocation?.timezone,
        },
      }),
      [today, time, activeLocation]
    )
  );

  useCopilotReadable(
    useMemo(
      () => ({
        description:
          "The currently logged-in user. Use this object for fetching user-specific data (e.g., name, email).",
        value: user,
      }),
      [user]
    )
  );

  useCopilotReadable(
    useMemo(
      () => ({
        description: "The business currently active within Shiftly.",
        value: activeBusiness,
      }),
      [activeBusiness]
    )
  );

  useCopilotReadable(
    useMemo(
      () => ({
        description:
          "The currently active location within the business, which is tied to shifts, positions, and internal staff.",
        value: activeLocation,
      }),
      [activeLocation]
    )
  );

  useCopilotReadable(
    useMemo(
      () => ({
        description:
          "All positions available for the active location. Use the `_id` field to perform any actions on positions. Group positions by the `group` field for better organization. When creating shifts, always use the individual position's `_id`.",
        value: availablePositions,
      }),
      [availablePositions]
    )
  );

  useCopilotReadable(
    useMemo(
      () => ({
        description: "The award code tied to the current business. Use this to reference award details when needed.",
        value: award_code,
      }),
      [award_code]
    )
  );

  useCopilotReadable(
    useMemo(
      () => ({
        description:
          "A list of responsibilities mapped by classification level for each position. The classification level corresponds to the `classification_level` field in positions. The `group` field indicates the stream each position belongs to.",
        value: responsibilities[award_code],
      }),
      [award_code]
    )
  );

  /********************************************************** Actions ********************************************************** */

  useCopilotAction(
    useMemo(
      () => ({
        name: "managePositions",
        description: "Navigate to the 'Positions Template' page to create, edit, or delete positions.",
        parameters: [],
        handler: async () => {
          navigate("/positions");
        },
      }),
      [navigate]
    )
  );

  // Get Shifts for Dates
  useCopilotAction(
    useMemo(
      () => ({
        name: "getShiftsForDates",
        description: "Retrieve all shifts between two specified dates.",
        parameters: [
          {
            type: "date",
            name: "startDate",
            description: "The start date for the shift search. Must be a future date.",
            required: true,
          },
          {
            type: "date",
            name: "endDate",
            description:
              "The end date for the shift search. Must be after the start date and within a 2-10 hour range.",
            required: true,
          },
        ],
        handler: async ({ startDate, endDate }) => {
          if (!startDate || !endDate) {
            return "Please provide both a start date and an end date.";
          }

          const response = await post({
            entity: "Shift",
            method: "getAndPopulate",
            populate: ["user", "position"],
            criteria: {
              location: activeLocation?._id,
              start_time: {
                $gte: new Date(startDate).setHours(0, 0, 0, 0),
                $lte: new Date(endDate).setHours(23, 59, 59, 999),
              },
            },
          });

          if (!response || response.length === 0) {
            return "No shifts found within the specified date range.";
          }

          const shiftsGroupedByPosition = response.reduce((acc, shift) => {
            const positionName = shift.position.name;
            if (!acc[positionName]) {
              acc[positionName] = [];
            }
            acc[positionName].push(shift);
            return acc;
          }, {});

          let structuredResponse = `There are ${response.length} shift(s) between ${startDate} and ${endDate}.\n\nHere's a breakdown by position:\n\n`;

          Object.keys(shiftsGroupedByPosition).forEach((position) => {
            structuredResponse += `### Position: ${position}\n`;
            shiftsGroupedByPosition[position]
              .sort((a, b) => new Date(a.start_time) - new Date(b.start_time))
              .forEach((shift) => {
                structuredResponse += `- **Level**: ${shift.position?.classification_level}\n`;
                structuredResponse += `  - **Start Time**: ${new Date(shift.start_time).toLocaleString()}\n`;
                structuredResponse += `  - **End Time**: ${new Date(shift.end_time).toLocaleString()}\n\n`;
              });
          });

          structuredResponse += "Would you like to make any changes to these shifts?";

          return { structuredResponse, shifts: response };
        },
      }),
      [post, activeLocation]
    )
  );

  // Create a shift
  useCopilotAction(
    useMemo(
      () => ({
        name: "createShift",
        description: "Create a new shift.",
        parameters: [
          {
            type: "string",
            name: "type",
            description: "Whether the shift is internal or published on Shiftly.",
            enum: ["internal", "published"],
          },
          {
            type: "date",
            name: "date",
            description: "The date of the shift.",
            required: true,
          },
          {
            type: "date",
            name: "startTime",
            description: "The start time of the shift.",
            required: true,
          },
          {
            type: "date",
            name: "endTime",
            description: "The end time of the shift.",
            required: true,
          },
          {
            type: "string",
            name: "position",
            description: "The position ID for the shift.",
            required: true,
          },
          {
            type: "number",
            name: "classificationLevel",
            description: "The classification level for the shift, based on the award.",
            enum: [1, 2, 3, 4, 5],
            required: true,
          },
          {
            type: "string",
            name: "rateModifier",
            description: "Additional pay on top of the regular rate.",
          },
        ],
        handler: async ({ date, startTime, endTime, position: posId, classificationLevel, rateModifier }) => {
          try {
            const positionGroup = availablePositions.find((pos) => pos._id === posId)?.group;
            const position = availablePositions.find(
              (pos) => pos.group?._id === positionGroup?._id && pos.classification_level === classificationLevel
            );

            if (!position) {
              throw new Error("Position not found.");
            }

            const start_time = moment(`${date} ${startTime}`, "YYYY-MM-DD HH:mm").toDate();
            const end_time = moment(`${date} ${endTime}`, "YYYY-MM-DD HH:mm").toDate();

            const shift = {
              award_code: activeBusiness?.industry?.award_code,
              business: activeBusiness._id,
              industry: activeBusiness?.industry?._id,
              location: activeLocation._id,
              timezone: activeLocation.timezone,
              position: position?._id,
              position_group: positionGroup?._id,
              start_time,
              end_time,
              shift_rate_modifier: rateModifier,
              status: "unpublished",
              responsibilities: position?.responsibilities,
            };

            console.log(shift);

            const { error } = await post({
              entity: "Shift",
              method: "create",
              data: shift,
            });

            if (error) {
              throw new Error(error.message);
            }

            refresh("Shift.ShiftsBetweenDates");

            return "Shift created successfully in draft mode. Please review and publish.";
          } catch (error) {
            return "Error creating shift: " + error?.message;
          }
        },
      }),
      [post, activeLocation, availablePositions, activeBusiness, refresh]
    )
  );

  // Update or Edit a shift
  useCopilotAction(
    useMemo(
      () => ({
        name: "updateOrEditShift",
        description: "Update the details of an existing shift.",
        parameters: [
          {
            type: "string",
            name: "_id",
            description:
              "The _id field on the shift object that we are editing. For example if we are moving a shift, we will need to pass the _id of the shift we are moving.",
          },
          {
            type: "string",
            name: "type",
            description: "The type of shift: internal or published.",
            enum: ["internal", "published"],
          },
          {
            type: "date",
            name: "date",
            description: "The date of the shift.",
            required: true,
          },
          {
            type: "date",
            name: "startTime",
            description: "The start time of the shift.",
            required: true,
          },
          {
            type: "date",
            name: "endTime",
            description: "The end time of the shift.",
            required: true,
          },
          {
            type: "string",
            name: "position",
            description: "The position ID for the shift.",
            required: true,
          },
          {
            type: "number",
            name: "classificationLevel",
            description: "The classification level for the shift, based on the award.",
            enum: [1, 2, 3, 4, 5],
            required: true,
          },
          {
            type: "string",
            name: "rateModifier",
            description: "Additional pay on top of the regular rate.",
          },
        ],
        handler: async ({ _id, date, startTime, endTime, position: posId, classificationLevel, rateModifier }) => {
          try {
            const positionGroup = availablePositions.find((pos) => pos._id === posId)?.group;
            const position = availablePositions.find(
              (pos) => pos.group?._id === positionGroup?._id && pos.classification_level === classificationLevel
            );

            if (!position) {
              throw new Error("Position not found.");
            }

            const start_time = moment(`${date} ${startTime}`, "YYYY-MM-DD HH:mm").toDate();
            const end_time = moment(`${date} ${endTime}`, "YYYY-MM-DD HH:mm").toDate();

            const shift = {
              _id,
              award_code: activeBusiness?.industry?.award_code,
              business: activeBusiness._id,
              location: activeLocation._id,
              timezone: activeLocation.timezone,
              position: position?._id,
              position_group: positionGroup?._id,
              start_time,
              end_time,
              shift_rate_modifier: rateModifier,
              status: "unpublished",
              responsibilities: position?.responsibilities,
            };

            const { error } = await post({
              entity: "Shift",
              method: "editShift",
              data: shift,
            });

            if (error) {
              throw new Error(error.message);
            }

            refresh("Shift.ShiftsBetweenDates");

            return "Shift successfully updated and placed in draft mode. Please review and publish.";
          } catch (error) {
            return "Error updating shift: " + error?.message;
          }
        },
      }),
      [post, activeLocation, availablePositions, activeBusiness, refresh]
    )
  );
};

export default useAI;
