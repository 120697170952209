import styles from "./Portal.module.css";
import { useLayoutEffect, useState } from "react";
import LoginShifter from "./Login";
import SignUpShifter from "./SignUp";
import LoginBusiness from "../Business/Portal/LoginBusiness";
import SignUpBusiness from "../Business/Portal/SignupBusiness";
import ResetShifter from "./ResetPassword";
import withDisplay from "src/components/HOC/withDisplay";
import WelcomeScreen from "src/components/misc/WelcomeScreen";

const Portal = ({ isMobile, isTablet }) => {
  const [page, setPage] = useState();
  const [mode, setMode] = useState();

  useLayoutEffect(() => {
    const url = new URL(window.location.href);
    const page = url.searchParams.get("page");

    setPage(page || !isMobile ? "login" : "welcome");

    if (isMobile) {
      setMode("shifter");
    } else {
      setMode("owner");
    }
  }, [isMobile, isTablet]);

  return (
    <div className={styles["container"]}>
      {page === "welcome" && <WelcomeScreen setPage={setPage} />}
      {mode === "shifter" ? (
        <>
          {page === "login" && <LoginShifter setPage={setPage} setMode={setMode} />}
          {page === "signup" && <SignUpShifter setPage={setPage} setMode={setMode} />}
          {page === "reset" && <ResetShifter setPage={setPage} />}
        </>
      ) : (
        <>
          {page === "login" && <LoginBusiness setPage={setPage} setMode={setMode} />}
          {page === "signup" && <SignUpBusiness setPage={setPage} setMode={setMode} />}
        </>
      )}
    </div>
  );
};
export default withDisplay(Portal);
