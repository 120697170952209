import useAuth from "src/hooks/global/useAuth";
import styles from "./MessageBubbleShifter.module.css";
import clsx from "clsx";
import { formatDateToPrettyTime } from "src/utility/date";
import useShiftlyLocation from "src/hooks/business/useShiftlyLocation";

const MessageBubbleShifter = ({ content, for_id, sent_date, after }) => {
  const { user } = useAuth();
  const { activeLocation } = useShiftlyLocation();
  const forMe = user._id === for_id || activeLocation?._id === for_id;
  const showTime = !after || new Date(after.sent_date).getTime() - new Date(sent_date).getTime() > 300000;

  return (
    <div className={styles["container"]}>
      <div className={clsx(styles["bubble"], forMe ? styles["receiving"] : styles["sending"])}>
        <p className={styles["text"]}>{content}</p>
      </div>
      {showTime && (
        <p className={clsx(styles["sent_time"], forMe ? styles["left"] : styles["right"])}>
          {formatDateToPrettyTime(sent_date)}
        </p>
      )}
    </div>
  );
};

export default MessageBubbleShifter;
