import { forwardRef, useCallback, useRef, useImperativeHandle, useState } from "react";
import styles from "./FileUploader.module.css";
import Button from "src/components/UI/buttons/Button";
import useToast from "src/hooks/global/useToast";
import useFetch from "src/hooks/global/useFetch";
import withInputBase from "src/components/HOC/withInputBase";

const FileUploader = forwardRef(
  (
    {
      buttonText = "Upload",
      buttonProps,
      uploadOnSubmit = true,
      onUploadComplete = () => {},
      maxFileCount = Infinity,
      multiple = true,
      accepts = "*",
      visible = true,
      showToast = true,
      formProps: { value: filesArray, ...formProps },
      value,
      setValue,
      submitOnEnter,
      validate,
      label,
      defaults: { validate: defaultValidate, required: defaultRequired, ...defaults },
      required,
      ...props
    },
    ref
  ) => {
    const fileUploaderRef = useRef(formProps.ref);

    const [files, setFiles] = useState([]);
    const [inputValue, setInputValue] = useState(value);

    const { post: postFiles, isLoading } = useFetch({
      options: {
        onMutate: () => {
          showToast && toast.info("Uploading your files, please wait...");
        },
        onSuccess: async (data) => {
          await onUploadComplete(data);
          setValue(data);
        },
      },
    });

    const toast = useToast();

    const submitFiles = useCallback(
      async (filesParam) => {
        const filesToUpload = filesParam || files;

        if (!filesToUpload.length) return;

        if (filesToUpload.length > maxFileCount) {
          toast.info("Too many files", `You can only upload ${maxFileCount} files.`);
        }
        const formdata = new FormData();
        for (let i = 0; i < Math.min(filesToUpload.length, maxFileCount); i++) {
          formdata.append("files", filesToUpload[i]);
        }

        postFiles({
          node: "docs-node",
          data: formdata,
        });
      },

      [files, maxFileCount, postFiles, toast]
    );

    useImperativeHandle(ref, () => ({
      click: () => fileUploaderRef.current?.click(),
      getFiles: () => files,
      submitFiles,
      isLoading,
    }));

    const handleFileUpload = useCallback(
      async (e) => {
        const files = e.target.files;

        setFiles(files);

        if (uploadOnSubmit) {
          await submitFiles(files);
        }
        e.target.value = null;
      },
      [submitFiles, uploadOnSubmit]
    );

    return (
      <div
        className={styles["upload-container"]}
        style={{
          display: visible ? "block" : "none",
        }}
      >
        <label className={styles["label"]}>
          {label}: {required && <span>*</span>}
        </label>
        {visible && (
          <Button
            loading={isLoading}
            {...buttonProps}
            onClick={() => fileUploaderRef.current?.click()}
            disabled={isLoading}
            className={styles["upload-button"]}
          >
            {buttonText}
          </Button>
        )}
        <input
          {...props}
          {...formProps}
          {...defaults}
          type="file"
          className={styles["file-uploader-input"]}
          accept={accepts}
          multiple={multiple}
          ref={(e) => {
            fileUploaderRef.current = e;
            formProps?.ref?.(e);
          }}
          onChange={handleFileUpload}
        />
      </div>
    );
  }
);

export default withInputBase(FileUploader);
