import { createSlice } from "@reduxjs/toolkit";

const shiftSlice = createSlice({
  name: "shifts",
  initialState: {
    filteredShifts: [],
    savedShifts: [],
    trashedShifts: [],
    page: 1,
    loading: true,
    shiftStack: {
      pointer: 0,
      leftStack: [],
      rightStack: [],
      scrollStack: [],
    },
    filter: {
      what: {
        ids: [],
        descriptions: [],
      },
      where: {},
      when: [],
      wage: 0
    },
  },
  reducers: {
    setFilter(state, action) {
      state.filter = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setFilteredShifts(state, action) {
      state.filteredShifts = action.payload;
    },
    setPointer(state, action) {
      state.shiftStack.pointer = action.payload;
    },
    pushToStack(state, action) {
      state.shiftStack[action.payload.stack].push(action.payload.value);
    },
    popFromStack(state, action) {
      state.shiftStack[action.payload.stack].pop();
    },
    clearStack(state, action) {
      state.shiftStack[action.payload.stack] = [];
    },
  },
});

export const shiftActions = shiftSlice.actions;

export default shiftSlice.reducer;
