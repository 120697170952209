import styles from "./BusinessAccount.module.css";
import Input from "src/components/UI/inputs/Input";
import useBusiness from "src/hooks/business/useBusiness";
import StandardLayout from "../../Account/components/StandardLayout";

const BusinessAccountBusinessDetails = () => {
  const { activeBusiness } = useBusiness();
  return (
    <StandardLayout
      heading={
        <h1>
          <span>Business</span> Details
        </h1>
      }
      breadcrumb={"Business Settings /"}
      returnLink={"/business-account"}
    >
      <div className={styles["home-container"]}>
        <div className={styles["home-content"]}>
          <div className={styles["home-content-block"]}>
            <Input label={"Business Name:"} value={activeBusiness?.name} disabled />
            <Input label={"Australian Business Number (ABN):"} value={activeBusiness?.ABN} disabled />
            <Input
              label={"Business Award:"}
              value={activeBusiness?.industry?.name + " (" + activeBusiness?.industry?.award_code + ")"}
              disabled
            />
          </div>
        </div>
      </div>
    </StandardLayout>
  );
};

export default BusinessAccountBusinessDetails;
