import styles from "./NewPosition.module.css";
import barista from "src/assets/svg/Positions/barista.svg";
import Each from "src/components/func/Each";
import Link from "src/components/func/Link";
import useBusiness from "src/hooks/business/useBusiness";
import { buildAwardSummaryLink } from "src/utility/http";
import useStyling from "src/hooks/global/useStyling";
import Checkbox from "src/components/UI/inputs/Checkbox";

const NewPositionPreview = ({ data = {}, qualifications = [] }) => {
  const { activeBusiness } = useBusiness();

  const styling = useStyling(styles);
  return (
    <div className={styling("container")}>
      <div className={styling("heading")}>
        <h1>
          Here is a <span>preview</span> of your position.
        </h1>
      </div>
      <div className={styling("show-position")}>
        <Checkbox name={"is_active"} label={"Would you like this position to be visible on the scheduler?"} />
      </div>
      <div className={styling("content", "short-content")}>
        <div className={styling("card")}>
          <div className={styling("pos-heading")}>
            <div className={styling("illustration")}>
              <img src={barista} alt="barista" />
            </div>
            <div className={styling("name")}>{data?.name}</div>
          </div>

          <div className={styling("description")}>{data.description}</div>
          {qualifications.length > 0 && (
            <div className={styling("qualifications")}>
              <h3>Qualifications</h3>
              <div className={styling("qual-grid")}>
                <Each
                  of={qualifications}
                  render={(qualification, index) => <div key={index}>{qualification.description}</div>}
                />
              </div>
            </div>
          )}
          <div className={styling("classification")}>
            <Link to={buildAwardSummaryLink(activeBusiness?.industry?.award_code)}>
              {activeBusiness?.industry?.award_code}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPositionPreview;
