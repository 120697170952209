import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ShiftCancel.module.css";
import { motion } from "framer-motion";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { useCallback, useState } from "react";
import TextArea from "src/components/UI/inputs/TextArea";
import Button from "src/components/UI/buttons/Button";
import Link from "src/components/func/Link";
import useToast from "src/hooks/global/useToast";
import useFetch from "src/hooks/global/useFetch";
import useUI from "src/hooks/global/useUI";
import Dropdown from "src/components/UI/inputs/Dropdown";

const cancelReasons = [
  { value: "sick", label: "I am sick" },
  { value: "emergency", label: "I have an emergency" },
  { value: "travel", label: "Travel plans fell through" },
  { value: "family", label: "Family obligations" },
  { value: "weather", label: "Adverse weather conditions" },
  { value: "transport", label: "Transport issues" },
  { value: "double_booked", label: "Double booked by mistake" },
  { value: "personal", label: "Personal reasons" },
  { value: "work_conflict", label: "Conflict with another job" },
  { value: "other", label: "Other" },
];

const cancelReasonsBusiness = [
  { value: "no_show", label: "No show" },
  { value: "overstaffed", label: "Overstaffed" },
  { value: "low_demand", label: "Low demand" },
  { value: "weather", label: "Adverse weather conditions" },
  { value: "budget_cuts", label: "Budget cuts" },
  { value: "emergency", label: "Business emergency" },
  { value: "maintenance", label: "Maintenance issues" },
  { value: "double_booked", label: "Double booked by mistake" },
  { value: "other", label: "Other" },
];

const ShiftCancel = ({ shift, setShow, show, setShowDetails }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [additionalComments, setAdditionalComments] = useState();
  const toast = useToast();

  const { businessMode } = useUI();

  const {
    post: cancelTheShift,
    isLoading,
    refresh,
  } = useFetch({
    options: {
      onSuccess: () => {
        setShow(false);
        setShowDetails(false);
        toast.success("Shift cancelled successfully");
        refresh("Shift.GetUpcomingShifts");
        refresh("Shift.GetMobileUpcomingShifts");
      },
    },
  });

  const cancelShift = useCallback(() => {
    if (!selectedReason) {
      toast.error("Please select a reason for your cancellation.");
      return;
    }

    if (!additionalComments) {
      toast.error("Please provide additional comments.");
      return;
    }

    businessMode
      ? cancelTheShift({
          entity: "Shift",
          method: "delete",
          criteria: {
            _id: shift._id,
          },
          data: {
            cancellationReason: selectedReason,
          },
        })
      : cancelTheShift({
          entity: "Shift",
          method: "cancelShiftForUser",
          data: {
            shift,
            cancellationReason: selectedReason,
            additionalComments,
          },
        });
  }, [additionalComments, cancelTheShift, selectedReason, shift, toast, businessMode]);

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{
        x: show ? "0" : "100%",
      }}
      transition={{ duration: 0.2 }}
      className={styles["container"]}
    >
      <div className={styles["header"]} onClick={() => setShow(false)}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <div className={styles["content"]}>
        <h2>Cancel Shift</h2>
        <p>Please specify the reason for your shift cancellation.</p>
        <Dropdown
          my={"10px"}
          label={"Select reason for your cancellation"}
          placeholder={"Select reason"}
          options={businessMode ? cancelReasonsBusiness : cancelReasons}
          value={selectedReason}
          setValue={setSelectedReason}
        />
        <TextArea
          label={"Additional comments"}
          value={additionalComments}
          setValue={setAdditionalComments}
          placeholder={"Write message here"}
          rows={10}
        />
        <p className={styles["note"]}>
          <span>Please note: </span> All shift cancellations are subject to investigation by Shiftly. <br />
          <br />
          Multiple cancellations within a short period may lead to disciplinary action.
        </p>
        <Button
          disabled={isLoading}
          onClick={cancelShift}
          my={"10px"}
          theme={"secondary-outline"}
          className={styles["cancel-btn"]}
        >
          {isLoading ? "Cancelling..." : "Cancel Shift"}
        </Button>
        <div className={styles["link-container"]}>
          <Link className={styles["link"]} onClick={() => setShow(false)}>
            Go back to Shift
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default ShiftCancel;
