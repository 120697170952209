import styles from "./UpcomingShifts.module.css";

const ShiftCard = ({ shift = {}, setShowDetails = () => {} }) => {
  return (
    <div className={styles["shift-card"]} onClick={() => setShowDetails(shift)}>
      <h4>
        {shift?.day_of_week} {shift?.prettyDate}, {shift.position?.name}
      </h4>
      <p>
        <span>{shift.shiftPeriod}</span>
        <span>{shift?.timezone}</span>
      </p>
      <p className={styles["shift-location"]}>{shift.location?.name}</p>
      <p className={styles["shift-address"]}>{shift.address?.full_address || shift?.location?.address?.full_address}</p>
    </div>
  );
};

export default ShiftCard;
