import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    loadingElements: ["initAuth"],
    loading: true,
    shifts: {
      newShiftModal: false,
      viewMode: "scroll",
      newShiftModalDefaults: null,
      currentPeriod: "[]",
    },
    settings: {
      mode: "shifter",
      new_user: false,
      push_notifications_prompted: false,
    },
  },
  reducers: {
    startLoading(state, action) {
      state.loadingElements.push(action.payload);
      state.loading = true;
    },
    stopLoading(state, action) {
      state.loadingElements = state.loadingElements.filter((el) => el !== action.payload);
      if (state.loadingElements.length === 0) {
        state.loading = false;
      }
    },
    setSettings(state, action) {
      state.settings = action.payload;
    },
    updateSetting(state, action) {
      state.settings[action.payload.setting] = action.payload.value;
    },
    showNewShiftModal(state, action) {
      state.shifts.newShiftModal = action.payload;
    },
    setNewShiftModalDefaults(state, action) {
      state.shifts.newShiftModalDefaults = action.payload;
    },
    hideNewShiftModal(state) {
      state.shifts.newShiftModal = false;
    },
    setCurrentPeriod(state, action) {
      state.shifts.currentPeriod = action.payload;
    },
    setViewMode(state, action) {
      state.shifts.viewMode = action.payload;
    },
  },
});
export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
