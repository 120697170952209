import { iso31661 } from "iso-3166";
import moment from "moment-timezone";
const {
  validateEmail,
  validatePassword,
  validateMobile,
  validatePostcode,
  validateDOB,
  validateTFN,
  isFutureDate,
  validateBSB,
  validateBankAccountNumber,
} = require("@shiftly/shared/utility/Validation");

const timezones = moment.tz.names();

const states = [
  { value: "ACT", label: "ACT" },
  { value: "NSW", label: "NSW" },
  { value: "NT", label: "NT" },
  { value: "QLD", label: "QLD" },
  { value: "SA", label: "SA" },
  { value: "TAS", label: "TAS" },
  { value: "VIC", label: "VIC" },
  { value: "WA", label: "WA" },
];

const genders = [
  { label: "Male", value: "M" },
  {
    label: "Female",
    value: "F",
  },
  { label: "Other", value: "O" },
  { label: "Prefer not to say", value: "I" },
];

const countryList = iso31661.map((country) => {
  return { label: country.name, value: country.alpha3 };
});

const formDefaults = {
  //Login
  password: {
    validate: validatePassword,
    message:
      "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
    label: "Password",
    placeholder: "***************",
    required: true,
    type: "password",
  },
  confirm_password: {
    validate: (v, data) => {
      return v === data.password;
    },
    message: "Passwords do not match.",
    label: "Confirm Password",
    placeholder: "***************",
    required: true,
    type: "password",
  },
  new_password: {
    validate: validatePassword,
    message:
      "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
    label: "New Password",
    placeholder: "***************",
    required: true,
    type: "password",
  },
  current_password: {
    validate: validatePassword,
    message:
      "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
    label: "Current Password",
    placeholder: "***************",
    required: true,
    type: "password",
  },

  //Personal Details
  email: {
    validate: validateEmail,
    message: "Please enter a valid email address.",
    label: "Email",
    placeholder: "john.doe@example.com",
    required: true,
  },
  first_name: {
    validate: false,
    label: "First Name",
    placeholder: "John",
    required: true,
  },
  last_name: {
    validate: false,
    label: "Last Name",
    placeholder: "Doe",
    required: true,
  },
  date_of_birth: {
    validate: validateDOB,
    label: "Date of Birth",
    placeholder: "DD/MM/YYYY",
    required: true,
    type: "date",
  },
  phone_number: {
    validate: validateMobile,
    label: "Phone Number",
    placeholder: "0412345678",
    pattern: "^[0-9]{10}$",
    inputMode: "numeric",
    maxLength: 10,
  },
  gender: {
    label: "Gender",
    placeholder: "Select a gender option",
    required: true,
    options: genders,
  },
  passport_number: {
    label: "Passport Number",
    placeholder: "M1234567",
    required: true,
  },
  passport_country: {
    label: "Issuing Country",
    placeholder: "Select a country",
    required: true,
    options: countryList,
  },
  passport_expiry: {
    validate: isFutureDate,
    label: "Passport Expiry Date",
    placeholder: "DD/MM/YYYY",
    required: true,
    type: "date",
  },

  //Address
  street: {
    validate: false,
    label: "Street Address",
    placeholder: "Street",
    required: true,
  },
  city: {
    validate: false,
    label: "City",
    placeholder: "City",
    required: true,
  },
  state: {
    validate: false,
    label: "State",
    placeholder: "State",
    required: true,
    options: states,
  },
  post_code: {
    validate: validatePostcode,
    label: "Postcode",
    placeholder: "Postcode",
    required: true,
    pattern: "^[0-9]{4}$",
    inputMode: "numeric",
    maxLength: 4,
  },
  timezone: {
    label: "Timezone",
    placeholder: "Select a timezone",
    required: true,
    options: timezones.map((opt) => ({ value: opt, label: opt })),
  },

  //Bank Details
  account_name: {
    label: "Account Name",
    placeholder: "John Doe",
    required: true,
  },
  bsb: {
    validate: validateBSB,
    label: "BSB",
    placeholder: "123456",
    maxLength: 6,
    required: true,
  },
  account_number: {
    validate: validateBankAccountNumber,
    label: "Account Number",
    placeholder: "123456789",
    maxLength: 9,
    required: true,
  },

  //Tax Details
  tax_file_number: {
    validate: validateTFN,
    label: "Tax File Number (TFN)",
    placeholder: "226799598",
    pattern: "^[0-9]{9}$",
    inputMode: "numeric",
    maxLength: 9,
    required: true,
  },
  residency_status: {
    required: true,
    label: "Select residency status for tax purposes",
    placeholder: "Select Residency Status",
    options: [
      { label: "Australian Resident", value: "resident" },
      { label: "Working Holiday", value: "working_holiday" },
      { label: "Non Resident", value: "foreign_resident" },
    ],
  },
  has_loan_or_student_debt: {
    label: "Do you have study or training loans?",
    type: "checkbox",
  },
  tax_free_threshold: {
    label: "Would you like to claim the tax free threshold?",
    type: "checkbox",
  },

  //Super Details
  super_fund: {
    label: "Select your Super Fund",
    placeholder: "Select a super fund",
    required: true,
    // options: superFundsList, // This should be fetched from the server
  },
  member_number: {
    label: "Super Member Number",
    placeholder: "123456",
    required: true,
  },

  //Profile
  bio: {
    required: true,
    label: "Write your bio",
    placeholder:
      "Introduce yourself! Include details like your skills, experience, and the type of shifts you enjoy. Mention any special qualifications, past achievements, and why you love what you do. Be creative and professional – let your personality shine through!",
    rows: 15,
  },

  //Shifts
  hourly_rate_modifier: {
    validate: (v) => (v >= 0 ? true : "Wage modifier must be a positive number."),
    label: "Increase hourly rate by",
    placeholder: "0",
    required: true,
    inputMode: "tel",
    min: 0,
  },

  //Positions
  position_rate_modifier: {
    validate: (v) => (v >= 0 ? true : "Wage modifier must be a positive number."),
    label: "Increase minimum wage by",
    placeholder: "0",
    required: true,
    inputMode: "numeric",
    min: 0,
  },
};

export default formDefaults;
