import styles from "./BusinessNavMobileMode.module.css";
import useStyling from "src/hooks/global/useStyling";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

const BusinessNavMobileMode = ({ links = [] }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("container")}>
      <div className={styling("nav-bar")}>
        {links.map((item) => {
          return <NavItem key={item.label} {...item} />;
        })}
      </div>
    </div>
  );
};

export default BusinessNavMobileMode;

const NavItem = ({ icon, path = "/", hasNotifications, label, activeKey, badge = 0 }) => {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const styling = useStyling(styles);

  return (
    <div className={styling("nav-item", currentPath === "/" + activeKey && "active")} onClick={() => navigate(path)}>
      <div className={styling("nav-item-content")}>
        {badge > 0 && <div className={styling("badge")}>{badge}</div>}
        <FontAwesomeIcon icon={icon} className={styling("icon")} />
        {hasNotifications && <div className={styling("notification")}></div>}
        <p className={clsx("footer")}>{label}</p>
      </div>
    </div>
  );
};
