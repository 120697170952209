import styles from "./PositionsHome.module.css";
import NewPositionModal from "./components/NewPositionModal";
import { useCallback, useEffect, useState } from "react";
import useBusiness from "src/hooks/business/useBusiness";
import useDelayUnmount from "src/hooks/global/useDelayUnmount";
import LoadingSpinner from "src/components/animations/LoadingSpinner";
import SearchAndDisplay from "src/components/layout/SearchAndDisplay";
import { truncate } from "src/utility/format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCheck, faPencil } from "@fortawesome/pro-duotone-svg-icons";
import StandardLayout from "../../Account/components/StandardLayout";
import clsx from "clsx";
import useAlerts from "src/contexts/Alerts";
import useFetch from "src/hooks/global/useFetch";
import usePositions from "src/hooks/business/usePositions";
import Link from "src/components/func/Link";
import { buildAwardSummaryLink } from "src/utility/http";
import useSearchParams from "src/hooks/global/useSearchParams";

const PositionsHome = () => {
  const [newPositionModal, setNewPositionModal] = useState(false);
  const [elements, setElements] = useState([]);
  const [initial, setInitial] = useState({
    group: "",
    name: "",
    description: "",
    qualifications: [],
    position_rate_modifier: 0,
    is_active: true,
  });

  const { isLoading, activeBusiness } = useBusiness();

  const { availablePositions, groupedPositions, refreshAvailablePositions } = usePositions();

  const award_code = activeBusiness?.industry?.award_code;

  const showLoader = useDelayUnmount(isLoading);

  const { confirm } = useAlerts();
  const { post: deletePosition } = useFetch({
    options: {
      onSuccess: () => {
        refreshAvailablePositions();
      },
    },
  });

  const handleDelete = useCallback(
    async (position) => {
      if (
        !(await confirm({
          label: "Are you sure you want to delete this position?",
          text: (
            <p>
              Deleting a position cannot be undone.
              <br />
              <br />
              Shifts currently associated with this position will continue as normal, however you will no longer be able
              to create shifts for this position.
            </p>
          ),
          inverse: true,
          confirmText: "Delete",
        }))
      )
        return;

      deletePosition({
        entity: "Position",
        method: "delete",
        criteria: { _id: position._id },
      });
    },
    [confirm, deletePosition]
  );

  useSearchParams(
    ["mode"],
    ({ mode }) => {
      mode === "new" && setNewPositionModal(true);
    },
    []
  );

  useEffect(() => {
    setElements(
      Object.values(groupedPositions).map((positions, index) => ({
        original: positions[0],
        content: (
          <PositionsCard
            {...positions[0]}
            index={index}
            award_code={award_code}
            initial={initial}
            setInitial={setInitial}
            setNewPositionModal={setNewPositionModal}
          />
        ),
        footerContent: (
          <PositionsCardFooter
            position={positions[0]}
            setInitial={setInitial}
            setNewPositionModal={setNewPositionModal}
            handleDelete={handleDelete}
          />
        ),
        listContent: (
          <PositionsList
            {...positions[0]}
            index={index}
            setInitial={setInitial}
            setNewPositionModal={setNewPositionModal}
            handleDelete={handleDelete}
          />
        ),
      }))
    );
  }, [groupedPositions, award_code, initial, handleDelete]);

  return (
    <>
      <NewPositionModal
        newPositionModal={newPositionModal}
        setNewPositionModal={setNewPositionModal}
        initial={initial}
        setInitial={setInitial}
      />
      <StandardLayout
        heading={
          <h1>
            Your <span>Positions</span>
          </h1>
        }
        size="large"
        buttonText={"Create New Position"}
        buttonEvent={() => {
          setInitial({
            group: "",
            name: "",
            description: "",
            qualifications: [],
            position_rate_modifier: 0,
            is_active: true,
          });
          setNewPositionModal(true);
        }}
      >
        <div className={styles["container"]}>
          {showLoader ? (
            <div className={styles["loading-container"]}>
              <LoadingSpinner loader={isLoading} />
            </div>
          ) : (
            <SearchAndDisplay
              elementsName="positions"
              searchPlaceholder={
                availablePositions.length ? "Try " + availablePositions[0]?.name + "..." : "Search your positions here"
              }
              searchFields={["name"]}
              elements={elements}
              emptyStateText={<p>Create a new position to get started.</p>}
              link={{
                label: "View your award",
                destination: buildAwardSummaryLink(award_code),
              }}
            />
          )}
        </div>
      </StandardLayout>
    </>
  );
};

const PositionsCard = (props) => {
  const { name, description, qualifications, setInitial, setNewPositionModal, group } = props;
  return (
    <div
      className={styles["pos-card"]}
      icon={faPencil}
      onClick={() => {
        setInitial({ ...props, group: props.group?._id });
        setNewPositionModal(true);
      }}
    >
      <div className={styles["levels"]}>
        <Link to={"/positions/position-levels/" + group?._id}>View Levels</Link>
      </div>
      <div className={styles["pos-card-heading"]}>
        <div className={styles["pos-card-title"]}>
          <h2>{name}</h2>
        </div>
      </div>
      <div className={styles["pos-card-description"]}>
        {truncate(description, 80)}{" "}
        {description.length > 80 && (
          <span
            onClick={() => {
              setInitial({ ...props, group: props.group?._id });
              setNewPositionModal(true);
            }}
          >
            Read More
          </span>
        )}
      </div>
      <div className={styles["pos-card-qualifications"]}>
        {qualifications.slice(0, 3).map((qualification, index) => (
          <div key={index} className={styles["pos-card-qualification"]}>
            <span>
              <FontAwesomeIcon icon={faCheck} />
            </span>
            <p>{qualification?.description}</p>
          </div>
        ))}
        {qualifications.length > 3 ? <div className={styles["qual-more"]}>...</div> : null}
      </div>
    </div>
  );
};

const PositionsCardFooter = ({ position, setInitial, setNewPositionModal, handleDelete }) => {
  return (
    <div className={styles["pos-card-footer"]}>
      <FontAwesomeIcon
        icon={faTrash}
        onClick={(e) => {
          e.stopPropagation();
          handleDelete({ ...position, group: position.group?._id });
        }}
      />
      <FontAwesomeIcon
        icon={faPencil}
        onClick={() => {
          setInitial({ ...position, group: position.group?._id });
          setNewPositionModal(true);
        }}
      />
    </div>
  );
};

const PositionsList = (props) => {
  const { name, description, qualifications, setInitial, setNewPositionModal, group, handleDelete } = props;

  return (
    <div
      className={styles["list-container"]}
      onClick={() => {
        setInitial({ ...props, group: props.group?._id });
        setNewPositionModal(true);
      }}
    >
      <div className={clsx(styles["list-section"], styles["name-section"])}>
        <p>
          <span>{name}</span>
        </p>
      </div>
      {qualifications?.map((qual) => (
        <div className={styles["list-section"]} key={qual?._id}>
          <p>
            <FontAwesomeIcon icon={faCheck} className={styles["list-check"]} />
            {truncate(qual?.description, 60)}
          </p>
        </div>
      ))}
      <div className={clsx(styles["list-section"], styles["description-section"])}>
        <p>
          <span>{truncate(description, 40)}</span>
        </p>
      </div>
      <div className={clsx(styles["list-section"], styles["list-icon"])}>
        <FontAwesomeIcon
          icon={faTrash}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete({ ...props, group: group?._id });
          }}
        />
        <FontAwesomeIcon
          icon={faPencil}
          onClick={() => {
            setInitial({ ...props, group: group?._id });
            setNewPositionModal(true);
          }}
        />
      </div>
    </div>
  );
};
export default PositionsHome;
