import useAuth from "src/hooks/global/useAuth";
import styles from "./MessagesBubble.module.css";
import { formatDateToPrettyTime } from "src/utility/date";
import useStyling from "src/hooks/global/useStyling";
import useShiftlyLocation from "src/hooks/business/useShiftlyLocation";

const MessagesBubble = ({ content, for_id, sent_date, after, before }) => {
  const { user } = useAuth();
  const { activeLocation } = useShiftlyLocation();
  const forMe = user._id === for_id || activeLocation?._id === for_id;
  const showTime = !after || new Date(after.sent_date).getTime() - new Date(sent_date).getTime() > 300000;
  const styling = useStyling(styles);

  return (
    <div className={styling("container")}>
      <div className={styling("bubble", forMe ? "receiving" : "sending")}>
        <p className={styling("text")}>{content}</p>
      </div>
      {showTime && (
        <p className={styling("sent_time", forMe ? "left" : "right")}>{formatDateToPrettyTime(sent_date)}</p>
      )}
    </div>
  );
};

export default MessagesBubble;
