import { forwardRef, useEffect, useImperativeHandle } from "react";
import { Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import styles from "./MapElement.module.css";
import MapMarkerIcon from "src/assets/svg/icons/map_marker.svg";
import { roundNumber } from "src/utility/format";
import { useUncontrolled } from "uncontrollable";

const toRadians = (degrees) => {
  return degrees * (Math.PI / 180);
};

const haversineDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in km
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
};

const MapElement = forwardRef(function MapElement(props, ref) {
  const { camera, setCamera, pins = [] } = useUncontrolled(props, { camera: "setCamera" });

  useImperativeHandle(
    ref,
    () => ({
      camera,
      getDistanceFromCenter: () => {
        const latCenter = camera?.center?.lat;
        const lngCenter = camera?.center?.lng;

        // South-West corner coordinates
        const latSW = camera?.bounds?.south;
        const lngSW = camera?.bounds?.west;

        // North-East corner coordinates
        const latNE = camera?.bounds?.north;
        const lngNE = camera?.bounds?.east;

        if (!latCenter || !lngCenter || !latSW || !lngSW || !latNE || !lngNE) return;

        // Calculate distances
        const distanceSW = haversineDistance(latCenter, lngCenter, latSW, lngSW);
        const distanceNE = haversineDistance(latCenter, lngCenter, latNE, lngNE);

        // Get the maximum distance
        return Math.max(distanceSW, distanceNE);
      },
    }),
    [camera]
  );

  useEffect(() => {
    if (!pins.length || pins.length > 1) return;
    // setCamera({
    //   center: {
    //     lat: pins[0]?.lat ?? -33.8688,
    //     lng: pins[0]?.lng ?? 151.2093,
    //   },
    //   zoom: 13,
    // });
  }, [pins, setCamera]);

  return (
    <div className={styles["map-container"]}>
      <Map
        {...camera}
        mapId={"eb2b87995a53a413"}
        onCameraChanged={(v) => {
          setCamera(v.detail); //this has bounds on it
        }}
        zoomControl={false}
        mapTypeControl={false}
        scaleControl={false}
        streetViewControl={false}
        rotateControl={false}
        fullscreenControl={false}
      >
        {pins.map((pin, index) => (
          <Marker key={index} position={pin.position} callback={pin.callback} pin={pin} />
        ))}
      </Map>
    </div>
  );
});

const Marker = ({ position, callback = () => {}, pin }) => {
  return (
    <AdvancedMarker
      position={position}
      onClick={() => {
        callback(pin);
      }}
    >
      <div className={styles["map-marker-container"]}>
        <div className={styles["marker-icon"]}>
          <img src={MapMarkerIcon} alt="Marker Icon" />
          <p>${roundNumber(pin.shift?.hourlyRate)}</p>
        </div>
      </div>
    </AdvancedMarker>
  );
};

export default MapElement;
