import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./BusinessNavItem.module.css";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import useStyling from "src/hooks/global/useStyling";

const BusinessNavItem = ({ label, icon, path, activeIndex, setActiveNav, index, badge = 0 }) => {
  const navigate = useNavigate();
  const styling = useStyling(styles);
  return (
    <div
      className={clsx(styles["container"], activeIndex === index && styles["active"])}
      onClick={() => {
        setActiveNav(index);
        navigate(path);
      }}
    >
      <div className={styles["icon-container"]}>
        <FontAwesomeIcon icon={icon} />
      </div>
      {badge > 0 && <div className={styling("badge")}>{badge}</div>}
      <div className={styles["link-text"]}>
        <p>{label}</p>
      </div>
    </div>
  );
};

export default BusinessNavItem;
