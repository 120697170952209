import withInputWrapper from "src/components/HOC/withInputWrapper";
import useStyling from "src/hooks/global/useStyling";
import styles from "./Input.module.css";
import { useState, useEffect, forwardRef, useRef } from "react";
import { faEye } from "@fortawesome/pro-light-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withInputBase from "src/components/HOC/withInputBase";

const Input = forwardRef(
  (
    {
      icon,
      formProps,
      type = "text",
      value,
      setValue,
      submitOnEnter,
      onEnterPress,
      specialButton,
      validate,
      defaults: { validate: defaultValidate, required: defaultRequired, ...defaults },
      required,
      ...props
    },
    ref
  ) => {
    const styling = useStyling(styles);

    const [inputType, setInputType] = useState(type || defaults.type);
    const [inputIcon, setInputIcon] = useState(icon);

    useEffect(() => {
      if (icon) {
        setInputIcon(icon);
      }

      if (type === "password") {
        setInputIcon({
          icon: faEye,
          side: "right",
          onClick: () =>
            setInputType(() => {
              return inputType === "password" ? "text" : "password";
            }),
        });
      }
    }, [inputType, type, icon]);

    return (
      <div className={styling("container")}>
        <input
          {...defaults}
          {...props}
          {...formProps}
          type={inputType}
          ref={(e) => {
            formProps?.ref?.(e);
            ref.current = e;
          }}
        />
        {inputIcon && (
          <div className={styling("icon", inputIcon?.side, icon?.className)} onClick={inputIcon?.onClick}>
            <FontAwesomeIcon icon={inputIcon?.icon} />
          </div>
        )}
      </div>
    );
  }
);

export default withInputBase(withInputWrapper(Input));
