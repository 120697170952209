import React, { useLayoutEffect } from "react";
import styles from "./Scheduler.module.css";
import SchedulerHeader from "./header/SchedulerHeader";
import SchedulerCalendarGrid from "./calendar/SchedulerCalendarGrid";
import SchedulerCalendarNav from "./calendar/SchedulerCalendarNav";
import { useNavigate } from "react-router-dom";
import withDisplay from "src/components/HOC/withDisplay";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import Link from "src/components/func/Link";
import { SchedulerContextProvider } from "src/contexts/Scheduler";

const Scheduler = React.memo(({ isMobile, isLaptop }) => {
  const navigate = useNavigate();

  // Navigate to mobile shifts if isMobile is true
  useLayoutEffect(() => {
    if (isMobile) {
      navigate("/mobile-shifts");
    }
  }, [isMobile, navigate]);

  return (
    <SchedulerContextProvider>
      <DndProvider backend={isLaptop ? HTML5Backend : TouchBackend}>
        <div className={styles["container"]}>
          <SchedulerHeader />
          <div className={styles["calendar"]}>
            <SchedulerCalendarNav />
            <SchedulerCalendarGrid />
            <div className={styles["new-position"]}>
              <p className={styles["new-pos-title"]}>New Position</p>
              <div className={styles["new-pos"]}>
                <Link to={"/positions?mode=new"}> + Add New Position</Link>
              </div>
            </div>
          </div>
        </div>
      </DndProvider>
    </SchedulerContextProvider>
  );
});

export default withDisplay(Scheduler);
