import { createContext, useCallback, useContext, useRef, useState } from "react";
import Modal from "src/components/UI/layout/Modal";
import styles from "./Alerts.module.css";
import useStyling from "src/hooks/global/useStyling";
import Overlay from "src/components/UI/layout/Overlay";
import ModalLabel from "src/components/misc/ModalLabel";
import Button from "src/components/UI/buttons/Button";
import useDisplay from "src/hooks/global/useDisplay";
import { motion } from "framer-motion";

export const AlertsContext = createContext();

export const AlertsContextProvider = ({ children }) => {
  const awaitingPromiseRef = useRef(null);
  const [options, setOptions] = useState(null);

  const { isMobile } = useDisplay();

  const styling = useStyling(styles);

  const openModal = useCallback((options) => {
    setOptions(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }, []);

  const handleClose = useCallback(() => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(false);
    }
    setOptions(null);
  }, []);

  const handleConfirm = useCallback((value) => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(value);
    }
    setOptions(null);
  }, []);

  const confirm = useCallback(
    async ({
      label = "Are you sure?",
      text = "Are you sure you want to complete this action?",
      cancelText = "Cancel",
      confirmText = "Submit",
      inverse = false,
    } = {}) =>
      await openModal({
        label,
        content: (
          <>
            <div className={styling("content-container")}>
              <div className={styling("content-text")}>{text}</div>
              <div className={styling("buttons-container")}>
                <Button onClick={handleClose} theme={"secondary-outline"}>
                  {cancelText}
                </Button>
                <Button onClick={handleConfirm} theme={inverse ? "danger" : "secondary"}>
                  {confirmText}
                </Button>
              </div>
            </div>
          </>
        ),
      }),
    [openModal, handleClose, styling, handleConfirm]
  );

  return (
    <>
      <AlertsContext.Provider value={{ confirm, prompt, alert }} children={children} />
      {!isMobile ? (
        <Modal
          showModal={Boolean(options)}
          label={<ModalLabel text={options?.label} />}
          setShowModal={handleClose}
          className={styling("modal")}
        >
          {options?.content}
        </Modal>
      ) : (
        <div className={styling("mobile-wrapper", Boolean(options) && "mobile-visible")}>
          <Overlay open={Boolean(options)} onClick={handleClose} />
          <motion.div
            initial={{
              y: "100%",
            }}
            animate={{
              y: Boolean(options) ? "0%" : "100%",
            }}
            transition={{ duration: 0.2 }}
            className={styling("modal-mobile")}
          >
            <div className={styling("label-mobile")}>
              <h3>{options?.label}</h3>
            </div>
            {options?.content}
          </motion.div>
        </div>
      )}
    </>
  );
};

const useAlerts = () => useContext(AlertsContext);

export default useAlerts;
