import Modal from "src/components/UI/layout/Modal";
import styles from "./CancelShift.module.css";
import { useUncontrolled } from "uncontrollable";
import logo from "src/assets/logos/icon.svg";
import { useCallback, useState } from "react";
import TextArea from "src/components/UI/inputs/TextArea";
import { roundNumber } from "src/utility/format";
import Button from "src/components/UI/buttons/Button";
import useFetch from "src/hooks/global/useFetch";
import useToast from "src/hooks/global/useToast";

const feePercent = roundNumber((1 - process.env.REACT_APP_SHIFTLY_REFUND_AMOUNT) * 100, 0);

const CancelShift = (props) => {
  const { shift, showModal = false, setShowModal } = useUncontrolled(props, { showModal: "setShowModal" });
  const toast = useToast();

  const [cancellationReason, setCancellationReason] = useState("");
  const {
    post: submitComment,
    isLoading,
    refresh,
  } = useFetch({
    options: {
      onSuccess: (data) => {
        setShowModal(false);
        refresh("Shift.ShiftsBetweenDates");
        setCancellationReason("");
        toast.success("Shift cancelled successfully");
      },
    },
  });

  const handleSubmit = useCallback(() => {
    submitComment({
      entity: "Shift",
      method: "delete",
      criteria: {
        _id: shift._id,
      },
      data: {
        cancellationReason,
      },
    });
  }, [shift, submitComment, cancellationReason]);

  return (
    <div className={styles["container"]}>
      <Modal
        className={styles["modal"]}
        showModal={showModal}
        setShowModal={setShowModal}
        label={
          <div className={styles["label"]}>
            <img src={logo} alt="Shiftly Logo" className={styles["shiftly-logo"]}></img>
            <h1>
              <span>Cancel</span> shift
            </h1>
          </div>
        }
      >
        <div className={styles["content"]}>
          <div className={styles["heading"]}>
            <h3>We're sorry this shift hasn't worked out.</h3>
            <p>
              <strong>
                Cancelling a shift will incur a cancellation <span>fee of {feePercent}%</span> on the shift's total
                cost. <br />
              </strong>
              <br />
              If your cancellation is due to issues with employee standards, an emergency, or any other specific
              circumstance, please provide details in the text box below. We take these matters very seriously and will
              investigate every submission thoroughly to ensure the highest standards are maintained.
            </p>
          </div>
          <div className={styles["cancellation-reason"]}>
            <TextArea
              rows={8}
              value={cancellationReason}
              setValue={setCancellationReason}
              label={"Please provide cancellation reason:"}
              placeholder={
                "Provide examples of the expected standards and how the shifter failed to meet them. Describe what actions you and the shifter took to resolve the mistakes, and mention any team members who witnessed the situation."
              }
            />
            <div className={styles["buttons"]}>
              <Button
                theme={"secondary"}
                onClick={() => {
                  setShowModal(false);
                  setCancellationReason("");
                }}
              >
                Back
              </Button>
              <Button
                theme={"primary"}
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Cancel Shift"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CancelShift;
