import Searchbar from "./components/SearchBar/Searchbar";
import ShiftStack from "./components/ShiftStack";
import MapView from "./components/MapView";
import styles from "./Search.module.css";
import useUI from "src/hooks/global/useUI";

const Search = () => {
  const {
    shifts: { viewMode },
  } = useUI();

  return (
    <div className={styles["container"]}>
      <Searchbar />
      {viewMode === "scroll" ? <ShiftStack /> : <MapView />}
    </div>
  );
};
export default Search;
