import Button from "src/components/UI/buttons/Button";
import styles from "./Portal.module.css";
import Input from "src/components/UI/inputs/Input";
import { useCallback, useState } from "react";
import useFetch from "src/hooks/global/useFetch";
import useToast from "src/hooks/global/useToast";
import { motion } from "framer-motion";
import useSearchParams from "src/hooks/global/useSearchParams";
import FormV2 from "src/components/func/FormV2";

const ResetPassword = ({ setPage }) => {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [resetToken, setResetToken] = useState("");

  useSearchParams(["token", "email"], ({ token, email }) => {
    setResetToken(token);
    setEmail(email);
  });

  const { post, isLoading, isError } = useFetch({
    options: {
      onSuccess: () => {
        toast.success(!resetToken ? "Reset link sent successfully. Check your emails!" : "Password reset successfully");
        setPage("login");
      },
    },
  });

  const onSubmitRequest = useCallback(
    async (data) => {
      return await post({
        node: "auth-node",
        method: "resetPasswordRequest",
        data,
      });
    },
    [post]
  );

  const onSubmitNewPassword = useCallback(
    async (data) => {
      return await post({
        node: "auth-node",
        method: "resetPassword",
        data: {
          token: resetToken,
          password: data.password,
          email,
        },
      });
    },
    [post, resetToken, email]
  );

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={styles["content-container"]}
    >
      {resetToken ? (
        <>
          <h2 className={styles["heading"]}>
            Reset <span>Password</span>
          </h2>
          <p className={styles["subtext"]}>You can create your new password below:</p>
          <FormV2 onSubmit={onSubmitNewPassword} className={styles["form-container"]}>
            <div className={styles["content"]}>
              <Input name={"password"} />
              <Input name={"confirm_password"} submitOnEnter />
            </div>
            <div className={styles["button-container"]}>
              <Button theme={"secondary"} size={"fw"} type={"submit"} loading={isLoading} error={isError}>
                Send reset link
              </Button>
            </div>
          </FormV2>
        </>
      ) : (
        <>
          <h2 className={styles["heading"]}>
            Reset <span>Password</span>
          </h2>
          <p className={styles["subtext"]}>
            Forgot your password? <br />
            <br />
            Enter your email address below and we'll send you a link to reset your password.
          </p>
          <FormV2 onSubmit={onSubmitRequest} className={styles["form-container"]}>
            <div className={styles["content"]}>
              <Input name={"email"} submitOnEnter />
            </div>
            <div className={styles["button-container"]}>
              <Button type={"submit"} theme={"secondary"} size={"fw"} loading={isLoading} error={isError}>
                Send reset link
              </Button>

              <p className={styles["nav-text"]} onClick={() => setPage("login")}>
                I've remembered! <span>Login</span>
              </p>
            </div>
          </FormV2>
        </>
      )}
    </motion.div>
  );
};
export default ResetPassword;
