import React from "react";
import styles from "./SchedulerCalendarGrid.module.css";
import useStyling from "src/hooks/global/useStyling";
import usePositions from "src/hooks/business/usePositions";
import SchedulerCalendarGroup from "./SchedulerCalendarGroup";
import { useScheduler } from "src/contexts/Scheduler";

const SchedulerCalendarGrid = React.memo((props) => {
  const { shifts = [], applications = [], isLoading, thirdPartyShifts = [], internalShifts = [] } = useScheduler();

  const { groupedPositions } = usePositions();
  const styling = useStyling(styles);

  return (
    <div className={styling("container", isLoading && "isLoading")}>
      {Object.entries(groupedPositions)
        .filter(([id, positions]) => positions.some((p) => p.is_active))
        .map(([group, positions]) => (
          <SchedulerCalendarGroup
            key={group}
            positions={positions}
            shifts={shifts.filter((s) => s.position_group === group)}
            applicants={applications}
            thirdPartyShifts={thirdPartyShifts.filter((s) => s.group === group)}
            internalShifts={internalShifts.filter((s) => s.position_group === group)}
          />
        ))}
    </div>
  );
});

export default SchedulerCalendarGrid;
