import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";

const useToast = () => {
  const success = useCallback(
    (message, title) => toast.success(title ? <ToastBody title={title} message={message} /> : message),
    []
  );

  const error = useCallback(
    (message, title) => toast.error(title ? <ToastBody title={title} message={message} /> : message),
    []
  );

  const info = useCallback(
    (message, title) => toast.info(title ? <ToastBody title={title} message={message} /> : message),
    []
  );

  return useMemo(() => ({ success, error, info }), [success, error, info]);
};

export const ToastBody = ({ title, message }) => (
  <div>
    <h6>{title}</h6>
    <p>{message}</p>
  </div>
);

export default useToast;
