import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "src/contexts/FormV2";
import formDefaults from "src/utility/form";

const useFormProps = (props) => {
  const { register, getValues, errors, submit, clearErrors, setFormValue } = useForm();
  const [localErrors, setLocalErrors] = useState({});
  const { name = "noname", onChange, value = "", setValue, formatter, validate, required } = props;

  const registerFunc = useMemo(
    () =>
      register(name, {
        validate: (v) => {
          if (validate === false) return true;
          const validatingFunction = validate || formDefaults[name]?.validate || (() => true);

          const response = validatingFunction(v, getValues());
          if (response === true) return true;
          if (typeof response === "string") return response;

          return formDefaults[name]?.message || "Invalid";
        },
        required: (required ?? formDefaults[name]?.required) && "Required",
      }),
    [register, name, validate, getValues, required]
  );

  const controlledInput = useMemo(() => !Boolean(registerFunc) || name === "noname", [registerFunc, name]);

  // Ensure state is updated even when inside a form
  const formValue = getValues(name);
  useEffect(() => {
    if (controlledInput) return;
    clearErrors(name);
    clearErrors('toast');
    if (formValue === undefined) return;
    const formattedValue = formatter?.(formValue) ?? formValue;
    setValue(formattedValue);
    onChange?.(formattedValue);
  }, [formValue, setValue, clearErrors, name, onChange, formatter, controlledInput]);

  const handleChange = useCallback(
    (e) => {
      const newValue = formatter ? formatter(e.target.value) : e.target.value;
      e.target.value = newValue;
      onChange?.(newValue);
      setValue(newValue);
    },
    [setValue, onChange, formatter]
  );

  const handleOnBlur = useCallback(
    (e) => {
      if (controlledInput) {
        setLocalErrors((prev) => ({ ...prev, [name]: validate?.(value) }));
      }
    },
    [setLocalErrors, name, validate, controlledInput, value]
  );

  const formProps = useMemo(() => {
    return controlledInput
      ? {
          value: value,
          onChange: handleChange,
          onBlur: handleOnBlur,
        }
      : registerFunc;
  }, [registerFunc, value, handleChange, controlledInput, handleOnBlur]);

  return {
    formProps,
    submit,
    value: controlledInput ? value : formValue,
    setValue: controlledInput ? setValue : (v, specificName) => setFormValue(specificName ?? name, v),
    errors: controlledInput
      ? localErrors
      : Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: errors[key]?.message }), {}),
  };
};

export default useFormProps;
