import styles from "./ModalLabel.module.css";
import useStyling from "src/hooks/global/useStyling";
import logo from "src/assets/logos/icon.svg";

const ModalLabel = ({ text = "" }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("container")}>
      <img src={logo} alt="Shiftly Logo" className={styles["shiftly-logo"]}></img>
      <h3>{text}</h3>
    </div>
  );
};

export default ModalLabel;
