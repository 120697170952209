import styles from "./BusinessAccountLocationsHome.module.css";
import SearchAndDisplay from "src/components/layout/SearchAndDisplay";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { useNavigate } from "react-router-dom";
import StandardLayout from "../../Account/components/StandardLayout";
import { faStar } from "@fortawesome/pro-regular-svg-icons";
import Link from "src/components/func/Link";
import useShiftlyLocation from "src/hooks/business/useShiftlyLocation";
import useFetch from "src/hooks/global/useFetch";
import useAlerts from "src/contexts/Alerts";

const LocationsHome = () => {
  const { allLocations, refreshAvailableLocations } = useShiftlyLocation();
  const [elements, setElements] = useState([]);

  const { confirm } = useAlerts();
  const navigate = useNavigate();

  const { post: deleteLocation } = useFetch({
    options: {
      onSuccess: () => {
        refreshAvailableLocations();
      },
    },
  });

  const handleDeleteLocation = useCallback(
    async (location) => {
      if (!location?._id) return;
      if (
        !(await confirm({
          label: "Delete Location",
          text: "Are you sure you want to delete this location? This cannot be undone.",
          confirmText: "Delete",
          inverse: true,
        }))
      )
        return;
      deleteLocation({
        entity: "Location",
        method: "delete",
        criteria: { _id: location?._id },
      });
    },
    [deleteLocation, confirm]
  );

  useEffect(() => {
    setElements(
      allLocations.map((location, index) => ({
        original: location,
        content: <LocationsCard {...location} index={index} location={location} />,
        footerContent: <LocationsCardFooter location={location} handleDeleteLocation={handleDeleteLocation} />,
        listContent: (
          <LocationsList {...location} index={index} location={location} handleDeleteLocation={handleDeleteLocation} />
        ),
      }))
    );
  }, [allLocations, handleDeleteLocation]);

  return (
    <StandardLayout
      heading={
        <h1>
          Business <span>Locations</span>
        </h1>
      }
      breadcrumb={"Business Settings /"}
      returnLink={"/business-account"}
      buttonText={"Create New Location"}
      buttonEvent={() => navigate("/business-account/locations/edit-location")}
      size="large"
    >
      <SearchAndDisplay
        elementsName="locations"
        searchPlaceholder={"Search locations..."}
        searchFields={["name"]}
        elements={elements}
      />
    </StandardLayout>
  );
};

const LocationsCard = ({ name, address, logo, location }) => {
  const navigate = useNavigate();
  const { setActiveLocation } = useShiftlyLocation();
  return (
    <div
      className={styles["card"]}
      onClick={() => {
        setActiveLocation(location);
        navigate("/business-account/locations/edit-location", { state: { location } });
      }}
    >
      <div className={styles["card-header"]}>
        <div className={styles["card-header-details"]}>
          <h2>{name}</h2>
          <p>
            {address?.street}, {address?.city}
          </p>
        </div>
        <div className={styles["card-logo-container"]}>
          <img src={logo} alt={name} />
        </div>
      </div>
      <div className={styles["card-body"]}>
        <h6>Manage Location</h6>
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
      </div>
    </div>
  );
};
const LocationsCardFooter = ({ location, handleDeleteLocation }) => {
  const navigate = useNavigate();
  const { setActiveLocation } = useShiftlyLocation();
  return (
    <div className={styles["footer-container"]}>
      <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteLocation(location)} />
      <FontAwesomeIcon
        icon={faPencil}
        onClick={() => {
          setActiveLocation(location);
          navigate("/business-account/locations/edit-location", { state: { location } });
        }}
      />
    </div>
  );
};

const LocationsList = ({ location, handleDeleteLocation }) => {
  const navigate = useNavigate();
  return (
    <div className={styles["list"]}>
      <div className={styles["list-details"]}>
        <div className={styles["list-logo"]}>
          <img src={location.icon || location.logo} alt={location?.name} />
        </div>
        <p>{location.name}</p>
        <div className={styles["list-rating"]}>
          <p>{location?.rating}</p>
        </div>
        <FontAwesomeIcon icon={faStar} />
      </div>
      <div className={styles["list-address"]}>
        <p>
          {location?.address?.street}, {location?.address?.city}
        </p>
      </div>
      <div className={styles["list-link"]}>
        <Link to="/business-account/locations/edit-location" state={{ location }}>
          Manage Location
        </Link>
      </div>
      <div
        className={styles["list-edit"]}
        onClick={() => navigate("/business-account/locations/edit-location", { state: { location } })}
      >
        <FontAwesomeIcon icon={faPencil} />
      </div>
      <div className={styles["list-trash"]} onClick={() => handleDeleteLocation(location)}>
        <FontAwesomeIcon icon={faTrash} />
      </div>
    </div>
  );
};

export default LocationsHome;
