import withInputBase from "src/components/HOC/withInputBase";
import styles from "./TextArea.module.css";
import useStyling from "src/hooks/global/useStyling";
import withInputWrapper from "src/components/HOC/withInputWrapper";
import { forwardRef } from "react";

const TextArea = forwardRef(
  (
    {
      formProps,
      type = "text",
      value,
      setValue,
      submitOnEnter,
      validate,
      defaults: { validate: defaultValidate, required: defaultRequired, ...defaults },
      required,
      rows = 3,
      resize = "vertical",
      specialButton,
      ...props
    },
    ref
  ) => {
    const styling = useStyling(styles);
    return (
      <div className={styling("container")}>
        <textarea
          {...defaults}
          {...props}
          {...formProps}
          rows={rows}
          style={{ resize }}
          className={styling("textarea")}
          ref={(e) => {
            formProps?.ref?.(e);
            ref.current = e;
          }}
        />
      </div>
    );
  }
);

export default withInputBase(withInputWrapper(TextArea));
