export const convertTime = (time = "") => {
  let [hours] = time.split(":").map(Number);

  // AM or PM
  const suffix = hours >= 12 ? "pm" : "am";

  // Convert to 12-hour format
  hours = hours % 12 || 12;

  return `${hours}${suffix}`;
};

export const convertObjectToTimeString = (obj) => {
  const start = convertTime(obj.startTime);
  const end = convertTime(obj.endTime);

  return `${start} - ${end}`;
};

export const stringToDate = (dateString) => {
  const [day, month, year] = dateString.split("/").map(Number);

  return new Date(year, month - 1, day);
};

export const calculateHoursBetweenDates = (sd = new Date(), ed = new Date()) => {
  const startDate = new Date(sd);
  const endDate = new Date(ed);
  // Get the difference in milliseconds between the two dates
  const diffInMs = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to hours
  const diffInHours = diffInMs / (1000 * 60 * 60);

  // Return the difference in hours
  return diffInHours;
};
export const formatDateToPrettyTime = (dateParam) => {
  const date = new Date(dateParam);
  const now = new Date();
  const diffMs = date - now; // difference in milliseconds
  const diffMins = Math.round(diffMs / 60000); // difference in minutes
  const diffHours = Math.round(diffMins / 60); // difference in hours

  if (diffMins < -4) {
    // Past
    if (diffMins > -60) {
      return `${-diffMins} minutes ago`;
    } else if (diffHours > -4) {
      return `${-diffHours} hours ago`;
    } else if (diffHours <= -4 && now.getDate() === date.getDate()) {
      return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });
    } else {
      return date.toLocaleString("en-US", {
        month: "short",
        day: "numeric", // e.g., 17
        hour: "2-digit", // e.g., 05
        minute: "2-digit", // e.g., 30
        hour12: true, // use AM/PM
      });
    }
  } else if (diffMins >= -4 && diffMins <= 4) {
    return `Just Now`;
  } else {
    // Future
    if (diffMins < 60) {
      return `in ${diffMins} minutes`;
    } else if (diffHours < 4) {
      return `in ${diffHours} hours`;
    } else if (diffHours >= 4 && now.getDate() === date.getDate()) {
      return "at" + date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });
    } else if (diffHours < 25) {
      return `in ${diffHours} hours`;
    } else {
      return date.toLocaleString("en-US", {
        month: "short",
        day: "numeric", // e.g., 17
        hour: "2-digit", // e.g., 05
        minute: "2-digit", // e.g., 30
        hour12: true, // use AM/PM
      });
    }
  }
};

export const formatDateToPrettyDate = (dateParam) => {
  const date = new Date(dateParam);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric", // e.g., 17
    year: "numeric", // e.g., 2021
  });
};

export const getDayOfWeek = (date) => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return days[date.getDay()];
};

export const getMonthShortName = (date) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return months[date.getMonth()];
};

export const getDayOfWeekStartingMonday = (date) => {
  let dayOfWeek = date.getDay();

  if (dayOfWeek === 0) {
    dayOfWeek = 6;
  } else {
    dayOfWeek -= 1;
  }

  return dayOfWeek;
};

export const convertStringTimeToDate = (shiftDate, startTime, endTime) => {
  const startDate = new Date(shiftDate).setHours(startTime?.split(":")[0], startTime?.split(":")[1]);
  const endDate = new Date(shiftDate).setHours(endTime?.split(":")[0], endTime?.split(":")[1]);

  if (startDate >= endDate) {
    const end = new Date(endDate);
    end.setDate(end.getDate() + 1);
    return { startDate: new Date(startDate), endDate: end };
  }

  return { startDate: new Date(startDate), endDate: new Date(endDate) };
};

export const calculateAgeInYears = (date) => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }

  return age;
};
