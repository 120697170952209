import { Fragment } from "react";
import styles from "./Toggle.module.css";
import clsx from "clsx";

const Toggle = ({ tab = 0, setTab = () => {}, tabs = [] }) => {
  return (
    <>
      <div className={styles["tabs-container"]}>
        {tabs.map((tabItem, index) => {
          return (
            <Fragment key={index}>
              <div
                className={clsx(styles["tab-item"], tab === index && styles["active"])}
                onClick={() => setTab(index)}
              >
                {tabItem.icon}
                <p className={clsx("small")}>{tabItem.text}</p>
              </div>
              {index !== tabs.length - 1 && <div className={styles["divider"]}></div>}
            </Fragment>
          );
        })}
      </div>
      <div className={styles["tab-slider-container"]}>
        <div
          className={styles["tab-slider"]}
          style={{
            width: `${100 / tabs.length}%`,
            left: `${(tab / tabs.length) * 100}%`,
          }}
        ></div>
      </div>
    </>
  );
};

export default Toggle;
