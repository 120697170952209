import SideLayout from "src/components/layout/SideLayout";
import styles from "./CVEdit.module.css";
import useStyling from "src/hooks/global/useStyling";
import { useCallback, useMemo } from "react";
import useProfile from "src/hooks/shifter/useProfile";
import TextArea from "src/components/UI/inputs/TextArea";
import Button from "src/components/UI/buttons/Button";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import FormV2 from "src/components/func/FormV2";

const CVEditBio = (props) => {
  const styling = useStyling(styles);

  const { profile, updateProfile, updatingProfile, onError } = useProfile();

  const initial = useMemo(
    () => ({
      bio: profile?.bio,
    }),
    [profile]
  );

  const handleSubmit = useCallback(
    async (data) => {
      const response = await updateProfile({ data: { ...data, account_id: profile?.account_id } });
      !response.error && props.setShow(false);
      return response;
    },
    [updateProfile, props, profile]
  );

  return (
    <SideLayout heading={"Edit Bio"} {...props}>
      <FormV2 initial={initial} onSubmit={handleSubmit}>
        <div className={styling("container")}>
          <TextArea name={"bio"} submitOnEnter />
          <Button
            loading={updatingProfile}
            error={onError}
            theme={"secondary"}
            type="submit"
            className={styling("save-btn")}
            icon={faSave}
          >
            Save Bio
          </Button>
        </div>
      </FormV2>
    </SideLayout>
  );
};

export default CVEditBio;
