import withInputBase from "src/components/HOC/withInputBase";
import styles from "./Increment.module.css";
import useStyling from "src/hooks/global/useStyling";
import useInlineStyles from "src/hooks/global/useInlineStyles";
import Link from "src/components/func/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/pro-light-svg-icons";
import { forwardRef, useCallback } from "react";
import clsx from "clsx";

const Increment = forwardRef(
  (
    {
      value = 0,
      setValue,
      link,
      label,
      error,
      min = 0,
      max = Infinity,
      unit,
      side = "right",
      step = 1,
      required,
      disabled,
      className,
      formProps,
      ...props
    },
    ref
  ) => {
    const style = useInlineStyles(props);
    const styling = useStyling(styles);

    const handleIncrement = useCallback(() => {
      const val = Number(value);
      if (val + step <= max) {
        setValue(val + step);
      }
    }, [value, setValue, step, max]);

    const handleDecrement = useCallback(() => {
      const val = Number(value);
      if (val - step >= min) {
        setValue(val - step);
      }
    }, [value, setValue, step, min]);

    return (
      <div className={clsx(styling("wrapper"), className)} style={{ style }}>
        <div className={styling("header")}>
          <label className={styling("label")}>
            {label}: {required && <span>*</span>}
          </label>
          {link && (
            <Link to={link.destination} className={styles["link"]}>
              {link.label}
            </Link>
          )}
        </div>

        <div className={styling("container", disabled && "disabled")}>
          <button
            type="button"
            className={styling("increment-btn", "decrement")}
            onClick={handleDecrement}
            disabled={value - step < min || disabled}
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>

          <div className={styling("increment-unit-input")}>
            {unit && side === "left" && <span className={styling("unit")}>{unit}</span>}
            <input ref={ref} type="number" className={styling("input")} {...formProps} disabled={disabled} />
            {unit && side === "right" && <span className={styling("unit")}>{unit}</span>}
          </div>

          <button
            type="button"
            className={styling("increment-btn", "increment")}
            onClick={handleIncrement}
            disabled={value + step > max || disabled}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>

        {error && (
          <div className={styling("error-container")}>
            <p className={styling("message")}>{error}</p>
          </div>
        )}
      </div>
    );
  }
);

export default withInputBase(Increment);
