const responsibilities = {
  MA000119: {
    "Food and Beverage": {
      1: [
        "Pick up glasses.",
        "Assist higher-classified food and beverage attendants, excluding customer service.",
        "Clear food plates from tables.",
        "Set and wipe down tables.",
        "Clean and tidy associated areas.",
        "Handle cash transactions.",
      ],
      2: [
        "Serve, dispense, or mix liquor.",
        "Assist with cellar duties.",
        "Perform waiting duties for food or beverages, including cleaning tables.",
        "Handle cash transactions.",
        "Attend to snack bar duties.",
        "Conduct delivery tasks.",
        "Take reservations, greet, and seat guests.",
      ],
      3: [
        "Serve, dispense, or mix liquor.",
        "Assist with cellar duties.",
        "Perform waiting duties for both food and liquor, including cleaning tables.",
        "Handle cash transactions.",
        "Assist in training and supervising lower-classified food and beverage attendants.",
        "Conduct delivery tasks.",
        "Take reservations, greet, and seat guests.",
      ],
      4: ["Perform specialised skilled duties in a fine dining room or restaurant."],
      5: ["Supervise, train, and coordinate food and beverage staff.", "Manage stock control for one or more bars."],
    },
    "Restaurant and Cafe": {
      1: [
        "Pick up glasses.",
        "Assist higher-classified food and beverage attendants, excluding customer service.",
        "Clear food plates from tables.",
        "Set and wipe down tables.",
        "Clean and tidy associated areas.",
        "Handle cash transactions.",
        "Cook breakfasts and snacks, bake, prepare pastries, or butcher.",
        "Perform general cleaning duties within the kitchen, food preparation area, or scullery.",
        "Clean cooking utensils and general utensils used in the kitchen and restaurant.",
        "Assist cooking employees.",
        "Assemble and prepare ingredients for cooking.",
        "Conduct general pantry duties.",
      ],
      2: [
        "Serve, dispense, or mix liquor.",
        "Assist with cellar duties.",
        "Perform waiting duties for food or beverages, including cleaning tables.",
        "Handle cash transactions.",
        "Attend to snack bar duties.",
        "Conduct delivery tasks.",
        "Take reservations, greet, and seat guests.",
        "Perform specialised non-cooking duties in the kitchen or food preparation area.",
        "Assist in training and supervising lower-classified food and beverage attendants.",
      ],
      3: [
        "Serve, dispense, or mix liquor.",
        "Assist with cellar duties.",
        "Perform waiting duties for both food and liquor, including cleaning tables.",
        "Handle cash transactions.",
        "Assist in training and supervising lower-classified food and beverage attendants.",
        "Conduct delivery tasks.",
        "Take reservations, greet, and seat guests.",
        "Perform cooking duties such as baking, pastry cooking, or butchering.",
        "Supervise, train, and coordinate lower-classified kitchen attendants.",
      ],
      4: [
        "Perform specialised skilled duties in a fine dining room or restaurant.",
        "Supervise, train, and coordinate food and beverage staff.",
        "Manage stock control for one or more bars.",
      ],
    },
    Kitchen: {
      1: [
        "Perform general cleaning duties within the kitchen or food preparation area, including the scullery.",
        "Clean cooking utensils and general utensils used in the kitchen and restaurant.",
        "Assist cooking employees.",
        "Assemble and prepare ingredients for cooking.",
        "Conduct general pantry duties.",
      ],
      2: [
        "Perform specialised non-cooking duties in a kitchen or food preparation area.",
        "Supervise kitchen attendants of a lower classification.",
      ],
      3: ["Supervise, train, and coordinate kitchen attendants of a lower classification."],
    },
    Cook: {
      1: ["Cook breakfasts and snacks.", "Engage in baking, pastry cooking, or butchering."],
      2: ["Perform cooking duties, including baking, pastry cooking, or butchering."],
      3: [
        "Work as a commis chef or equivalent, having completed an apprenticeship or passed the appropriate trade test.",
        "Engage in cooking, baking, pastry cooking, or butchering duties.",
      ],
      4: [
        "Work as a demi chef or equivalent, having completed an apprenticeship or passed the appropriate trade test.",
        "Perform general or specialised cooking, butchering, baking, or pastry cooking duties.",
        "Supervise and train other cooks and kitchen employees.",
      ],
      5: [
        "Work as a chef de partie or equivalent, having completed an apprenticeship or passed the appropriate trade test.",
        "Perform general and specialised cooking, butchering, or pastry cooking duties.",
        "Supervise and train kitchen employees.",
        "Manage ordering and stock control.",
        "Supervise other cooks and kitchen employees in a single kitchen establishment.",
      ],
    },
    Chef: {
      1: [
        "Work as a commis chef or equivalent, having completed an apprenticeship or passed the appropriate trade test.",
        "Engage in cooking, baking, pastry cooking, or butchering duties.",
      ],
      2: [
        "Work as a demi chef or equivalent, having completed an apprenticeship or passed the appropriate trade test.",
        "Perform general or specialised cooking, butchering, baking, or pastry cooking duties.",
        "Supervise and train other cooks and kitchen employees.",
      ],
      3: [
        "Work as a chef de partie or equivalent, having completed an apprenticeship or passed the appropriate trade test.",
        "Perform general and specialised cooking, butchering, or pastry cooking duties.",
        "Supervise and train kitchen employees.",
        "Manage ordering and stock control.",
        "Supervise other cooks and kitchen employees in a single kitchen establishment.",
      ],
    },
    Store: {
      1: ["Receive and store general and perishable goods.", "Clean the store area."],
      2: ["Operate mechanical lifting equipment, such as a forklift.", "Perform more complex storeperson duties."],
      3: [
        "Implement quality control techniques and procedures.",
        "Understand and manage a store/warehouse area or a large section of such an area.",
        "Exhibit highly developed interpersonal and communication skills.",
        "Supervise and provide direction and guidance to other employees.",
        "Assist in providing on-the-job training and induction.",
        "Liaise with management, suppliers, and customers regarding store operations.",
        "Detail and coordinate the activities of other storepersons.",
        "Act in a leading hand capacity for over 10 storepersons.",
        "Maintain control registers, including inventory control.",
        "Prepare and reconcile reports on stock movements, dispatches, etc.",
        "Supervise the receipt and delivery of goods and manage the contents of a store.",
      ],
      Administration: {
        1: [
          "Perform basic clerical and routine office duties, such as collating, filing, photocopying, and delivering messages.",
        ],
        2: [
          "Engage in general clerical or office duties, such as typing, filing, basic data entry, and performing calculating functions.",
        ],
        3: [
          "Operate a switchboard, paging system, and office equipment.",
          "Use keyboard and function keys to enter and retrieve data through a computer terminal.",
          "Copy type at 25 words per minute with at least 98% accuracy.",
          "Maintain mail register and records.",
          "Maintain established paper-based filing/records systems, including creating and indexing new files, distributing files within the organisation, and monitoring file locations.",
          "Transcribe information into records, complete forms, and take telephone messages.",
          "Acquire and apply a working knowledge of office or sectional operating procedures and requirements.",
          "Acquire and apply a working knowledge of the organisation’s structure and personnel to deal with inquiries, locate appropriate staff, relay internal information, respond to or redirect inquiries, and greet visitors.",
          "Keep appropriate records.",
          "Sort, process, and record original source financial documents, such as invoices, cheques, and correspondence on a daily basis.",
          "Maintain and record petty cash, prepare bank deposits and withdrawals, and perform banking duties.",
          "Operate computerised radio telephone equipment, micro/personal computers, printing devices attached to personal computers, or dictaphone equipment.",
          "Produce documents and correspondence using standard formats, touch type at 40 words per minute with at least 98% accuracy, or audio type.",
          "Use one or more software packages developed for a micro/personal computer to operate and populate a database, spreadsheet, or worksheet, and graph previously prepared spreadsheets.",
          "Follow standard procedures or templates using existing models or fields of information.",
          "Create, maintain, and generate simple reports.",
          "Use a central computer resource to an equivalent standard.",
          "Use one or more software packages to create, format, edit, proofread, spell check, correct, print, and save text documents, such as standard correspondence and business documents.",
          "Take shorthand notes at 70 words per minute and transcribe with 95% accuracy.",
          "Arrange travel bookings and itineraries, make appointments, screen telephone calls, follow visitor protocol procedures, and establish telephone contact on behalf of an executive.",
          "Apply a working knowledge of the organisation’s products or services, functions, locations, and clients.",
          "Respond to and act on most internal or external inquiries within the functional area.",
          "Use and maintain a computer-based record management system to identify, access, and extract information from internal sources; maintain circulation, indexing, and filing systems for publications; review files; close files; and archive files.",
          "Maintain financial records and journals, collect and prepare time and wage records, prepare accounts queries from debtors, and post transactions to the ledger.",
        ],
        4: [
          "Coordinate other clerical staff, having completed the appropriate level of training, including a supervisory course.",
        ],
      },
      Security: {
        1: ["Assist in maintaining dress standards and good order at an establishment."],
        2: [
          "Manage timekeeping for employees.",
          "Ensure the security of keys.",
          "Check in and out delivery vehicles.",
          "Supervise Doorperson/Security Officer Grade 1 employees.",
        ],
      },
    },
  },
  MA000003: {
    "Fast Food": {
      1: [
        "Prepare, receive orders, cook, sell, serve, or deliver meals, snacks, and beverages primarily for takeaway or in food courts.",
        "Perform duties as directed within the limits of competence, skills, and training.",
        "Conduct incidental cleaning, including cleaning of toilets.",
      ],
      2: [
        "Supervise Fast Food Employees Level 1 on a day-to-day basis.",
        "Train new employees.",
        "Exercise trade skills as required.",
      ],
      3: ["Be in charge of a shop, food outlet, or delivery outlet as appointed by the employer."],
    },
  },
  MA000009: {
    "Food and Beverage": {
      1: [
        "Pick up glasses.",
        "Empty ashtrays.",
        "Assist higher-classified food and beverage attendants, excluding customer service.",
        "Clear food plates from tables.",
        "Set and wipe down tables.",
        "Clean and tidy associated areas.",
      ],
      2: [
        "Serve, dispense, and mix liquor, including selling liquor from the bottle department.",
        "Assist in the cellar or bottle department.",
        "Perform waiting duties for food or beverages, including cleaning tables.",
        "Handle cash transactions.",
        "Attend to snack bar duties.",
        "Conduct delivery tasks.",
        "Take reservations, greet, and seat guests.",
      ],
      3: [
        "Operate a mechanical lifting device.",
        "Attend to a wagering terminal, electronic gaming terminal, or similar terminal.",
        "Take full control of a cellar or liquor store, including receipt, delivery, and recording of goods.",
        "Mix a range of sophisticated drinks.",
        "Train and supervise food and beverage attendants of a lower grade.",
      ],
      4: [
        "Perform specialised skilled duties in a fine dining room or restaurant, having completed an apprenticeship or passed the appropriate trade test.",
      ],
      5: ["Supervise, train, and coordinate food and beverage staff.", "Manage stock control for one or more bars."],
    },
    Kitchen: {
      1: [
        "Perform general cleaning duties within the kitchen, food preparation area, or scullery, including cleaning cooking and general utensils.",
        "Assist cooking employees.",
        "Assemble and prepare ingredients for cooking.",
        "Conduct general pantry duties.",
      ],
      2: [
        "Perform specialised non-cooking duties in the kitchen or food preparation area.",
        "Supervise kitchen attendants.",
      ],
      3: ["Supervise, train, and coordinate kitchen attendants of a lower classification."],
      4: [
        "Work as a commis chef or equivalent, having completed an apprenticeship or passed the appropriate trade test.",
        "Engage in cooking, baking, pastry cooking, or butchering duties.",
      ],
      5: [
        "Work as a demi chef or equivalent, having completed an apprenticeship or passed the appropriate trade test.",
        "Perform general or specialised cooking, butchering, baking, or pastry cooking duties.",
        "Supervise and train other cooks and kitchen employees.",
      ],
      6: [
        "Work as a chef de partie or equivalent, having completed an apprenticeship or passed the appropriate trade test.",
        "Perform general and specialised cooking, butchering, baking, or pastry cooking duties.",
        "Supervise and train kitchen employees.",
        "Manage ordering and stock control.",
        "Supervise kitchen employees in a single kitchen establishment.",
      ],
    },
    "Guest Services": {
      1: [
        "Perform laundry or linen duties, including minor repairs to linen or clothing (e.g., buttons, zips, seams).",
        "Collect and deliver guests’ personal dry cleaning and laundry, linen, and associated materials to and from accommodation areas.",
        "Perform general cleaning duties.",
        "Park guests’ motor vehicles.",
      ],
      2: [
        "Service and clean accommodation areas.",
        "Receive and assist guests at the entrance to the establishment.",
        "Drive a passenger vehicle or courtesy bus.",
        "Transfer guests’ baggage to and from rooms.",
        "Assist in the dry cleaning process.",
        "Perform cleaning duties using specialised equipment and chemicals.",
        "Provide butler services such as food, beverage, and personalised guest service.",
      ],
      3: [
        "Supervise guest service employees of a lower classification.",
        "Provide butler services such as food, beverage, and personalised guest service.",
        "Conduct major repairs to linen or clothing, including basic tailoring and major alterations.",
        "Perform dry cleaning duties.",
      ],
      4: [
        "Work as a tradesperson in dry cleaning, tailoring, or as a butler, having completed an apprenticeship or passed the appropriate trade test.",
      ],
      5: ["Supervise, train, and coordinate employees in the housekeeping department."],
    },
  },
  Administration: {
    2: [
      "Perform basic clerical and routine office duties, such as collating, filing, photocopying, and delivering messages.",
    ],
    3: [
      "Engage in general clerical or office duties, such as typing, filing, basic data entry, and performing calculating functions.",
    ],
    4: [
      "Operate a switchboard, paging system, and office equipment.",
      "Use keyboard and function keys to enter and retrieve data through a computer terminal.",
      "Copy type at 25 words per minute with at least 98% accuracy.",
      "Maintain mail register and records.",
      "Maintain established paper-based filing or records systems, including creating and indexing new files, distributing files within the organisation, and monitoring file locations.",
      "Transcribe information into records, complete forms, and take telephone messages.",
      "Acquire and apply a working knowledge of office or sectional operating procedures and requirements.",
      "Acquire and apply a working knowledge of the organisation’s structure and personnel to deal with inquiries, locate appropriate staff, relay internal information, respond to or redirect inquiries, and greet visitors.",
      "Keep appropriate records.",
      "Sort, process, and record original source financial documents, such as invoices, cheques, and correspondence on a daily basis.",
      "Maintain and record petty cash, prepare bank deposits and withdrawals, and perform banking duties.",
      "Operate computerised radio telephone equipment, micro/personal computers, printing devices attached to personal computers, or dictaphone equipment.",
      "Produce documents and correspondence using standard formats, touch type at 40 words per minute with at least 98% accuracy, or audio type.",
      "Use one or more software application packages developed for a micro/personal computer to operate and populate a database, spreadsheet, or worksheet, and graph previously prepared spreadsheets.",
      "Follow standard procedures or templates for the preceding functions using existing models or fields of information.",
      "Create, maintain, and generate simple reports.",
      "Use a central computer resource to an equivalent standard.",
      "Use one or more software packages to create, format, edit, proofread, spell check, correct, print, or save text documents, such as standard correspondence and business documents.",
      "Take shorthand notes at 70 words per minute and transcribe with at least 95% accuracy.",
      "Arrange travel bookings and itineraries, make appointments, screen telephone calls, follow visitor protocol procedures, and establish telephone contact on behalf of an executive.",
      "Apply a working knowledge of the organisation’s products or services, functions, locations, and clients.",
      "Respond to and act on most internal or external inquiries in their functional area.",
      "Use and maintain a computer-based record management system to identify, access, and extract information from internal sources; maintain circulation, indexing, and filing systems for publications, review files, close files, or archive files.",
      "Maintain financial records and journals, collect and prepare time and wage records, prepare accounts queries from debtors, and post transactions to the ledger.",
    ],
    5: [
      "Coordinate other clerical staff, having completed the appropriate level of training, including a supervisory course.",
    ],
  },
  Leisure: {
    2: [
      "Assist as an instructor or pool attendant.",
      "Set up, distribute, and care for equipment.",
      "Take bookings for leisure activities.",
    ],
    3: [
      "Lead classes or direct leisure activities in areas such as sporting facilities, health clubs, and swimming pools.",
      "Have the appropriate level of training for directing these activities.",
    ],
    4: [
      "Plan and coordinate leisure activities for guests.",
      "Supervise other leisure attendants, if required.",
      "Have the appropriate level of training for planning and coordinating these activities.",
    ],
  },
  Store: {
    2: ["Receive and store general and perishable goods.", "Clean the store area."],
    3: [
      "Perform all duties of a Storeperson Grade 1.",
      "Operate mechanical lifting equipment, such as a forklift.",
      "Perform more complex storeperson duties.",
    ],
    4: [
      "Implement quality control techniques and procedures.",
      "Understand and manage a store or warehouse area, or a large section of such an area.",
      "Demonstrate highly developed interpersonal and communication skills.",
      "Supervise and provide direction and guidance to other employees, including on-the-job training and induction.",
      "Utilise skills gained from the successful completion of an appropriate warehousing certificate.",
      "Liaise with management, suppliers, and customers regarding store operations.",
      "Detail and coordinate activities of other storepersons, acting in a leading hand capacity for more than 10 storepersons.",
      "Maintain control registers, including inventory control, and prepare and reconcile regular reports on stock movements or dispatches.",
      "Supervise the receipt and delivery of goods, record outgoing goods, and manage the contents of a store.",
    ],
  },
};

module.exports = responsibilities;
