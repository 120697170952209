import { truncate } from "src/utility/format";
import styles from "./FilterCard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import useFetch from "src/hooks/global/useFetch";
import { useCallback } from "react";
import useShifts from "src/hooks/shifter/useShifts";
import { useNavigate } from "react-router-dom";

const FilterCard = ({ positions = [], dates, wage_range, location, index, _id }) => {
  const { handleSearch } = useShifts();
  const navigate = useNavigate();

  const { post: deleteFilter, updateCache } = useFetch({
    options: {
      onMutate: () => {
        updateCache("Filter.SavedFiltersForUser", (oldData = []) => {
          return oldData.filter((filter) => filter._id !== _id);
        });
      },
    },
  });

  const handleSelectFilter = useCallback(() => {
    //TODO Implement handle search with selected filter
    // handleSearch({});
    navigate("/");
  }, [navigate]);

  return (
    <div className={styles["container"]} onClick={handleSelectFilter}>
      <p className={styles["title"]}>Filter {index + 1}</p>
      {positions.length > 0 && <FilterRow name="What" value={positions.map((ind) => ind.label).join(", ")} />}
      {location && <FilterRow name="Where" value={`${location.location?.full_address} - ${location.range}km`} />}
      {dates && (
        <FilterRow
          name="When"
          value={dates
            .split(",")
            .map((dt) => new Date(dt).toLocaleDateString())
            .join(", ")}
        />
      )}
      {wage_range && <FilterRow name="Wage" value={`$${wage_range[0]} - $${wage_range[1]}`} />}
      <div
        className={styles["icon"]}
        onClick={(e) => {
          e.stopPropagation();
          deleteFilter({
            entity: "Filter",
            method: "delete",
            criteria: {
              _id,
            },
          });
        }}
      >
        <FontAwesomeIcon icon={faHeart} />
      </div>
    </div>
  );
};

const FilterRow = ({ name, value }) => {
  return (
    <div className={styles["row"]}>
      <p className={styles["name"]}>{name}</p>
      <div className={styles["divider"]}></div>
      <p className={styles["value"]}>{truncate(value, 30)}</p>
    </div>
  );
};
export default FilterCard;
