import { toast } from "react-toastify";
import { ToastBody } from "src/hooks/global/useToast";

export const customFetch = async ({
  entity,
  method,
  criteria,
  data: reqData,
  pipeline,
  node = "live-node",
  populate,
  options,
  headers: reqHeaders = {},
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("shiftly-auth-token");

      // Conditionally setting headers
      let headers = {
        ...reqHeaders,
      };
      if (!(reqData instanceof FormData)) {
        headers["Content-Type"] = "application/json";
      }

      if (token) {
        headers["token"] = token;
      }

      // Conditionally setting the body
      const body =
        reqData instanceof FormData
          ? reqData
          : JSON.stringify({
              entity,
              method,
              criteria,
              data: reqData,
              populate,
              pipeline,
              options,
            });

      const baseUrl = process.env.REACT_APP_API_URL;
      const encodedNode = encodeURIComponent(node);
      const url = `${baseUrl}${encodedNode}`;

      const res = await fetch(url, {
        method: "POST",
        headers,
        body,
      });

      if (!res.ok) {
        const error = await res.json();
        throw new Error(error.prettyError || "Network response was not ok.");
      }

      const data = await res.json();

      if (data.token) {
        localStorage.setItem("shiftly-auth-token", data.token);
      }

      if (data.status !== 200) {
        data?.error?.prettyError &&
          (!data?.error?.field || data?.error?.field === "toast") &&
          toast.error(<ToastBody title={"Aw snap"} message={data?.error?.prettyError} />);
        reject(data);
        return;
      }

      resolve(data);
    } catch (error) {
      reject({ status: 400, data: [], error });
    }
  });
};

export const buildAwardSummaryLink = (award) => {
  return `https://www.fairwork.gov.au/employment-conditions/awards/awards-summary/${award}-summary`;
};

export const buildGoogleMapsLink = ({ lat, lng }) => {
  return `https://www.google.com/maps?q=${lat},${lng}`;
};
