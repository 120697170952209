import withInputBase from "src/components/HOC/withInputBase";
import { useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import useStyling from "src/hooks/global/useStyling";
import useInlineStyles from "src/hooks/global/useInlineStyles";
import styles from "./Switch.module.css";

const Switch = ({
  value,
  setValue,
  title,
  label,
  error,
  required,
  disabled,
  className,
  formProps,
  name,
  ...props
}) => {
  const styling = useStyling(styles);
  const [toggled, setToggled] = useState(value || false);
  const style = useInlineStyles(props);

  useEffect(() => {
    setToggled(value);
  }, [value]);

  const handleClick = useCallback(() => {
    if (disabled) return;
    setToggled((prev) => {
      const newValue = !prev;
      setValue(newValue);
      return newValue;
    });
  }, [disabled, setValue]);

  return (
    <div className={styling("wrapper")}>
      <div
        className={clsx(styling("container", disabled && "disabled"), className)}
        style={style}
        onClick={handleClick}
      >
        <input type="checkbox" {...formProps} checked={toggled ?? true} style={{ display: "none" }} />
        <div className={clsx(styling("switch"), toggled && styling("checked"))}>
          <div className={styling("switch-button")}></div>
        </div>
        <div className={styling("switch-details")}>
          <h6>{title}</h6>
          <p className={styling("label")}>
            {label}
            {required && <span>*</span>}
          </p>
        </div>
      </div>
      {error && (
        <div className={styling("error-container")}>
          <p className={styling("message")}>{error}</p>
        </div>
      )}
    </div>
  );
};

export default withInputBase(Switch);
