import { useEffect } from "react";

const useAsyncEffect = (asyncFunction, deps = []) => {
  useEffect(() => {
    (async () => {
      try {
        await asyncFunction();
      } catch (error) {
        console.error("Error in useAsyncEffect:", error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useAsyncEffect;
