import styles from "./SchedulerPositionRow.module.css";
import { useMemo, useCallback } from "react";
import Each from "src/components/func/Each";
import { getDayOfWeekStartingMonday } from "src/utility/date";
import ShiftSlot from "./SchedulerShiftSlot";
import { motion } from "framer-motion";
import usePositions from "src/hooks/business/usePositions";
import { useScheduler } from "src/contexts/Scheduler";
import { truncate } from "src/utility/format";

const SchedulerPositionRow = ({ position, shifts = [], applicants, additive }) => {
  const { levels } = usePositions();
  const { isLoading } = useScheduler();

  const positionName = useMemo(() => truncate(position?.name, 15), [position?.name]);

  const createNewRow = useCallback(
    (shift) => {
      if (shift?.type === "external") {
        return {
          title: <p>{shift?.position?.provider}</p>,
          days: Array(7).fill({ status: "free" }),
        };
      } else if (shift?.type === "internal") {
        return {
          title: <p>Internal</p>,
          days: Array(7).fill({ status: "free" }),
        };
      } else {
        return {
          title: (
            <p>
              {levels[position?.classification_level - 1] || "N/A"}
            </p>
          ),
          days: Array(7).fill({ status: "free" }),
        };
      }
    },
    [levels, position?.classification_level]
  );

  const shiftRows = useMemo(() => {
    const initialRows = shifts.reduce((rows, shift) => {
      const dayIndex = getDayOfWeekStartingMonday(new Date(shift.start_time));
      const shiftApplicants = applicants.filter((app) => app.shift === shift._id);
      const shiftStatus = shiftApplicants.length > 0 && shift.status === "published" ? "hasApplicants" : shift.status;

      let shiftAddedToRow = false;

      rows.forEach((row) => {
        if (!shiftAddedToRow && row.days[dayIndex]?.status === "free") {
          row.days[dayIndex] = { status: shiftStatus, shift: { ...shift, applicants: shiftApplicants } };
          shiftAddedToRow = true;
        }
      });

      if (!shiftAddedToRow) {
        const newRow = createNewRow(shift);
        newRow.days[dayIndex] = { status: shiftStatus, shift: { ...shift, applicants: shiftApplicants } };
        rows.push(newRow);
      }

      return rows;
    }, []);

    additive &&
      initialRows.push({
        title: (
          <p>
            Add <br />
            {positionName} +
          </p>
        ),
        days: Array(7).fill({ status: "add" }),
      });
    return initialRows;
  }, [shifts, applicants, additive, createNewRow, positionName]);

  const renderRow = useCallback(
    ({ title, days }, idx) => (
      <motion.div
        key={idx}
        className={styles["row-container"]}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        exit={{ opacity: 0 }}
      >
        <>
          <div className={styles["row-title"]}>{title}</div>
          <Each
            of={days}
            render={({ key, ...day }, idx) => {
              const k = day?.shift?._id + day?.shift?.start_time?.toString() + day?.shift?.position?._id || key;
              return <ShiftSlot {...day} key={k} index={idx} position={position} />;
            }}
          />
        </>
      </motion.div>
    ),
    [position]
  );

  return <div className={styles["shift-rows"]}>{!isLoading ? <Each of={shiftRows} render={renderRow} /> : <></>}</div>;
};

export default SchedulerPositionRow;
