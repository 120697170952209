import styles from "./BusinessHome.module.css";
import useBusiness from "src/hooks/business/useBusiness";
import NewBusinessSetup from "./NewBusinessSetup/HQNewBusinessSetup";
import useDelayUnmount from "src/hooks/global/useDelayUnmount";
import LoadingSpinner from "src/components/animations/LoadingSpinner";
import { useEffect, useState } from "react";
import SearchAndDisplay from "src/components/layout/SearchAndDisplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import StandardLayout from "../Account/components/StandardLayout";
import { faGear } from "@fortawesome/pro-regular-svg-icons";
import useStyling from "src/hooks/global/useStyling";
import useFetch from "src/hooks/global/useFetch";

const BusinessHome = () => {
  const { availableBusinesses, loading, setActiveBusiness, isLoading } = useBusiness();
  const [newBusinessModal, setNewBusinessModal] = useState(false);
  const [elements, setElements] = useState([]);

  const showLoader = useDelayUnmount(loading);
  const styling = useStyling(styles);

  useEffect(() => {
    if (!isLoading && !availableBusinesses.length) {
      setNewBusinessModal(true);
    }
  }, [isLoading, availableBusinesses]);

  useEffect(() => {
    setElements(
      availableBusinesses.map((business, index) => ({
        original: business,
        content: <></>,
        footerContent: <></>,
        listContent: (
          <BusinessList {...business} index={index} business={business} setActiveBusiness={setActiveBusiness} />
        ),
      }))
    );
  }, [availableBusinesses, setActiveBusiness]);

  return (
    <>
      <NewBusinessSetup newBusinessModal={newBusinessModal} setNewBusinessModal={setNewBusinessModal} />
      <StandardLayout
        heading={
          <h1>
            All <span>Businesses</span>
          </h1>
        }
        breadcrumb={"Account Settings /"}
        returnLink={"/account-settings"}
        buttonText={"Create New Business"}
        buttonEvent={() => setNewBusinessModal(true)}
        size="large"
        showBackButton={false}
      >
        <div className={styling("container")}>
          {showLoader ? (
            <div className={styling("loading-container")}>
              <LoadingSpinner loader={loading} />
            </div>
          ) : (
            <SearchAndDisplay
              isLoading={isLoading}
              hideSearchBar
              elementsName="Businesses"
              searchPlaceholder={
                availableBusinesses.length
                  ? "Try " + availableBusinesses[0]?.name + "..."
                  : "Search your businesses here"
              }
              searchFields={["name"]}
              elements={elements}
              emptyStateText={'You have no businesses setup. Click "Create New Business" to get started.'}
              hideToggle={true}
              defaultView={"list"}
            />
          )}
        </div>
      </StandardLayout>
    </>
  );
};

const BusinessList = ({ name, setActiveBusiness, business, _id }) => {
  const navigate = useNavigate();
  const styling = useStyling(styles);

  const { data: locations } = useFetch({
    request: {
      entity: "Location",
      criteria: { business: _id },
    },
    dependency: _id,
  });

  return (
    <div
      className={styling("bl-container")}
      onClick={(e) => {
        e.stopPropagation();
        setActiveBusiness(business);
        navigate("/shifts", business);
      }}
    >
      <div className={styling("bc-header")}>
        <div className={styling("bc-title")}>
          <h2>{name}</h2>
        </div>
        <div className={styling("bl-divider")}></div>
        <div className={styling("bc-locations")}>
          <h2>{locations?.length} locations</h2>
        </div>
        <div className={styling("bl-divider")}></div>
      </div>
      <div className={styling("bl-controllers")}>
        <div
          className={styling("bc-settings")}
          onClick={(e) => {
            e.stopPropagation();
            setActiveBusiness(business);
            navigate("/business-account");
          }}
        >
          <FontAwesomeIcon icon={faGear} />
        </div>
        <div className={styling("bl-divider")}></div>
        <div className={styling("bl-chevron")}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
    </div>
  );
};

export default BusinessHome;
