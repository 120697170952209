import SideLayout from "src/components/layout/SideLayout";
import styles from "../EmploymentDetails.module.css";
import useStyling from "src/hooks/global/useStyling";
import { useCallback } from "react";
import Button from "src/components/UI/buttons/Button";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import Input from "src/components/UI/inputs/Input";
import Checkbox from "src/components/UI/inputs/Checkbox";
import useOnboardingComplete from "src/hooks/shifter/useOnboardingComplete";
import useFetch from "src/hooks/global/useFetch";
import useAuth from "src/hooks/global/useAuth";
import Dropdown from "src/components/UI/inputs/Dropdown";
import FormV2 from "src/components/func/FormV2";

const EDTaxDetails = (props) => {
  const styling = useStyling(styles);
  const { user } = useAuth();
  const { taxDeclaration } = useOnboardingComplete();

  const { post, isLoading, isError, updateCache } = useFetch({
    options: {
      onSuccess: (data) => {
        updateCache("TaxDeclaration.GetTaxDeclaration", data);
      },
    },
  });

  const handleSubmit = useCallback(
    async (data) => {
      return await post({
        entity: "TaxDeclaration",
        method: "update",
        criteria: { user: user?._id },
        data: {
          ...data,
          user: user?._id,
        },
        options: { upsert: true },
      });
    },
    [post, user]
  );

  return (
    <SideLayout heading={"Tax Details"} {...props}>
      <div className={styling("form-container")}>
        <FormV2 onSubmit={handleSubmit} initial={taxDeclaration}>
          <Input name={"tax_file_number"} />
          <Dropdown name={"residency_status"} />
          <div className={styling("checkbox-container")}>
            <Checkbox name={"has_loan_or_student_debt"} />
          </div>
          <div className={styling("checkbox-container")}>
            <Checkbox name={"tax_free_threshold"} />
          </div>
          <Button
            theme={"secondary"}
            type="submit"
            loading={isLoading}
            error={isError}
            className={styling("save-btn")}
            icon={faSave}
          >
            Save Tax Details
          </Button>
          <Button
            theme={"secondary-transparent"}
            className={styling("save-btn")}
            onClick={() => {
              props.setShow(false);
            }}
          >
            Cancel
          </Button>
        </FormV2>
      </div>
    </SideLayout>
  );
};

export default EDTaxDetails;
