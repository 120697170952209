import { useEffect } from "react";
import styles from "./Notifications.module.css";
import Each from "src/components/func/Each";
import NotificationWrapper from "src/components/UI/notifications/NotificationWrapper";
import useStyling from "src/hooks/global/useStyling";
import Illustration from "src/assets/svg/Illustrations/NoShifts.svg";

const Notifications = ({ readNotifications = [], unreadNotifications = [], markAsRead = () => {} }) => {
  const styling = useStyling(styles);

  useEffect(() => {
    return () => {
      markAsRead();
    };
  }, [markAsRead]);


  return (
    <div className={styles["container"]}>
      {unreadNotifications?.length === 0 && readNotifications.length === 0 && (
        <div className={styling("no-notifications")}>
          <div className={styling("no-image")}>
            <img src={Illustration} alt="Youre all caught up" />
          </div>
          <p>You're all caught up!</p>
        </div>
      )}
      {unreadNotifications?.length > 0 && (
        <div className={styles["notifications"]}>
          <h4>Unread</h4>
          <Each
            of={unreadNotifications}
            render={({ key, ...notification }) => <NotificationWrapper {...notification} key={key} />}
          />
        </div>
      )}
      {readNotifications.length > 0 && (
        <div className={styles["notifications"]}>
          <h4>Read</h4>
          <Each
            of={readNotifications}
            render={({ key, ...notification }) => <NotificationWrapper {...notification} key={key} />}
          />
        </div>
      )}
    </div>
  );
};

export default Notifications;
