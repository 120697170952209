import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Stepper from "src/components/UI/layout/Stepper";
import BusinessDetails from "./HQNewBusinessDetails";
import HQNewBusinessLocations from "./HQNewBusinessLocations";
import styles from "./HQNewBusinessSetup.module.css";
import NewBusinessIndustry from "./HQNewBusinessIndustry";
import useFetch from "src/hooks/global/useFetch";
import HQMultipleLocations from "./HQMultipleLocations";
import { useNavigate } from "react-router-dom";
import useBusiness from "src/hooks/business/useBusiness";
import ModalLabel from "src/components/misc/ModalLabel";
import FormV2 from "src/components/func/FormV2";

const { businessSetupMessages } = require("@shiftly/shared/utility/Messages");

const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const NewBusinessSetup = ({ newBusinessModal, setNewBusinessModal }) => {
  const [formData, setFormData] = useState({
    timezone: clientTimezone,
  });
  const [activeStep, setActiveStep] = useState(0);

  const { refresh } = useFetch();

  useEffect(() => {
    setFormData({ timezone: clientTimezone });
    setActiveStep(0);
  }, [newBusinessModal]);

  const navigate = useNavigate();
  const { activeBusiness } = useBusiness();
  const formRef = useRef();

  const { post: postBusiness, isLoading } = useFetch({
    options: {
      onSuccess: () => {
        !activeBusiness && navigate("/shifts");
        refresh("availableBusinesses");
        setNewBusinessModal(false);
        formRef.current.reset();
        setFormData({ timezone: clientTimezone });
      },
    },
  });

  const steps = useMemo(
    () => [
      {
        label: "Business Details",
        content: <BusinessDetails />,
        validation: async () => await formRef.current.validateFields(["name"]),
      },
      {
        label: "Industry",
        content: <NewBusinessIndustry data={formData} setActiveStep={setActiveStep} />,
        validation: async () => await formRef.current.validateFields(["industry"]),
      },
      {
        label: "MultipleLocations",
        content: <HQMultipleLocations setActiveStep={setActiveStep} />,
        validation: async () => await formRef.current.validateFields(["multipleLocations"]),
      },
      {
        label: "Locations",
        content: (
          <HQNewBusinessLocations
            data={formData}
            setData={setFormData}
            businessName={formData?.name}
            multipleLocations={formData?.multipleLocations === "yes"}
          />
        ),
        validation: async () =>
          await formRef.current.validateFields(["timezone", "street", "city", "state", "post_code"]),
      },
    ],
    [formData]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const { name, industry, address, locationName, timezone } = formData;

      if (!name) {
        throw new Error(businessSetupMessages.business_details_incomplete.prettyError);
      }

      if (!industry) {
        throw new Error(businessSetupMessages.selected_indsutry_incomplete.prettyError);
      }

      if (!timezone || !address) {
        throw new Error(businessSetupMessages.location_incomplete.prettyError);
      }

      const business = {
        ...formData,
        location: { name: locationName || name, timezone },
      };

      postBusiness({
        entity: "Business",
        method: "create",
        data: business,
      });
    } catch ({ message }) {
      
    }
  }, [postBusiness, formData]);

  return (
    <FormV2 data={formData} setData={setFormData} ref={formRef} onSubmit={handleSubmit}>
      <Stepper
        label={<ModalLabel text="Business" />}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={steps}
        className={styles["modal"]}
        showStepper={newBusinessModal}
        setShowStepper={setNewBusinessModal}
        onComplete={handleSubmit}
        loading={isLoading}
        keyDown={false}
      ></Stepper>
    </FormV2>
  );
};

export default NewBusinessSetup;
