import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ApplicantCard.module.css";
import useStyling from "src/hooks/global/useStyling";
import { faCalendar, faClock, faComment, faDollarSign, faUser } from "@fortawesome/pro-regular-svg-icons";
import RatingDisplay from "src/components/misc/RatingDisplay";
import { roundNumber } from "src/utility/format";
import Button from "src/components/UI/buttons/Button";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ApplicantCard = ({ applicant, profile, actionApplicant, showButtons = false, onClick, setShowConfirmModal }) => {
  const { user, shift, total } = applicant;
  const navigate = useNavigate();

  const styling = useStyling(styles);
  return (
    <div className={styling("container")} onClick={onClick}>
      <div className={styling("header")}>
        <div className={styling("header-details")}>
          <div className={styling("user")}>
            <h5>
              {user?.first_name} {user?.last_name}
            </h5>
          </div>
          <RatingDisplay rating={profile?.rating} />
        </div>
        <div
          className={styling("header-actions")}
          onClick={(e) => {
            e.stopPropagation();
            navigate("/messaging?user=" + user._id);
          }}
        >
          <div className={styling("header-action")}>
            <FontAwesomeIcon icon={faComment} />
          </div>
        </div>
      </div>
      <div className={styling("body")}>
        <div className={styling("grid-item")}>
          <div className={styling("grid-icon")}>
            <FontAwesomeIcon icon={faUser} />
          </div>
          <p className={styling("grid-text")}>
            {shift?.day_of_week} {shift?.prettyDate}
          </p>
        </div>

        <div className={styling("grid-item")}>
          <div className={styling("grid-icon")}>
            <FontAwesomeIcon icon={faCalendar} />
          </div>
          <p className={styling("grid-text")}> {shift?.shiftPeriod}</p>
        </div>

        <div className={styling("grid-item")}>
          <div className={styling("grid-icon")}>
            <FontAwesomeIcon icon={faDollarSign} />
          </div>
          <p className={styling("grid-text")}>{roundNumber(total)}</p>
        </div>

        <div className={styling("grid-item")}>
          <div className={styling("grid-icon")}>
            <FontAwesomeIcon icon={faClock} />
          </div>
          <p className={styling("grid-text")}>{shift?.position?.name}</p>
        </div>
      </div>
      {showButtons && (
        <div className={styling("footer")}>
          <Button
            theme={"secondary-outline"}
            onClick={() => actionApplicant({ applicantion_id: applicant?._id, user, shift, action: "declined" })}
            className={styling("button")}
            icon={faTimes}
            iconSide="left"
          >
            Decline
          </Button>
          <Button
            theme={"primary"}
            onClick={(e) => {
              e.stopPropagation();
              setShowConfirmModal({ ...applicant, profile });
            }}
            className={styling("button")}
            icon={faCheck}
            iconSide="left"
          >
            Accept
          </Button>
        </div>
      )}
    </div>
  );
};

export default ApplicantCard;
