import { useState, useEffect, useCallback, useMemo } from "react";
import { useDrag, useDrop } from "react-dnd";
import useFetch from "src/hooks/global/useFetch";
import moment from "moment-timezone";
import useAlerts from "src/contexts/Alerts";

const useShiftDrag = ({ shift, position, index, periods, status }) => {
  const [isAltKeyPressed, setIsAltKeyPressed] = useState(false);

  const { confirm } = useAlerts();

  // Unified key handler to remove debouncing (optional)
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Alt") setIsAltKeyPressed(true);
    };
    const handleKeyUp = (event) => {
      if (event.key === "Alt") setIsAltKeyPressed(false);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const {
    post: updateShift,
    refresh,
    updateCache,
  } = useFetch({
    options: {
      onMutate: ({ data: shift, payload, method }) => {
        if (method === "delete") return;
        updateShiftCache(payload.type, shift, {
          position: payload.position,
          location: payload.location,
          business: payload.business,
          position_group: payload.position_group?._id,
          user: payload.user || undefined,
        });
      },
      onError: () => {
        refresh("Shift.ShiftsBetweenDates");
        refresh("InternalShift.ShiftsBetweenDates");
      },
      onSuccess: () => {
        refresh("Shift.ShiftsBetweenDates");
        refresh("InternalShift.ShiftsBetweenDates");
      },
    },
  });

  // Utility to update the cache based on shift type
  const updateShiftCache = useCallback(
    (type, shift, payload) => {
      const cacheKey = type === "shiftly" ? "Shift.ShiftsBetweenDates" : "InternalShift.ShiftsBetweenDates";
      updateCache(cacheKey, (oldData = []) => {
        const findIndex = oldData.findIndex((oldShift) => oldShift._id === shift._id);
        const newShift = { ...shift, ...payload };

        if (findIndex === -1) return [...oldData, newShift];
        const newData = [...oldData]; // Shallow clone
        newData[findIndex] = newShift;
        return newData;
      });
    },
    [updateCache]
  );

  const handleTimeUpdate = useCallback((shift, slotDay) => {
    const originalStartTime = moment.tz(shift.start_time, shift.timezone);
    const originalEndTime = moment.tz(shift.end_time, shift.timezone);

    const newStartTime = slotDay
      .clone()
      .hour(originalStartTime.hour())
      .minute(originalStartTime.minute())
      .second(originalStartTime.second())
      .toISOString();

    let newEndTime = slotDay
      .clone()
      .hour(originalEndTime.hour())
      .minute(originalEndTime.minute())
      .second(originalEndTime.second());

    if (originalStartTime.day() !== originalEndTime.day()) {
      newEndTime.add(1, "day");
    }

    newEndTime = newEndTime.toISOString();

    return { newStartTime, newEndTime };
  }, []);

  const handleDropShift = useCallback(
    async (item) => {
      const { shift: draggedShift, position: draggedPosition } = item;

      //Exit anything external or invalid
      if (draggedPosition.type === "external" || position?.type === "external" || !draggedShift._id) return;

      const slotDay = moment.tz(periods.current[index], draggedShift?.timezone);
      const { newStartTime, newEndTime } = handleTimeUpdate(draggedShift, slotDay);

      const newShift = {
        ...draggedShift,
        start_time: newStartTime,
        end_time: newEndTime,
        location: draggedShift?.location?._id,
        position: position?._id,
        position_group: position?.group?._id,
        business: draggedShift?.business?._id,
        user: draggedShift?.user?._id,
      };

      let payload = {
        location: draggedShift?.location,
        position,
        position_group: position?.group,
        business: draggedShift?.business,
        user: draggedShift?.user,
      };

      if (isAltKeyPressed) delete payload._id;

      if (position.type === "internal" && draggedPosition?.type === "internal") {
        //Dropping internal shift on internal shift

        if (isAltKeyPressed) delete newShift._id;

        await updateShift({
          entity: "InternalShift",
          method: isAltKeyPressed ? "create" : "update",
          criteria: { _id: draggedShift._id },
          data: newShift,
          payload: { ...payload, type: "internal" },
        });
      } else if (!position.type && draggedPosition?.type === "internal") {
        //Dropping internal shift on external slot
        if (
          !(await confirm({
            label: "Publish Shift",
            text: "Are you sure you'd like to publish this shift? You can always edit it later.",
            confirmText: "Publish",
            cancelText: "Cancel",
          }))
        )
          return;

        delete newShift._id;
        await updateShift({
          entity: "Shift",
          method: "create",
          data: { ...newShift, user: null, status: "published" },
          payload: { ...payload, type: "shiftly" },
        });

        await updateShift({
          entity: "InternalShift",
          method: "delete",
          criteria: { _id: draggedShift._id },
          payload: { type: "internal" },
        });
        return;
      } else if (!draggedPosition?.type && position?.type === "internal") {
        //Dropping external shift on internal slot
        return;
      } else {
        //Dropping external shift on external slot
        await updateShift({
          entity: "Shift",
          method: isAltKeyPressed ? "create" : "editShift",
          data: newShift,
          payload: { ...payload, type: "shiftly" },
        });
      }
    },
    [updateShift, periods, index, isAltKeyPressed, position, handleTimeUpdate, confirm]
  );

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "Shift",
      item: { shift, index, position },
      collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    }),
    [shift, index, position]
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "Shift",
      drop: (item) => handleDropShift(item),
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    }),
    [handleDropShift]
  );

  const dragRef = useMemo(() => {
    const undraggable = ["confirmed", "expired", "external"];
    if (status === "add" || undraggable.includes(shift.status)) return null;
    return drag;
  }, [drag, status, shift.status]);

  return useMemo(
    () => ({
      dragRef,
      dropRef: drop,
      isDragging,
      isOver,
    }),
    [dragRef, drop, isDragging, isOver]
  );
};

export default useShiftDrag;
