import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useDrag } from "react-use-gesture";

const SWIPE_THRESHOLD = 0.3;
const DIRECTION_THRESHOLD = 50; // Minimum distance to be considered a swipe
const TAP_THRESHOLD = 100; // Maximum duration for a tap in milliseconds
const MOVE_THRESHOLD = 10; // Maximum movement for a tap in pixels

export const GestureContext = createContext({ bind: () => {}, drag: {}, swipe: {} });

export const useGestures = ({ onSwipe = () => {}, onDrag = () => {} } = {}) => {
  const { drag, swipe, bind } = useContext(GestureContext);

  useEffect(() => {
    swipe.swiped && onSwipe(swipe.direction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipe]);

  useEffect(() => {
    onDrag(drag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drag]);

  return { drag, swipe, bind };
};

export const GestureContextProvider = ({ children }) => {
  const [drag, setDrag] = useState({ dragging: false, velocity: 0, dx: 0, dy: 0 });
  const [swipe, setSwipe] = useState({ swiped: false, direction: "none" });

  const debounceTimeout = useRef(null);

  const debouncedSetSwipe = (swiped, direction) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      setSwipe({ swiped, direction });
    }, 200); // Adjust debounce delay as needed
  };

  const bind = useDrag(({ velocity, down: dragging, movement: [dx, dy], elapsedTime }) => {
    const trigger = velocity > SWIPE_THRESHOLD;
    let direction;

    if (Math.abs(dx) > Math.abs(dy)) {
      direction = dx > 0 ? "right" : "left";
    } else {
      direction = dy > 0 ? "down" : "up";
    }

    setDrag({ dragging, velocity, dx, dy });

    if (dragging) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      return;
    }

    const isTap = elapsedTime < TAP_THRESHOLD && Math.abs(dx) < MOVE_THRESHOLD && Math.abs(dy) < MOVE_THRESHOLD;

    if (isTap || swipe.swiped) {
      debouncedSetSwipe(false, "none");
    } else if (trigger && (Math.abs(dx) > DIRECTION_THRESHOLD || Math.abs(dy) > DIRECTION_THRESHOLD)) {
      debouncedSetSwipe(true, direction);
      setTimeout(() => {
        debouncedSetSwipe(false, "none");
      }, 210);
    } else {
      debouncedSetSwipe(false, "none");
    }
  });

  useEffect(() => {
    return () => {
      // Cleanup debounce timeout on unmount
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  return <GestureContext.Provider value={{ bind, drag, swipe, setSwipe }}>{children}</GestureContext.Provider>;
};
