import React, { useMemo } from "react";
import Each from "src/components/func/Each";
import styles from "./SchedulerCalendarGroup.module.css";
import useStyling from "src/hooks/global/useStyling";
import SchedulerPositionRow from "./SchedulerPositionRow";

const SchedulerCalendarGroup = React.memo(
  ({ positions, shifts = [], applicants = [], thirdPartyShifts = [], internalShifts = [] }) => {
    const styling = useStyling(styles);
    const position = positions[0];

    const sortedPositions = useMemo(() => {
      return [...positions].sort((a, b) => a?.classification_level - b?.classification_level);
    }, [positions]);

    return (
      <div className={styling("container")}>
        <div className={styling("title")}>
          <p>{position?.name}</p>
        </div>
        <Each
          of={sortedPositions}
          render={(position) => {
            const filteredShifts = shifts.filter((shift) => shift.position?._id === position._id);
            const filteredApplicants = applicants.filter((app) =>
              filteredShifts.some((shift) => shift._id === app.shift)
            );
            return (
              <SchedulerPositionRow
                key={position?._id}
                position={position}
                shifts={filteredShifts}
                applicants={filteredApplicants}
              />
            );
          }}
        />
        {/* Internal Shifts */}
        <SchedulerPositionRow
          key={"internalShifts"}
          position={{ type: "internal", group: position?.group }}
          shifts={internalShifts}
          applicants={[]}
        />
        {/* Third Party Shifts */}
        <SchedulerPositionRow
          key={"thirdParty"}
          position={{ type: "external" }}
          shifts={thirdPartyShifts}
          applicants={[]}
        />
        {/* Additive Row */}
        <SchedulerPositionRow
          key={position?._id + "_additive"}
          position={position}
          shifts={[]}
          applicants={[]}
          additive
        />
      </div>
    );
  }
);

export default SchedulerCalendarGroup;
