import { useCallback, useRef, useState } from "react";
import styles from "./MessagesMessageBar.module.css";
import Button from "src/components/UI/buttons/Button";
import TextArea from "src/components/UI/inputs/TextArea";
import useSocket from "src/hooks/global/useSocket";
import useFetch from "src/hooks/global/useFetch";
import useStyling from "src/hooks/global/useStyling";

const MessagesMessageBar = ({ activeConversation }) => {
  const [message, setMessage] = useState("");
  const { sendEvent } = useSocket();
  const styling = useStyling(styles);

  const {
    post: saveMessage,
    isLoading,
    updateCache,
  } = useFetch({
    options: {
      onMutate: ({ data }) => {
        updateCache("Message.GetMessagesWithAccess", (oldData = []) => [...oldData, data]);
      },
      onSuccess: ([mes] = []) => {
        sendEvent("send_message", mes);
      },
    },
  });

  const inputRef = useRef();

  const handleSendMessage = useCallback(() => {
    if (!activeConversation || !message) return;
    const mes = {
      content: message,
      conversation: activeConversation._id,
      user: activeConversation.user?._id,
      location: activeConversation.location,
      for_id: activeConversation.user?._id,
      unread: true,
      sent_date: new Date(),
    };

    saveMessage({
      entity: "Message",
      data: { ...mes },
      method: "create",
    });

    setMessage("");
  }, [message, activeConversation, saveMessage]);

  return (
    <div className={styling("container")}>
      <div className={styling("input-container")}>
        <TextArea
          ref={inputRef}
          resize={"none"}
          placeholder={"Start typing..."}
          value={message}
          setValue={setMessage}
          rows={1}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        />
      </div>
      <Button
        theme={"primary"}
        disabled={isLoading}
        className={styling("send-message-button")}
        onClick={handleSendMessage}
      >
        Send Message
      </Button>
    </div>
  );
};

export default MessagesMessageBar;
