import styles from "./FloatingButton.module.css";
import useStyling from "src/hooks/global/useStyling";
import { useChatContext } from "@copilotkit/react-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";

const FloatingButton = (props) => {
  const { open, setOpen } = useChatContext();
  const styling = useStyling(styles);
  return (
    <button className={styling("container")} onClick={() => setOpen(!open)}>
      <FontAwesomeIcon icon={faSparkles} />
    </button>
  );
};

export default FloatingButton;
