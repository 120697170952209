import styles from "./Contact.module.css";
import useStyling from "src/hooks/global/useStyling";

const Contact = () => {
  const styling = useStyling(styles);
  return (
    <>
      <h1>Coming Soon.</h1>
    </>
  );
};

export default Contact;
