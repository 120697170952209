const generalMessages = {
  // Forms
  form_validation_error: {
    code: 2001,
    message: "Form validation error",
    prettyError: "Please check that all mandatory fields are filled out correctly.",
  },
  required_field: {
    message: (field) => `${field} is required`,
    prettyError: (field) => `Don't forget to enter your ${field}.`,
  },
  invalid_field: {
    code: 2002,
    message: (field) => `Invalid value for ${field}`,
    prettyError: (field) => `Hmm, that ${field} doesn't look right. Let's try again.`,
  },

  // Network
  catch_all_error: {
    code: 3001,
    message: "An unexpected error occurred",
    prettyError: "Something went wrong. We'll have it sorted in no time. Please try again later!",
  },

  //Not Supported
  not_supported: {
    code: 3002,
    message: "Not supported",
    prettyError: "This feature isn't supported yet. Please check back later.",
  },

  // Entity Validation
  invalid_object_id: {
    code: 2003,
    message: (field) => `Invalid object ID for ${field}`,
    prettyError: (field) => `The ${field} ID doesn't seem valid. Let's double-check it.`,
  },
  not_in_enum_list: {
    code: 2004,
    message: (field) => `${field} is not in the allowed list`,
    prettyError: (field) => `The value for ${field} isn't valid. Please select a valid option.`,
  },
  must_be_positive: {
    code: 2005,
    message: (field) => `${field} must be a positive number`,
    prettyError: (field) => `The value for ${field} needs to be positive. Let's correct that.`,
  },
};

const authenticationMessages = {
  // Login
  no_email_or_password_provided: {
    code: 4001,
    message: "Email or password not provided",
    prettyError: "Oops! Please enter both your email and password.",
  },
  no_associated_account_with_email: {
    code: 4002,
    message: "No account associated with this email",
    prettyError: "We couldn't find an account with that email. Want to sign up instead?",
  },
  incorrect_password: {
    code: 4003,
    message: "Incorrect password",
    prettyError: "That password didn't work. Let's try again!",
  },
  incomplete_business_setup: {
    code: 4004,
    message: "Incomplete business setup",
    prettyError: "You need to complete your business setup on a desktop before you can log in here.",
  },
  email_with_setup_link_sent: {
    code: 4005,
    message: "Email with setup link sent",
    prettyError: "We've sent you an email with a link to complete your business setup. Please check your inbox.",
  },
  cant_deactivate_account_with_shifts: {
    code: 4006,
    message: "Cannot deactivate account with shifts",
    prettyError:
      "You can't deactivate your account while you have upcoming shifts. Please contact support for assistance.",
  },

  // Signup
  signup_with_existing_email: {
    code: 1001,
    message: "Email already exists",
    prettyError: "You're already with us! Please login or reset your password if needed.",
  },
  stronger_password_required: {
    code: 1002,
    message: "Password strength is insufficient",
    prettyError: "Let's make your password a bit stronger for better security.",
  },
  passwords_do_not_match: {
    code: 1003,
    message: "Passwords do not match",
    prettyError: "The passwords don't match. Double-check and try again.",
  },

  // Reset Password
  failed_to_send_reset_email: {
    code: 1004,
    message: "Failed to send reset email",
    prettyError: "We couldn't send the reset email. Let's give it another shot.",
  },
  reset_password_token_expired: {
    code: 1005,
    message: "Reset password token expired",
    prettyError: "The reset link has expired. No worries, just request a new one!",
  },
};

const businessSetupMessages = {
  // ABN
  abn_invalid: {
    code: 5001,
    message: "Invalid ABN",
    prettyError: "Hmm, the ABN you entered doesn't seem right. Could you check it and try again?",
  },
  abn_not_active: {
    code: 5002,
    message: "Inactive ABN",
    prettyError: "It looks like this ABN isn't active. Please use an active one.",
  },
  abn_in_use: {
    code: 5003,
    message: "ABN already in use",
    prettyError: "This ABN is already linked to another account. Maybe try a different one?",
  },

  // Setup
  business_details_incomplete: {
    code: 5004,
    message: "Incomplete business details",
    prettyError: "You're almost there! Just finish filling out your business details to continue.",
  },
  selected_indsutry_incomplete: {
    code: 5005,
    message: "Industry selection incomplete",
    prettyError: "Let's choose your industry to move forward.",
  },
  address_incomplete: {
    code: 5006,
    message: "Address incomplete",
    prettyError: "Don't forget to complete your address details.",
  },
  location_incomplete: {
    code: 5007,
    message: "Location incomplete",
    prettyError: "Please ensure your business location details are complete.",
  },
};

const locationMessages = {
  // Location
  location_not_found: {
    code: 6001,
    message: "Location not found",
    prettyError: "We couldn't find that location. Please check and try again.",
  },
  location_already_exists: {
    code: 6002,
    message: "Location already exists",
    prettyError: "This location is already in the system. Please choose a different name or location.",
  },
};

const paymentMessages = {
  // General
  cannot_delete_payment: {
    code: 7001,
    message: "Cannot delete payment",
    prettyError: "Sorry, this payment can't be deleted right now. Please try again later.",
  },

  // Shift
  shift_must_be_confirmed_or_published: {
    code: 7002,
    message: "Shift must be confirmed or published",
    prettyError: "The shift needs to be confirmed or published before you can proceed.",
  },

  // Payment
  payment_failed: {
    code: 7003,
    message: "Payment failed",
    prettyError: "Something went wrong with the payment. Let's try that again.",
  },
  payment_not_found: {
    code: 7004,
    message: "Payment not found",
    prettyError: "We couldn't find that payment. Please check and try again.",
  },
  failed_too_process_refund: {
    code: 7005,
    message: "Failed to process refund",
    prettyError: "The refund couldn't be processed. Please contact support if the issue persists.",
  },

  // Payment Method
  payment_method_not_found: {
    code: 7006,
    message: "Payment method not found",
    prettyError: "We couldn't find that payment method. Please check and try again.",
  },
  payment_method_already_exists: {
    code: 7007,
    message: "Payment method already exists",
    prettyError: "This payment method is already in use. Please select another one.",
  },
  failed_to_set_as_default: {
    code: 7008,
    message: "Failed to set as default",
    prettyError: "Couldn't set this payment method as default. Please try again.",
  },
  cannot_delete_default_payment_method: {
    code: 7009,
    message: "Cannot delete default payment method",
    prettyError: "You can't delete the default payment method. Please set another method as default first.",
  },
};

const positionMessages = {
  position_already_exists: {
    code: 8001,
    message: "Position already exists",
    prettyError: "This position already exists. Please choose a different name or title.",
  },
  position_not_found: {
    code: 8002,
    message: "Position not found",
    prettyError: "We couldn't find that position. Please check and try again.",
  },
  no_position_group_found: {
    code: 8003,
    message: "No position group found",
    prettyError: "No group associated with this position was found. Please select a valid group.",
  },
};

const shiftMessages = {
  // Changing Shifts
  cannot_edit_shift: {
    code: 9001,
    message: "Cannot edit shift",
    prettyError: "Sorry, you can't edit this shift right now. Please try again later.",
  },
  shift_has_not_been_confirmed: {
    code: 9002,
    message: "Shift has not been confirmed",
    prettyError: "This shift hasn't been confirmed yet. Please confirm it before making changes.",
  },
};

module.exports = {
  authenticationMessages,
  generalMessages,
  businessSetupMessages,
  locationMessages,
  paymentMessages,
  positionMessages,
  shiftMessages,
};
