import clsx from "clsx";
import styles from "./SchedulerViewShift.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { faArrowUpRight, faCalendar, faClock, faCoffee, faEyeSlash, faLock } from "@fortawesome/pro-regular-svg-icons";
import logo from "src/assets/logos/icon.svg";
import Link from "src/components/func/Link";
import useShiftTotals from "src/hooks/business/useShiftTotals";
import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "src/components/UI/buttons/Button";
import moment from "moment-timezone";
import useUI from "src/hooks/global/useUI";
import { roundNumber } from "src/utility/format";
import CancelShift from "../../components/CancelShift";

const editableStatuses = ["unpublished", "published", "hasApplicants"];

const SchedulerViewShift = ({ view, setView, shift, status, profile, setShowProfile }) => {
  const [cancelShift, setCancelShift] = useState(false);

  let statusContent = status;
  let iconContent = <img src={logo} alt="Shiftly logo" style={{ objectFit: "contain" }} />;
  const { setNewShiftModalDefaults, showNewShiftModal } = useUI();

  const { shiftDispatch, actionTypes, totalRange, duration } = useShiftTotals();

  const setShiftFields = useCallback(
    (fields) => {
      Object.entries(fields).forEach(([field, value]) => {
        shiftDispatch({ type: actionTypes.SET_FIELD, field, value });
      });
    },
    [actionTypes, shiftDispatch]
  );

  const confirmedAmount = useMemo(() => {
    if (shift.status !== "confirmed" && shift.status !== "completed") return null;
    const application = shift.applicants.find((application) => application.status === "accepted");
    return application?.total;
  }, [shift]);

  const handleEditShiftClick = useCallback(() => {
    setView(false);
    setNewShiftModalDefaults({
      mode: "edit",
      shift: JSON.stringify(shift),
      type: shift?.type ?? "shiftly",
    });
    showNewShiftModal(true);
  }, [shift, setNewShiftModalDefaults, showNewShiftModal, setView]);

  useEffect(() => {
    if (shift?.status) {
      const startTime = moment.tz(shift?.start_time, shift?.timezone);
      const endTime = moment.tz(shift?.end_time, shift?.timezone);

      setShiftFields({
        position: shift?.position,
        shiftDate: startTime.toDate(),
        startTime: startTime.format("HH:mm"),
        endTime: endTime.format("HH:mm"),
        increasedHourlyRateModifier: shift?.shift_rate_modifier,
      });
    }
  }, [shift, setShiftFields]);

  switch (status) {
    case "unpublished":
      statusContent = <p className={styles["heading-status"]}>Unpublished</p>;
      iconContent = <FontAwesomeIcon icon={faEyeSlash} />;
      break;
    case "published":
      statusContent = <p className={styles["heading-status"]}>Published</p>;
      break;
    case "confirmed":
      statusContent = (
        <Link
          onClick={() => {
            setView(false);
            setShowProfile(true);
          }}
          className={styles["heading-status"]}
        >
          {"Shifter: " + shift?.user?.first_name + " " + shift?.user?.last_name}
        </Link>
      );
      iconContent = <img src={profile?.profile_picture} alt="Profile" style={{ objectFit: "cover" }} />;
      break;
    case "expired":
      statusContent = <p className={styles["heading-status"]}>Expired</p>;
      break;
    case "hasApplicants":
      statusContent = (
        <Link to={"/people?shift=" + shift?._id} className={styles["heading-status"]}>
          {shift?.applicants?.length} applicant{shift?.applicants?.length > 1 && "s"}
          <FontAwesomeIcon icon={faArrowUpRight} className={styles["linkArrow"]} />
        </Link>
      );
      break;
    default:
      break;
  }

  return (
    <>
      <CancelShift showModal={cancelShift} setShowModal={setCancelShift} shift={shift} />
      <div className={clsx(styles["overlay"], view && styles["visible"])} onClick={() => setView(false)}></div>
      <div className={clsx(styles["container"], view && styles["visible"])}>
        <div className={clsx(styles["heading"], styles[status])}>
          <div className={clsx(styles["heading-image"], styles[status])}>{iconContent}</div>
          <div className={styles["heading-details"]}>
            <h6 className={styles["heading-position"]}>
              {shift?.position?.name} - Level {shift?.position?.classification_level}
            </h6>
            {statusContent}
          </div>
          <div className={styles["close-icon"]} onClick={() => setView(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
        <div className={styles["body"]}>
          <div className={styles["body-row"]}>
            <div className={styles["body-icon"]}>
              <FontAwesomeIcon icon={faCalendar} />
            </div>
            <div className={styles["body-text"]}>
              {shift?.day_of_week} {shift?.prettyDate}
            </div>
          </div>
          <div className={styles["body-row"]}>
            <div className={styles["body-icon"]}>
              <FontAwesomeIcon icon={faClock} />
            </div>
            <div className={styles["body-text"]}>
              {shift?.prettyStartTime} - {shift?.prettyEndTime} ({duration} hrs)
            </div>
          </div>
          {shift?.breakTime && (
            <div className={styles["body-row"]}>
              <div className={styles["body-icon"]}>
                <FontAwesomeIcon icon={faCoffee} />
              </div>
              <div className={styles["body-text"]}> Breaks: {shift?.breakTime}</div>
            </div>
          )}
          {shift?.pin && (
            <div className={styles["body-row"]}>
              <div className={styles["body-icon"]}>
                <FontAwesomeIcon icon={faLock} />
              </div>
              <div className={styles["body-text"]}>
                Sign on pin: <strong>{shift?.pin}</strong>
              </div>
            </div>
          )}
        </div>
        {status === "hasApplicants" && (
          <p className={styles["editing-published"]}>
            *Please note: Editing a published shift will remove all applicants
          </p>
        )}
        <div className={styles["footer"]}>
          {!shift.type && (
            <div className={styles["footer-details"]}>
              <p className={styles["footer-subtext"]}>Total</p>
              {
                <p className={styles["footer-total"]}>
                  {confirmedAmount
                    ? `$${roundNumber(confirmedAmount)}`
                    : `$${roundNumber(totalRange.min)} - $${roundNumber(totalRange.max)}`}
                </p>
              }
            </div>
          )}
          <div className={styles["footer-actions"]}>
            {editableStatuses.includes(status) && (
              <Button onClick={handleEditShiftClick} theme={"secondary-outline"} className={styles["edit-button"]}>
                Edit Shift
              </Button>
            )}
            {status === "confirmed" && (
              <div className={styles["cancel-shift-button"]}>
                <Button
                  onClick={() => {
                    setView(false);
                    setCancelShift(true);
                  }}
                  theme={"secondary-outline"}
                  className={styles["edit-button"]}
                >
                  Cancel Shift
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SchedulerViewShift;
