import styles from "./HQNewBusinessSetup.module.css";
import clsx from "clsx";
import Input from "src/components/UI/inputs/Input";
import moment from "moment-timezone";
import Dropdown from "src/components/UI/inputs/Dropdown";
import Address from "src/components/UI/inputs/Address";
import { useEffect } from "react";
import FormFlex from "src/components/UI/layout/FormFlex";

const NewBusinessLocations = ({ data, setData, multipleLocations = {} }) => {
  useEffect(() => {
    if (!data?.timezone) {
      setData((prev) => ({
        ...prev,
        timezone: moment.tz.guess(),
      }));
    }
  }, [data, setData]);

  return (
    <div className={styles["container"]}>
      <div className={styles["heading"]}>
        <h1>
          Add your {multipleLocations ? "first" : ""} <span>location</span> below{" "}
        </h1>
      </div>
      <div className={clsx(styles["content"], styles["medium-content"])}>
        <FormFlex>
          {multipleLocations && (
            <Input
              label={"Name this location:"}
              required
              placeholder={"Cafe on the Corner - High Street"}
              name={"locationName"}
            />
          )}
          <Dropdown name={"timezone"} />
        </FormFlex>
        <Address name={"address"} />
      </div>
    </div>
  );
};

export default NewBusinessLocations;
