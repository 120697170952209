import Toggle from "src/components/UI/tabs/Toggle";
import styles from "./Saved.module.css";
import { useState } from "react";
import useFetch from "src/hooks/global/useFetch";
import useAuth from "src/hooks/global/useAuth";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Each from "src/components/func/Each";
import ShiftCard from "../Search/components/ShiftCard";
import ShiftDetails from "../Search/components/ShiftDetails/ShiftDetails";
import clsx from "clsx";
import { faFilter, faHeart } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterCard from "./FilterCard";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import ShiftSkeleton from "../Search/components/ShiftSkeleton";
import { motion } from "framer-motion";

const Saved = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { user } = useAuth();
  const [showDetails, setShowDetails] = useState(false);

  const [shiftParent] = useAutoAnimate();
  const [filterParent] = useAutoAnimate();

  const { data: savedShiftIDs } = useFetch({
    request: {
      entity: "ActionedShift",
      criteria: {
        action: "saved",
        user: user?._id,
      },
      id: "ActionedShift.SavedShiftIDs",
    },
    dependency: user?._id,
    options: {
      select: (data = []) => {
        return data.map((item) => item.shift);
      },
    },
  });

  const { data: savedShifts, isLoading: isShiftsLoading } = useFetch({
    request: {
      entity: "Shift",
      method: "getWithCalculatedFields",
      criteria: {
        _id: { $in: savedShiftIDs },
      },
      data: {
        user,
      },
      id: "Shift.GetSavedShiftsFromShiftIDs",
    },
    dependency: savedShiftIDs,
  });


  const { data: savedFilters } = useFetch({
    request: {
      entity: "Filter",
      method: "get",
      criteria: {
        user: user?._id,
      },
      id: "Filter.SavedFiltersForUser",
    },
    options: {
      staleTime: 0,
    },
    dependency: user?._id,
  });

  return (
    <>
      <ShiftDetails showDetails={showDetails} setShowDetails={setShowDetails} />
      <div className={styles["container"]}>
        <div className={styles["tabs-container"]}>
          <Toggle
            tab={activeTab}
            setTab={setActiveTab}
            tabs={[
              { text: "Shifts", icon: <FontAwesomeIcon icon={faHeart} /> },
              { text: "Filters", icon: <FontAwesomeIcon icon={faFilter} /> },
            ]}
          />
        </div>
        <div className={styles["elements-container"]}>
          <div className={clsx(styles["shifts-container"], activeTab === 0 && styles["visible"])} ref={shiftParent}>
            {isShiftsLoading ? (
              <motion.div exit={{ opacity: 0 }} className={styles["loading-skeleton"]}>
                <div className={styles["spinner"]}>
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>
                <ShiftSkeleton />
              </motion.div>
            ) : savedShifts?.length ? (
              <Each
                of={savedShifts}
                render={({ key, ...shift }, index) => (
                  <div className={styles["shift-container"]} key={index}>
                    <ShiftCard
                      key={key}
                      shift={shift}
                      setShowDetails={setShowDetails}
                      mode={"saved"}
                      shiftsLength={savedShiftIDs?.length}
                    />
                  </div>
                )}
              />
            ) : (
              <div className={styles["empty-container"]}>
                <p>
                  No Saved Shifts.
                  <br />
                  Better get swiping!
                </p>
              </div>
            )}
          </div>
          <div className={clsx(styles["filters-container"], activeTab === 1 && styles["visible"])} ref={filterParent}>
            {savedFilters?.length ? (
              <Each
                of={savedFilters}
                render={({ key, ...item }, index) => <FilterCard {...item} key={key} index={index} />}
              />
            ) : (
              <div className={styles["empty-container"]}>
                <p>
                  No Saved Filters.
                  <br />
                  Try the search bar to get started!
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Saved;
