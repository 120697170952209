import { useEffect } from "react";
import styles from "./Main.module.css";
import useAuth from "src/hooks/global/useAuth";
import { useNavigate } from "react-router-dom";
import useUI from "src/hooks/global/useUI";
import NewShiftModal from "src/pages/Business/Location/Shifts/components/NewShiftModal";
import ShifterNavigation from "./ShifterNavigation";
import BusinessNavigation from "./BusinessNavigation/BusinessNavigation";

const Main = ({ children }) => {
  const {
    auth: { isAuthenticated },
  } = useAuth();

  const { loading, mode } = useUI();

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!isAuthenticated) {
      navigate("/portal");
    }
  }, [isAuthenticated, navigate, loading]);

  return (
    <div className={styles["container"]}>
      {mode === "shifter" ? <ShifterNavigation /> : <BusinessNavigation />}
      <NewShiftModal />
      <div className={styles["content-wrapper"]}>{children}</div>
    </div>
  );
};
export default Main;
