import styles from "./Modal.module.css";
import Overlay from "./Overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useStyling from "src/hooks/global/useStyling";
import clsx from "clsx";

const Modal = ({ label, children, showModal, setShowModal, className }) => {
  const styling = useStyling(styles);

  return (
    <>
      <Overlay open={showModal} onClick={() => setShowModal(false)} />
      <div className={clsx(styling("modal", showModal && "visible"), className)}>
        <div className={styling("modal-header")}>
          {typeof label === "string" ? <p className={styling("label")}>{label}</p> : label}
          <FontAwesomeIcon icon={faTimes} onClick={() => setShowModal(false)}  className={styling('close-icon')}/>
        </div>

        <div className={styling("modal-divider")}></div>
        <div className={styling("modal-content")}>{children}</div>
      </div>
    </>
  );
};

export default Modal;
