import clsx from "clsx";
import styles from "./RatingDisplay.module.css";
import useStyling from "src/hooks/global/useStyling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-solid-svg-icons";

const RatingDisplay = ({ rating = 5, className }) => {
  const styling = useStyling(styles);
  return (
    <p className={clsx(styling("container"), className)}>
      {rating}{" "}
      <span>
        <FontAwesomeIcon icon={faStar} />
      </span>
    </p>
  );
};

export default RatingDisplay;
