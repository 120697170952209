import clsx from "clsx";
import styles from "./HQNewBusinessRadioButtonCard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewBusinessRadioButtonCard = ({ icon, label, award, active, onClick = () => {} }) => {
  return (
    <div className={clsx(styles["container"], active && styles["active"])} onClick={onClick}>
      <FontAwesomeIcon icon={icon} />
      <h6>{label}</h6>
      <p>{award}</p>
    </div>
  );
};

export default NewBusinessRadioButtonCard;
