import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import uiSlice from "./uiSlice";
import filtersSlice from "../shifter/filterSlice";
import shiftSlice from "../shifter/shiftSlice";
import businessSlice from "../business/businessSlice";
import { thunk } from "redux-thunk";

import awardSlice from "../utility/awardSlice";

const asyncFunctionMiddleware = (storeAPI) => (next) => (action) => {
  // If the "action" is actually a function instead...
  if (typeof action === "function") {
    // then call the function and pass `dispatch` and `getState` as arguments
    return action(storeAPI.dispatch, storeAPI.getState);
  }

  // Otherwise, it's a normal action - send it onwards
  return next(action);
};

const store = configureStore({
  reducer: {
    auth: authSlice,
    ui: uiSlice,
    filters: filtersSlice,
    shifts: shiftSlice,
    business: businessSlice,
    awards: awardSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(asyncFunctionMiddleware, thunk),
});

export default store;
