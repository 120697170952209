import styles from "../ProfileHome.module.css";
import FileUploader from "src/components/UI/inputs/FileUploader";
import { useRef } from "react";
import Skeleton from "react-loading-skeleton";
import noImage from "src/assets/png/no_img_placeholder.jpeg";
import useProfile from "src/hooks/shifter/useProfile";

const ProfileHomePicture = ({ picture, isLoading: profileLoading }) => {
  const fileUploaderRef = useRef();
  const { updateProfilePic } = useProfile();
  return (
    <>
      <FileUploader visible={false} ref={fileUploaderRef} onUploadComplete={updateProfilePic} />

      <div className={styles["progress-spinner-container"]}>
        <div className={styles["progress-spinner-initial"]}>
          {profileLoading ? (
            <Skeleton circle height={"150px"} width={"150px"} className={styles["progress-img-skeleton"]} />
          ) : (
            <img
              src={picture === "/defaults/shifter.png" ? noImage : picture}
              alt="user profile"
              onClick={() => fileUploaderRef.current?.click()}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileHomePicture;
