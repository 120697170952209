import { useCallback, useMemo, useEffect } from "react";
import socket from "src/utility/socket";
import useFetch from "src/hooks/global/useFetch";
import useUI from "src/hooks/global/useUI";
import useAuth from "src/hooks/global/useAuth";
import useUserActivity from "./useUserActivity";
import useShiftlyLocation from "../business/useShiftlyLocation";

const useSocket = (initialise = false) => {
  const { refresh, updateCache } = useFetch();
  const { businessMode } = useUI();
  const { activeLocation } = useShiftlyLocation();
  const { user } = useAuth();
  const isUserActive = useUserActivity();

  const sendEvent = useCallback((event, data) => {
    socket.emit(event, data);
  }, []);

  const onEvent = useCallback((event, handler) => {
    socket.on(event, handler);
  }, []);

  const offEvent = useCallback((event, handler) => {
    socket.off(event, handler);
  }, []);

  // Register and Deregister based on user activity
  useEffect(() => {
    if (!initialise) return;

    const id = businessMode ? activeLocation?._id : user?._id;
    if (!id) return;

    sendEvent("listen_notifications", { id });
    sendEvent("listen_invalidator", { id });
    sendEvent("listen_messages", { id });
    
  }, [activeLocation, sendEvent, user, businessMode, initialise, isUserActive]);

  const handleInvalidation = useCallback(
    (data) => {
      const { id } = data;
      refresh(id);
    },
    [refresh]
  );

  const handleNewNotification = useCallback(
    (notification) => {
      updateCache("Notifications.GetAllNotifications", (oldData = []) => {
        const newData = [...oldData];
        const index = newData.findIndex((n) => n._id === notification._id);
        if (index >= 0) {
          newData[index] = notification;
        } else {
          newData.push(notification);
        }
        return newData;
      });
    },
    [updateCache]
  );

  const handleReceiveMessage = useCallback(
    (message) => {
      updateCache("Message.GetMessagesWithAccess", (oldData = []) => {
        const newData = [...oldData];
        const index = newData.findIndex((m) => m._id === message._id);
        if (index >= 0) {
          newData[index] = message;
        } else {
          newData.push(message);
        }
        return newData;
      });
    },
    [updateCache]
  );

  // Listen
  useEffect(() => {
    onEvent("new_notification", handleNewNotification);
    onEvent("invalidate", handleInvalidation);
    onEvent("receive_message", handleReceiveMessage);

    return () => {
      offEvent("new_notification", handleNewNotification);
      offEvent("receive_message", handleReceiveMessage);
    };
  }, [
    onEvent,
    refresh,
    activeLocation,
    offEvent,
    updateCache,
    isUserActive,
    handleInvalidation,
    handleNewNotification,
    handleReceiveMessage,
  ]);

  return useMemo(() => ({ sendEvent, onEvent, offEvent }), [sendEvent, onEvent, offEvent]);
};

export default useSocket;
