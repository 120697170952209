import styles from "./BusinessWrapper.module.css";
import { useEffect } from "react";
import useBusiness from "src/hooks/business/useBusiness";
import MobileLocationSelector from "../misc/MobileLocationSelector";
import withDisplay from "../HOC/withDisplay";
import { useGestures } from "src/contexts/Gestures";
import TaskList from "../misc/TaskList/TaskList";
import { useLocation } from "react-router-dom";
import useAI from "src/hooks/global/useAI";
import { instructions, welcomeMessage } from "src/utility/ai";
import { CopilotPopup } from "@copilotkit/react-ui";
import FloatingButton from "../UI/ai/FloatingButton";
import Header from "../UI/ai/Header";

const BusinessWrapper = ({ children, isMobile }) => {
  const { initBusiness } = useBusiness();
  const { pathname } = useLocation();
  const { bind } = useGestures();

  useAI();

  useEffect(() => {
    initBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    pathname !== "/portal" && (
      <div className={styles["container"]} {...bind()}>
        <CopilotPopup
          labels={{
            title: "Shiftly Assistant",
            initial: welcomeMessage,
          }}
          Button={FloatingButton}
          Header={Header}
          showResponseButton={false}
          instructions={instructions}
        />
        {pathname !== "/" && isMobile && <MobileLocationSelector />}
        {!isMobile && <TaskList />}
        <div className={styles["content"]}>{children}</div>
      </div>
    )
  );
};

export default withDisplay(BusinessWrapper);
