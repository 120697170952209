import { createContext, useState, useContext, useLayoutEffect, useCallback } from "react";
import useFetch from "src/hooks/global/useFetch";
import useShiftlyLocation from "src/hooks/business/useShiftlyLocation";
import { newGUID } from "src/utility/generators";
import useBusiness from "src/hooks/business/useBusiness";
import useUI from "src/hooks/global/useUI";

export const SchedulerContext = createContext({
  shifts: [],
  applications: [],
  thirdPartyShifts: [],
  internalShifts: [],
  handleSlotClick: () => {},
  handleNextPeriod: () => {},
  handlePrevPeriod: () => {},
  onComplete: () => {},
  periods: {
    prev: [],
    current: [],
    next: [],
  },
  direction: 0,
  setDirection: () => {},
  duration: 0.5,
  fromDate: new Date(),
  isLoading: false,
  setIsLoading: () => {},
});

export const useScheduler = () => useContext(SchedulerContext);

const getDatesInWeek = (date, offset = 0) => {
  const days = [];
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1) + 7 * offset;

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(date);
    currentDate.setDate(diff + i);

    if (i === 0) {
      currentDate.setHours(0, 0, 0, 0);
    } else if (i === 6) {
      currentDate.setHours(23, 59, 59, 999);
    }

    days.push(new Date(currentDate));
  }

  return days;
};

export const SchedulerContextProvider = ({ children }) => {
  const [fromDate, setFromDate] = useState(new Date());
  const [direction, setDirection] = useState(0);
  const [duration, setDuration] = useState(0.5);
  const [isLoading, setIsLoading] = useState(false);
  const [periods, setPeriods] = useState({
    prev: [],
    current: [],
    next: [],
  });
  const { activeLocation } = useShiftlyLocation();
  const { activeBusiness } = useBusiness();
  const { setNewShiftModalDefaults, showNewShiftModal, setCurrentPeriod } = useUI();

  // Update periods when fromDate changes
  useLayoutEffect(() => {
    const currentPeriod = getDatesInWeek(fromDate, 0);
    setPeriods({
      prev: getDatesInWeek(fromDate, -1),
      current: currentPeriod,
      next: getDatesInWeek(fromDate, 1),
    });

    setCurrentPeriod(JSON.stringify(currentPeriod));
  }, [fromDate, setCurrentPeriod]);

  const { data: shifts, isLoading: shiftIsLoading } = useFetch({
    request: {
      entity: "Shift",
      method: "getAndPopulate",
      populate: ["user", "position"],
      criteria: {
        location: activeLocation?._id,
        start_time: {
          $gte: periods.current[0],
          $lte: periods.current[periods.current.length - 1],
        },
      },
      id: "Shift.ShiftsBetweenDates",
    },
    dependency: `${periods.current[0]}_${activeLocation?._id}`,
    options: {
      enabled: !!periods.current[0],
    },
  });

  const handleNextPeriod = useCallback(() => {
    console.log("next period");
    setFromDate((prevDate) => getDatesInWeek(new Date(prevDate), 1)[0]);
  }, []);

  const handlePrevPeriod = useCallback(() => {
    setFromDate((prevDate) => getDatesInWeek(new Date(prevDate), -1)[0]);
  }, []);

  const { data: applications } = useFetch({
    request: {
      entity: "ShiftApplication",
      criteria: {
        location: activeLocation?._id,
        shift: {
          $in: shifts?.map((shift) => shift._id),
        },
        status: {
          $ne: "unverified",
        },
      },
      id: "ShiftApplication.GetApplicationsForShifts",
    },
    dependency: shifts ? shifts.map((s) => s._id).join("_") : "",
  });

  const { data: thirdPartyShifts, isLoading: thirdPartyIsLoading } = useFetch({
    request: {
      entity: "Integration",
      method: "getShifts",
      criteria: {
        business: activeBusiness?._id,
        location: activeLocation?._id,
        start_date: periods.current[0],
        end_date: periods.current[periods.current.length - 1],
      },
      id: "Integration.ShiftsBetweenDates",
    },
    dependency: `${periods.current[0]}_${activeLocation?._id}_${activeBusiness?._id}`,
    options: {
      enabled: !!periods.current[0],
    },
  });

  const { data: internalShifts, isLoading: internalIsLoading } = useFetch({
    request: {
      entity: "InternalShift",
      method: "getAndPopulate",
      populate: ["user", "location"],
      criteria: {
        location: activeLocation?._id,
        start_time: {
          $gte: periods.current[0],
          $lte: periods.current[periods.current.length - 1],
        },
      },
      id: "InternalShift.ShiftsBetweenDates",
    },
    dependency: `${periods.current[0]}_${activeLocation?._id}`,
    options: {
      enabled: !!periods.current[0],
      select: useCallback((data) => {
        return data.map((shift) => ({
          ...shift,
          position: { _id: newGUID(), type: "internal", name: "Internal" },
          type: "internal",
        }));
      }, []),
    },
  });

  const handleSlotClick = useCallback(
    (dayIndex, position) => {
      setNewShiftModalDefaults({
        position: JSON.stringify(position),
        shiftDate: periods.current[dayIndex]?.toString(),
        type: position?.type === "internal" ? "internal" : "shiftly",
      });
      showNewShiftModal(true);
    },
    [setNewShiftModalDefaults, showNewShiftModal, periods]
  );

  return (
    <SchedulerContext.Provider
      value={{
        shifts,
        applications,
        thirdPartyShifts,
        internalShifts,
        handleSlotClick,
        handleNextPeriod,
        handlePrevPeriod,
        periods,
        direction,
        setDirection,
        setDuration,
        duration,
        fromDate,
        isLoading: shiftIsLoading || thirdPartyIsLoading || internalIsLoading || isLoading,
        setIsLoading,
      }}
    >
      {children}
    </SchedulerContext.Provider>
  );
};
