import useAuth from "src/hooks/global/useAuth";
import styles from "../ProfileHome.module.css";
import ProfileHomePicture from "./ProfileHomePicture";
import Button from "src/components/UI/buttons/Button";
import { calculateAge } from "src/utility/calculator";
import { useMemo } from "react";
import { faBell, faCalendarWeek, faDollar, faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import useStyling from "src/hooks/global/useStyling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import useProfile from "src/hooks/shifter/useProfile";
import useOnboardingComplete from "src/hooks/shifter/useOnboardingComplete";

const ProfileHomeCardMain = () => {
  const { user } = useAuth();
  const styling = useStyling(styles);

  const { profile, isLoading } = useProfile();
  const { todo } = useOnboardingComplete();
  const isComplete = useMemo(() => todo?.total, [todo]);

  return (
    <div className={styling("progress-container")}>
      <div className={styling("progress-card")}>
        <ProfileHomePicture picture={profile?.profile_picture} bio={profile?.bio} isLoading={isLoading} />
        <div className={styling("progress-details")}>
          <h4>
            {user?.first_name} {user?.last_name}, {calculateAge(user?.date_of_birth)}
          </h4>
        </div>
        <div className={styling("progress-button")}>
          <Button link={"/digital-cv"} theme={"secondary"} icon={!isComplete && faExclamationCircle} iconSide="left">
            {isComplete ? "Manage digital CV" : "Complete digital CV"}
          </Button>
        </div>
        <div className={styling("nav-links")}>
          <NavLink text={"Notifications"} icon={faBell} path={"/inbox?tab=notifications"} disabled={false} />
          <NavLink text={"Upcoming Shifts"} icon={faCalendarWeek} path={"/shifts"} disabled={!isComplete} />
          <NavLink text={"Payments"} icon={faDollar} path={"/payments"} disabled={!isComplete} />
        </div>
      </div>
    </div>
  );
};

const NavLink = ({ icon, text, path, disabled }) => {
  const navigate = useNavigate();
  const styling = useStyling(styles);
  return (
    <div className={styling("nav-container", disabled && "nav-disabled")} onClick={() => !disabled && navigate(path)}>
      <div className={styling("nav-icon")}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <p>{text}</p>
      <FontAwesomeIcon icon={faChevronRight} className={styling("nav-chevron")} />
    </div>
  );
};

export default ProfileHomeCardMain;
