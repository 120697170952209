import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useSearchParams = (keys = [], callBack = () => {}, dependencies = []) => {
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    keys.forEach((key) => {
      params[key] = searchParams.get(key);
    });
    callBack(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, ...dependencies]);
};

export default useSearchParams;
