import Input from "src/components/UI/inputs/Input";
import styles from "./NewPosition.module.css";
import useStyling from "src/hooks/global/useStyling";

const NewPositionWage = () => {
  const styling = useStyling(styles);

  return (
    <div className={styling("container")}>
      <div className={styling("heading")}>
        <h1>
          Would you like to increase the <span>pay</span> for your employee?
        </h1>
      </div>
      <div className={styling("content", "short-content")}>
        <Input name={"position_rate_modifier"} />
        <p className={styling("additive")}>
          *Offering competitive rates will attract <span>a broader range of applicants</span>, including those of
          candidates with <span>higher ratings.</span>
        </p>
      </div>
    </div>
  );
};

export default NewPositionWage;
