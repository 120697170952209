import { useEffect, useState } from "react";
import styles from "../ProfileHome.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faInfo } from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import useOnboardingComplete from "src/hooks/shifter/useOnboardingComplete";

const ProfileFinishSetup = () => {
  const [itemsToComplete, setItemsToComplete] = useState([]);

  const { todo, isLoading } = useOnboardingComplete();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return;
    const arrToComplete = [];
    if (!todo["personalDetails"])
      arrToComplete.push({ text: "Add personal details", activeKey: "personalDetails", priority: 1 });
    if (!todo["bankDetails"]) arrToComplete.push({ text: "Add bank details", activeKey: "bankDetails", priority: 1 });
    if (!todo["taxDetails"]) arrToComplete.push({ text: "Add tax details", activeKey: "taxDetails", priority: 1 });
    if (!todo["superDetails"])
      arrToComplete.push({ text: "Add super details", activeKey: "superDetails", priority: 1 });
    if (!todo["contactDetails"])
      arrToComplete.push({ text: "Add contact details", activeKey: "contactDetails", priority: 2 });
    if (!todo["bio"])
      arrToComplete.push({ text: "Add a bio", activeKey: "bio", priority: 2, destination: "/digital-cv" });
    if (!todo["workExperience"])
      arrToComplete.push({
        text: "Add experience",
        activeKey: "work_experience",
        priority: 2,
        destination: "/digital-cv",
      });
    if (!todo["qualifications"])
      arrToComplete.push({
        text: "Add qualifications",
        activeKey: "qualifications",
        priority: 2,
        destination: "/digital-cv",
      });

    setItemsToComplete(arrToComplete.map((item) => <FinishCard key={item.text} {...item} />));
  }, [isLoading, todo]);

  return itemsToComplete.length ? (
    <div className={styles["finish-container"]}>
      <div className={styles["finish-heading"]} onClick={() => navigate("/employment-details")}>
        <h4>
          Finish Profile Setup <span>{itemsToComplete.length}</span>
        </h4>
        <FontAwesomeIcon icon={faArrowRight} />
      </div>
      <div className={styles["finish-content"]}>{itemsToComplete}</div>
      <div className={styles["finish-notice"]}>
        <span>
          <FontAwesomeIcon icon={faInfo} />
        </span>
        <p>Shifters with a complete profile are 70% more likely to get hired.</p>
      </div>
    </div>
  ) : (
    <></>
  );
};

const FinishCard = ({ text, activeKey, priority, onClick, destination }) => {
  const navigate = useNavigate();

  const func = onClick ? onClick : () => navigate(destination ?? "/employment-details?key=" + activeKey);
  return (
    <div className={clsx(styles["finish-card"], priority === 1 && styles["high-priority"])} onClick={func}>
      <div className={styles["finish-card-icon"]}>
        <FontAwesomeIcon icon={faInfo} />
      </div>
      <div className={styles["finish-card-text"]}>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default ProfileFinishSetup;
