import styles from "./Messages.module.css";
import { useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import useFetch from "src/hooks/global/useFetch";
import Conversation from "./subcomponents/Conversation";
import Skeleton from "react-loading-skeleton";
import useMessagesCriteria from "../../hooks/useMessagesCriteria";
import MessageWindow from "./MessageWindow";
import useStyling from "src/hooks/global/useStyling";
import Illustration from "src/assets/svg/Illustrations/NoShifts.svg";
import Button from "src/components/UI/buttons/Button";
const Messages = () => {
  const [parent] = useAutoAnimate();
  const [activeConversation, setActiveConversation] = useState();
  const styling = useStyling(styles);

  const criteria = useMessagesCriteria(activeConversation);

  const { data: conversations, isLoading } = useFetch({
    request: {
      entity: "Conversation",
      method: "getExistingConversations",
      criteria,
      id: "Conversation.GetExistingConversations",
    },
    dependency: criteria,
  });

  return (
    <>
      <MessageWindow
        activeConversation={activeConversation}
        setActiveConversation={setActiveConversation}
        conversations={conversations}
      />
      <div className={styling("container")}>
        <div className={styling("all")}>
          <div className={styling("title")}>
            <h5>Messages</h5>
          </div>
          <div className={styling("messages")} ref={parent}>
            {isLoading ? (
              <div className={styling("loading-messages")}>
                {Array.from({ length: 5 }).map((_, index) => (
                  <MessageSkeleton key={index} />
                ))}
              </div>
            ) : conversations.length ? (
              conversations.map((conversation, index) => (
                <Conversation key={index} {...conversation} setActiveConversation={setActiveConversation} />
              ))
            ) : (
              <div className={styling("no-conversations")}>
                <div className={styling("no-convo-image")}>
                  <img src={Illustration} alt="no messages" />
                </div>
                <p>You have no messages!</p>
                <Button theme={"primary"} link={"/"} >
                  Search for Shifts
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const MessageSkeleton = () => {
  const styling = useStyling(styles);

  return (
    <div className={styling("message-loader")}>
      <Skeleton height={50} width={50} circle={true} />
      <div className={styling("skeleton-lines")}>
        <Skeleton height={20} width={200} />
        <Skeleton height={10} width={150} count={1} />
      </div>
    </div>
  );
};

export default Messages;
