import React, { useCallback, useEffect } from "react";
import Modal from "./Modal";
import styles from "./Stepper.module.css";
import Each from "src/components/func/Each";
import clsx from "clsx";
import Button from "../buttons/Button";
import useToast from "src/hooks/global/useToast";
import useDelayUnmount from "src/hooks/global/useDelayUnmount";
import ModalLabel from "src/components/misc/ModalLabel";
import { useUncontrolled } from "uncontrollable";

const Stepper = (props) => {
  const {
    activeStep = [],
    setActiveStep,
    steps = [],
    showStepper = false,
    setShowStepper = () => {},
    onComplete = () => new Promise((resolve) => resolve()),
    label = <ModalLabel />,
    className,
    skippable = true,
    showNav = false,
    nextButtonText = "Continue",
    prevButtonText = "Previous",
    loading = false,
    keyDown = true,
  } = useUncontrolled(props, {
    activeStep: "setActiveStep",
  });

  const handleStepClick = useCallback((i) => setActiveStep(i), [setActiveStep]);
  const toast = useToast();

  const isValid = useCallback(async () => {
    if (!steps[activeStep].validation) return true;
    return await steps[activeStep].validation();
  }, [activeStep, steps]);

  const handleNext = useCallback(async () => {
    if ((await isValid()) && activeStep < steps?.length - 1) {
      handleStepClick(activeStep + 1);
    } else {
      steps[activeStep]?.message && toast.error(steps[activeStep]?.message);
    }
  }, [activeStep, handleStepClick, steps, isValid, toast]);

  const handlePrev = useCallback(() => {
    if (activeStep > 0) {
      handleStepClick(activeStep - 1);
    }
  }, [activeStep, handleStepClick]);

  useEffect(() => {
    setTimeout(() => setActiveStep(0), 500);
  }, [showStepper, setActiveStep]);

  useEffect(() => {
    if (!keyDown) return;
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleNext();
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleNext, keyDown]);

  return (
    <Modal label={label} className={clsx(className)} showModal={showStepper} setShowModal={setShowStepper}>
      <div className={styles["container"]}>
        {showNav ? (
          <div className={styles["nav-container"]}>
            <div className={styles["steps-nav"]}>
              <div className={styles["progress-bar"]}>
                <div className={styles["bar"]} style={{ width: ((activeStep + 1) / steps?.length) * 100 + "%" }}></div>
              </div>
              <Each
                of={steps}
                render={({ key, ...step }, index) => {
                  return (
                    <StepNav
                      key={key}
                      handleStepClick={handleStepClick}
                      label={step.label}
                      end={index === steps.length - 1}
                      active={index === activeStep}
                      index={index}
                      activeStep={activeStep}
                      skippable={skippable}
                      {...step}
                    />
                  );
                }}
              />
            </div>
          </div>
        ) : (
          <div className={clsx(styles["progress-bar"], styles["hidden-nav"])}>
            <div className={styles["bar"]} style={{ width: ((activeStep + 1) / steps?.length) * 100 + "%" }}></div>
          </div>
        )}

        <div className={styles["steps-content-container"]}>
          {showStepper && (
            <Each
              of={steps}
              render={({ key, content, ...step }, index) => {
                return (
                  <div
                    className={styles["step-content"]}
                    style={{
                      transform: `translateX(${100 * -activeStep}%)`,
                      transition: "transform 0.5s ease-in-out",
                    }}
                    key={step.label}
                  >
                    <StepContent activeStep={activeStep} index={index}>
                      {typeof content === "function" ? content(step) : content}
                    </StepContent>
                  </div>
                );
              }}
            />
          )}
        </div>
        <div className={styles["step-footer"]}>
          <Button theme={"secondary"} disabled={activeStep === 0} onClick={handlePrev}>
            {prevButtonText}
          </Button>
          <Button
            theme={"primary"}
            disabled={loading}
            onClick={async () => {
              if (activeStep === steps?.length - 1) {
                if (isValid()) {
                  try {
                    await onComplete();
                  } catch (error) {
                    toast.error(error.message);
                  }
                }
              } else {
                handleNext();
              }
            }}
          >
            {activeStep !== steps?.length - 1 ? nextButtonText : loading ? "Loading..." : "Complete"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const StepContent = ({ index, activeStep, children }) => {
  const showContent = useDelayUnmount(activeStep === index, 500);
  const enhancedChildren = React.Children.map(children, (child) => {
    return React.cloneElement(child, { showContent });
  });

  return activeStep === index && <div className={styles["step-content"]}>{enhancedChildren}</div>;
};

const StepNav = ({ skippable, label, active, end, handleStepClick = () => {}, index, activeStep }) => {
  return (
    <div
      className={clsx(
        styles["step"],
        active && styles["step-active"],
        end && styles["step-end"],
        index < activeStep && styles["step-past"]
      )}
      onClick={() => skippable && handleStepClick(index)}
    >
      <p>{label}</p>
    </div>
  );
};

export default Stepper;
