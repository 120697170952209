import clsx from "clsx";
import styles from "./LoadingSpinner.module.css";

const LoadingSpinner = ({ colour = "orange", loader }) => {
  const mountedStyle = { animation: "inAnimation 500ms ease-in" };
  const unmountedStyle = {
    animation: "outAnimation 500ms ease-out",
    animationFillMode: "forwards",
  };
  return (
    <span
      style={loader ? mountedStyle : unmountedStyle}
      className={clsx(styles["loader"], colour && styles[colour])}
    ></span>
  );
};
export default LoadingSpinner;
