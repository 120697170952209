import Modal from "src/components/UI/layout/Modal";
import styles from "./ApplicantsConfirm.module.css";
import logo from "src/assets/logos/icon.svg";
import Button from "src/components/UI/buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock, faCoffee } from "@fortawesome/pro-regular-svg-icons";
import { calculateHoursBetweenDates } from "src/utility/date";
import { roundNumber } from "src/utility/format";
import useStyling from "src/hooks/global/useStyling";
import { formatDateToPrettyTime } from "src/utility/date";
import ModalLabel from "src/components/misc/ModalLabel";

const ApplicantsConfirm = ({ showModal, setShowModal, application, actionApplication, paymentMethod }) => {
  const { user = {}, shift = {} } = application || {};

  const styling = useStyling(styles);

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      label={<ModalLabel text={"Confirm " + application?.user?.first_name} />}
      className={styling("modal-container")}
    >
      <div className={styling("container")}>
        <div className={styling("body-container")}>
          <div className={styling("image-container")}>
            <div className={styling("profile-picture")}>
              <img src={application?.profile?.profile_picture} alt="Profile"></img>
            </div>
            <div className={styling("shiftly-logo")}>
              <img src={logo} alt="Shiftly Logo"></img>
            </div>
          </div>
          <div className={styling("dotted-line-container")}>
            <svg className={styling("dotted-line")} xmlns="http://www.w3.org/2000/svg">
              <line x1="0" y1="75" x2="4000" y2="75" />
            </svg>
            <p>Starts in {formatDateToPrettyTime(shift?.start_time)}</p>
          </div>
          <div className={styling("shift-container")}>
            <div className={styling("shift-heading")}>
              <div className={styling("shift-heading-details")}>
                <h3 className={styling("shift-heading-position")}>
                  {shift?.position?.name} - Level {shift?.position?.classification_level}
                </h3>
                <p className={styling("shift-heading-status")}>
                  Shifter:{" "}
                  <span>
                    {user?.first_name} {user?.last_name}
                  </span>
                </p>
              </div>
            </div>
            <div className={styling("shift-body")}>
              <div className={styling("shift-body-row")}>
                <div className={styling("shift-body-icon")}>
                  <FontAwesomeIcon icon={faCalendar} />
                </div>
                <div className={styling("shift-body-text")}>
                  {shift?.day_of_week} {shift?.prettyDate}
                </div>
              </div>
              <div className={styling("shift-body-row")}>
                <div className={styling("shift-body-icon")}>
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <div className={styling("shift-body-text")}>
                  {shift?.prettyStartTime} - {shift?.prettyEndTime} (
                  {calculateHoursBetweenDates(shift.start_time, shift.end_time)} hrs)
                </div>
              </div>
              {shift?.breakTime && (
                <div className={styling("shift-body-row")}>
                  <div className={styling("shift-body-icon")}>
                    <FontAwesomeIcon icon={faCoffee} />
                  </div>
                  <div className={styling("shift-body-text")}> Breaks: {shift?.breakTime}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styling("footer-container")}>
          <p className={styling("footer-subtext")}>
            *<strong>{paymentMethod?.type === "card" ? "Card" : "Bank Account"}</strong> ending in xxx-{" "}
            <strong>{paymentMethod?.au_becs_debit?.last4 || paymentMethod?.card?.last4}</strong> will be charged.
          </p>
          <div className={styling("action-buttons")}>
            <Button onClick={() => setShowModal(false)} theme={"secondary-outline"}>
              Cancel
            </Button>
            <div className={styling("confirm-action")}>
              <p className={styling("totals")}>
                Total Shift Charge: <span>${roundNumber(application?.total)}</span>
              </p>
              <Button
                onClick={() => {
                  actionApplication({ application_id: application?._id, shift, user, action: "accepted" });
                  setShowModal(false);
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApplicantsConfirm;
