import { useCallback, useEffect, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { customFetch } from "src/utility/http";
import useEntity from "./useEntity";

const fetchData = async (request) => {
  if (!request.entity && !request.node) {
    throw new Error("Entity is required");
  }
  try {
    const method = request.method || "get";
    const { data } = await customFetch({ ...request, method });
    return data;
  } catch (error) {
    throw error;
  }
};

const useFetch = (parameters = {}) => {
  const { request = {}, options = {}, dependency = "", onDataChange } = parameters;
  const hasDependency = Object.hasOwn(parameters, "dependency");
  const hasRequest = Object.hasOwn(parameters, "request");
  const requestID = request.id || request.entity + "." + request.method || "default";
  const queryClient = useQueryClient();

  const entity = useEntity(request.entity);

  //Handle Refresh
  const getAllQueries = useCallback(() => {
    const allQueries = queryClient.getQueriesData();
    return allQueries;
  }, [queryClient]);

  // Mutation setup
  const mutation = useMutation((req) => {
    return fetchData({ ...req, entity: entity.findEntity([req.entity], entity) });
  }, options);

  const memoizedQueryKey = useMemo(() => (dependency ? [requestID, dependency] : requestID), [requestID, dependency]);

  // Query setup
  const query = useQuery({
    queryKey: memoizedQueryKey,
    queryFn: hasRequest ? () => fetchData({ ...request, entity }) : () => {},
    enabled: options.enabled !== undefined ? options.enabled : hasDependency ? !!dependency : hasRequest,
    retry: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    ...options,
  });

  useEffect(() => {
    if (query.data && onDataChange) {
      onDataChange(query.data);
    }
  }, [query.data, onDataChange]);

  const findQueriesByKey = useCallback(
    (queryKey) => {
      const allQueries = getAllQueries();

      const queries = [];

      allQueries.forEach((query) => {
        const firstElement = query[0];
        let queryID;
        typeof firstElement === "string" ? (queryID = firstElement) : (queryID = firstElement[0]);

        if (queryID === queryKey) {
          queries.push(query);
        }
      });
      return queries;
    },
    [getAllQueries]
  );

  const refresh = useCallback(
    (queryKey) => {
      const queries = findQueriesByKey(queryKey);
      queries.forEach((query) => {
        queryClient.invalidateQueries({ queryKey: query[0] || query });
      });
    },
    [queryClient, findQueriesByKey]
  );

  const updateCache = useCallback(
    (query = "", callback = () => {}) => {
      const queries = findQueriesByKey(query);

      queries.forEach((query) => {
        queryClient.setQueryData(query[0], callback);
      });
    },
    [queryClient, findQueriesByKey]
  );

  // Combining states from both mutation and query into a stable reference object
  return useMemo(
    () => ({
      data: query.data || [],
      isLoading: mutation.isLoading || query.isLoading,
      isFetching: mutation.isFetching || query.isFetching,
      error: mutation.error || query.error,
      isError: mutation.isError || query.isError,
      post: async (req = {}) => {
        const { options = {} } = req;
        try {
          options.onMutate?.(req);
          const response = await mutation.mutateAsync(req);
          options.onSuccess?.(response?.data);
          options.select?.(response?.data);

          return response;
        } catch (error) {
          options.onError?.(error);
          return error;
        }
      },
      refresh,
      refetch: query.refetch,
      updateCache,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      mutation.isLoading,
      mutation.isFetching,
      mutation.error,
      mutation.isError,
      mutation.mutate,
      mutation.mutateAsync,
      query.data,
      query.isLoading,
      query.isFetching,
      query.error,
      query.isError,
      query.refetch,
      refresh,
      updateCache,
    ]
  );
};

export default useFetch;
