//Hook that provides dynamic styling for input components
const useInlineStyles = ({ mx, my, px, py, mt, mb, ml, mr, pt, pb, pl, pr, style }) => ({
  marginTop: mt ? mt : my,
  marginBottom: mb ? mb : my,
  marginLeft: ml ? ml : mx,
  marginRight: mr ? mr : mx,
  paddingTop: pt ? pt : py,
  paddingBottom: pb ? pb : py,
  paddingLeft: pl ? pl : px,
  paddingRight: pr ? pr : px,
  ...style,
});

export default useInlineStyles;
