import { useEffect, useState } from "react";
import MessagesCenter from "./MessagesCenter";
import styles from "./MessagesHome.module.css";
import MessagesSidePanel from "./MessagesSidePanel";

import Messages from "src/pages/Shifter/Inbox/components/Messages/Messages";
import withDisplay from "src/components/HOC/withDisplay";
import useReadMessages from "src/pages/Shifter/Inbox/hooks/useReadMessages";
import useStyling from "src/hooks/global/useStyling";
import useShiftlyLocation from "src/hooks/business/useShiftlyLocation";

const MessagesHome = ({ isMobile }) => {
  const [activeConversation, setActiveConversation] = useState();
  const { activeLocation } = useShiftlyLocation();
  const styling = useStyling(styles);

  const readMessages = useReadMessages(activeConversation);

  useEffect(() => {
    if (!activeConversation) return;
    if (activeConversation?.location?._id !== activeLocation?._id) {
      setActiveConversation(null);
    }
  }, [activeConversation, activeLocation]);

  if (isMobile) {
    return <Messages />;
  }

  return (
    <div className={styling("container")}>
      <div className={styling("side-panel")}>
        <MessagesSidePanel
          activeConversation={activeConversation}
          setActiveConversation={setActiveConversation}
          readMessages={readMessages}
        />
      </div>
      <div className={styling("center")}>
        <MessagesCenter activeConversation={activeConversation} setActiveConversation={setActiveConversation} />
      </div>
    </div>
  );
};

export default withDisplay(MessagesHome);
