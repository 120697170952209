import SearchAndDisplay from "src/components/layout/SearchAndDisplay";
import StandardLayout from "../../Account/components/StandardLayout";
import styles from "./IntegrationsHome.module.css";
import useStyling from "src/hooks/global/useStyling";
import useFetch from "src/hooks/global/useFetch";
import { useEffect, useMemo, useState } from "react";
import useBusiness from "src/hooks/business/useBusiness";
import deputyLogo from "src/assets/png/deputy_logo.webp";
import Button from "src/components/UI/buttons/Button";
import { useNavigate } from "react-router-dom";
import Switch from "src/components/UI/inputs/Switch";

function isBlankObject(obj) {
  if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).length === 0;
  }
  return false;
}

const integrations = [
  {
    name: "Deputy",
    logo: deputyLogo,
    page: "/integrations/deputy",
    description: (
      <p>
        Link your Deputy account with Shiftly to seamlessly view and manage your shifts in one place. <br />
        <br />
        Say goodbye to juggling schedules - let us handle the sync for you!
      </p>
    ),
  },
];

const IntegrationsHome = () => {
  const { activeBusiness } = useBusiness();

  const { data } = useFetch({
    request: {
      entity: "Integration",
      method: "get",
      criteria: {
        business: activeBusiness?._id,
      },
      id: "Integration.GetAllIntegrations",
    },
    dependency: activeBusiness?._id,
  });

  const elements = useMemo(() => {
    return integrations.map((integration) => {
      const activeIntegration = data?.find((i) => i.type === integration.name?.toLocaleLowerCase());
      integration.status = activeIntegration?.status || "connect";
      return {
        original: integration,
        content: <IntegrationCard {...integration} />,
        footerContent: <IntegrationFooter {...integration} />,
        listContent: <></>,
      };
    });
  }, [data]);
  return (
    <StandardLayout
      heading={
        <h1>
          <span>Pair us up:</span> Integrate Shiftly
        </h1>
      }
      breadcrumb={"Business Profile /"}
      returnLink="/business-account"
      size="large"
    >
      <SearchAndDisplay elements={elements} hideToggle hideSearchBar />
    </StandardLayout>
  );
};

const IntegrationFooter = ({ status, page, _id, locationMap, positionMap }) => {
  const styling = useStyling(styles);
  const [connected, setConnected] = useState(false);

  const { post: updateIntegration } = useFetch({});

  useEffect(() => {
    if (status === "connect") {
      setConnected(false);
      return;
    }

    if (status === "active") {
      setConnected(true);
      return;
    }

    setConnected(false);
  }, [status]);

  return (
    <div className={styling("footer")}>
      {status === "connect" ? (
        <Button link={page} theme={"secondary"} className={styling("connect-btn")}>
          Get Started
        </Button>
      ) : (
        <Switch
          value={connected}
          setValue={setConnected}
          onChange={(v) => {
            if (isBlankObject(locationMap || {}) || isBlankObject(positionMap || {})) {
              return;
            }

            updateIntegration({
              entity: "Integration",
              method: "update",
              criteria: {
                _id,
              },
              data: {
                status: v ? "active" : "inactive",
              },
            });
          }}
        />
      )}
    </div>
  );
};

const IntegrationCard = ({ logo, name, description, page }) => {
  const styling = useStyling(styles);
  const navigate = useNavigate();
  return (
    <div className={styling("card")} onClick={() => navigate(page)}>
      <div className={styling("details")}>
        <h2>{name}</h2>
        {description}
      </div>
      <div className={styling("logo")}>
        <img src={logo} alt={name} />
      </div>
    </div>
  );
};

export default IntegrationsHome;
