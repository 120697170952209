import Skeleton from "react-loading-skeleton";
import styles from "./ShiftStack.module.css";

const ShiftSkeleton = () => {
  return (
    <div className={styles["loading-container"]}>
      <div className={styles["skeleton-wrapper"]}>
        <Skeleton height={"50%"} width={"100%"} className={styles["skeleton-image"]} />
        <Skeleton count={1} height={"40px"} className={styles["skeleton-text"]} />
        <Skeleton count={3} height={"20px"} className={styles["skeleton-text"]} />
      </div>
    </div>
  );
};

export default ShiftSkeleton;
