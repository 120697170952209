import useFetch from "src/hooks/global/useFetch";
import styles from "./PaymentHistory.module.css";
import useStyling from "src/hooks/global/useStyling";
import CentralHeader from "src/components/misc/CentralHeader";
import Illustration from "src/assets/svg/Illustrations/NoShifts.svg";
import useAuth from "src/hooks/global/useAuth";
import { useEffect, useState } from "react";
import Each from "src/components/func/Each";
import PayHistoryCard from "./components/PayHistoryCard";

const PaymentHistory = () => {
  const styling = useStyling(styles);
  const { user } = useAuth();
  const [payrunsWithTimesheets, setPayrunsWithTimesheets] = useState([]);

  const { data: payments } = useFetch({
    request: {
      entity: "PayRun",
      criteria: {
        status: "paid",
      },
      options: {
        limit: 52,
      },
    },
  });

  const { data: timesheets } = useFetch({
    request: {
      entity: "Timesheet",
      method: "get",
      criteria: {
        user: user?._id,
      },
    },
    dependency: { payments, user },
    options: {
      select: (data) => {
        const tsMap = data.reduce((acc, ts) => {
          acc[ts.payrun] = ts;
          return acc;
        }, {});

        return tsMap;
      },
    },
  });

  useEffect(() => {
    setPayrunsWithTimesheets(
      payments.flatMap((payrun) => {
        const timesheet = timesheets[payrun._id];
        return timesheet ? payrun : [];
      })
    );
  }, [payments, timesheets]);

  return (
    <div className={styling("container")}>
      <CentralHeader text={"Payment History"} />
      <div className={styling("payments")}>
        {payrunsWithTimesheets.length ? (
          <Each
            of={payrunsWithTimesheets}
            render={({ key, ...payrun }) => <PayHistoryCard payrun={payrun} key={key} />}
          />
        ) : (
          <div className={styling("no-payments")}>
            <img src={Illustration} alt="no payments" className={styling("illustration")} />
            <p>No payments yet.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentHistory;
