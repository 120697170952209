import { Route, Routes, useLocation } from "react-router-dom";
import FunctionalWrapper from "./components/layout/FunctionalWrapper";
import Search from "src/pages/Shifter/Search";
import Saved from "src/pages/Shifter/Saved/Saved";
import Shifts from "src/pages/Shifter/Shifts/ShiftsHome";
import Inbox from "src/pages/Shifter/Inbox";
import Portal from "./pages/Portal";
import useUI from "./hooks/global/useUI";
import BusinessHome from "./pages/Business/BusinessHome/BusinessHome";
import PositionsHome from "./pages/Business/Location/Positions/PositionsHome";
import LocationsHome from "./pages/Business/BusinessHome/BusinessAccount/BusinessAccountLocationsHome";
import MessagesHome from "./pages/Business/Location/Messages/MessagesHome";
import ShifterWrapper from "./components/layout/ShifterWrapper";
import {
  BusinessAccountHome,
  BusinessAccountBusinessDetails,
  BusinessAccountBillingAndPayment,
  BusinessAccountPaymentHistory,
} from "./pages/Business/BusinessHome";
import Main from "./components/layout/Main";
import { AccountSettings, AccountSettingsYourDetails } from "./pages/Business/Account";
import AccountSettingsPassword from "./pages/Business/Account/AccountSettingsPassword";
import BusinessAccountLocationEdit from "./pages/Business/BusinessHome/BusinessAccount/BusinessAccountLocationEdit";
import Scheduler from "./pages/Business/Location/Shifts/scheduler/Scheduler";
import BusinessWrapper from "./components/layout/BusinessWrapper";
import CompletedShift from "./pages/Shifter/Shifts/CompletedShift";
import PaymentsHome from "./pages/Shifter/Payments/PaymentsHome";
import PaymentHistory from "./pages/Shifter/Payments/PaymentHistory";
import MobileShifts from "./pages/Business/Location/Shifts/MobileShifts/MobileShifts";
import ShiftHistory from "./pages/Shifter/Shifts/ShiftHistory";
import ProfileHome from "./pages/Shifter/Profile/Home/ProfileHome";
import DigitalCV from "./pages/Shifter/Profile/DigitalCV/DigitalCV";
import EmploymentDetails from "./pages/Shifter/Profile/EmploymentDetails/EmploymentDetails";
import IntegrationsHome from "./pages/Business/BusinessHome/Integrations/IntegrationsHome";
import Deputy from "./pages/Business/BusinessHome/Integrations/pages/Deputy";
import PositionLevels from "./pages/Business/Location/Positions/PositionLevels/PositionLevels";
import People from "./pages/Business/Location/People/People";
import Contact from "./pages/Shifter/Contact/Contact";
import TermsAndConditions from "./pages/Shifter/TermsAndConditions/TermsAndConditions";
import useDisplay from "./hooks/global/useDisplay";
import MobileAccount from "./pages/Business/Account/MobileAccount";
import Security from "./pages/Shifter/Profile/Security/Security";
import TrashedShifts from "./pages/Shifter/Shifts/TrashedShifts";

const App = () => {
  const { mode } = useUI();
  const location = useLocation();
  const { isMobile } = useDisplay();

  return (
    <FunctionalWrapper>
      <Main>
        <Routes location={location} key={location.pathname}>
          <Route path="/portal" exact element={<Portal />} />
          <Route path="/contact" exact element={<Contact />} />
        </Routes>
        {mode === "owner" && (
          <BusinessWrapper>
            <Routes location={location} key={location.pathname}>
              <Route path="/" exact element={<BusinessHome />} />

              {/* Main Routes */}
              <Route path="/shifts" exact element={<Scheduler />} />
              <Route path="/mobile-shifts" exact element={<MobileShifts />} />
              <Route path="/people" exact element={<People />} />
              <Route path="/positions" exact element={<PositionsHome />} />
              <Route path="/positions/position-levels/:group" exact element={<PositionLevels />} />
              <Route path="/messaging" exact element={<MessagesHome />} />

              {/* Business Account */}
              {isMobile ? (
                <Route path="/business-account/*" element={<MobileAccount />} />
              ) : (
                <>
                  <Route path="/business-account" exact element={<BusinessAccountHome />} />
                  <Route path="/business-account/business-details" exact element={<BusinessAccountBusinessDetails />} />
                  <Route path="/business-account/locations" exact element={<LocationsHome />} />
                  <Route
                    path="/business-account/locations/edit-location"
                    exact
                    element={<BusinessAccountLocationEdit />}
                  />
                  <Route
                    path="/business-account/billing-and-payment"
                    exact
                    element={<BusinessAccountBillingAndPayment />}
                  />
                  <Route
                    path="/business-account/billing-and-payment/payment-history"
                    exact
                    element={<BusinessAccountPaymentHistory />}
                  />
                </>
              )}

              {/* Integrations */}
              <Route path="/integrations" exact element={<IntegrationsHome />} />
              <Route path="/integrations/deputy" exact element={<Deputy />} />

              {/* Account */}
              <Route path="/account-settings" exact element={<AccountSettings />} />
              <Route path="/account-settings/your-details" exact element={<AccountSettingsYourDetails />} />
              <Route
                path="/account-settings/your-details/manage-password"
                exact
                element={<AccountSettingsPassword />}
              />
              <Route path="/tncs" exact element={<TermsAndConditions />} />
            </Routes>
          </BusinessWrapper>
        )}
        {mode === "shifter" && (
          <ShifterWrapper>
            <Routes location={location} key={location.pathname}>
              <Route path="/" exact element={<Search />} />
              <Route path="/saved" exact element={<Saved />} />
              <Route path="/shifts" exact element={<Shifts />} />
              <Route path="/shift-history" exact element={<ShiftHistory />} />
              <Route path="/trashed-shifts" exact element={<TrashedShifts />} />
              <Route path="/completed-shift" exact element={<CompletedShift />} />
              <Route path="/payments" exact element={<PaymentsHome />} />
              <Route path="/payment-history" exact element={<PaymentHistory />} />
              <Route path="/inbox" exact element={<Inbox />} />
              <Route path="/profile" exact element={<ProfileHome />} />
              <Route path="/digital-cv" exact element={<DigitalCV />} />
              <Route path="/security" exact element={<Security />} />
              <Route path="/employment-details" exact element={<EmploymentDetails />} />
            </Routes>
          </ShifterWrapper>
        )}
      </Main>
    </FunctionalWrapper>
  );
};

export default App;
