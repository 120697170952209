function arrayToMap(array, keyProperty) {
  return array.reduce((map, obj) => {
    const key = obj[keyProperty.toString()];
    if (!map[key]) {
      map[key] = obj;
    }
    return map;
  }, {});
}

const removeBlankPropertiesInObject = (obj) => {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  }
  return obj;
};

const roundNumber = (number = 0, digits = 2) => {
  const multiplier = Math.pow(10, digits);
  return Math.round(number * multiplier) / multiplier;
};

const truncate = (str = "", length = Infinity) => {
  if (str.length > length) {
    return str.substring(0, length) + "...";
  }
  return str;
};
module.exports = { arrayToMap, removeBlankPropertiesInObject, roundNumber, truncate };
