import withInputBase from "src/components/HOC/withInputBase";
import styles from "./RadioButtons.module.css";
import useStyling from "src/hooks/global/useStyling";
import { useState, useImperativeHandle, forwardRef, useCallback, useEffect } from "react";
import useInlineStyles from "src/hooks/global/useInlineStyles";
import Link from "src/components/func/Link";
import clsx from "clsx";

const RadioButtons = forwardRef(
  (
    {
      value,
      setValue,
      required,
      error,
      options = [],
      align = "flex-start",
      type = "list",
      label,
      link = {},
      className,
      formProps,
      ...props
    },
    ref
  ) => {
    const [selectedOption, setSelectedOption] = useState({});

    const styling = useStyling(styles);
    const style = useInlineStyles(props);

    useImperativeHandle(ref, () => ({
      refresh: () => {
        setSelectedOption({});
      },
    }));

    const handleOptionClick = useCallback(
      (option, index) => {
        setSelectedOption({ value: option.value || option, index });
        setValue(option.value || option);
      },
      [setValue]
    );

    useEffect(() => {
      const selected = options.find((option) => option.value === value);
      selected && setSelectedOption({ value: selected.value, index: options.indexOf(selected) });
    }, [value, options]);

    return (
      <div className={styling("wrapper")}>
        <div className={styling("header")}>
          {label && (
            <label className={styling("label")}>
              {label}: {required && <span>*</span>}
            </label>
          )}
          {link?.destination && (
            <Link to={link.destination} className={styling("link")}>
              {link.label}
            </Link>
          )}
        </div>
        <div
          className={clsx(styling("container"), type === "cards" && styling("cards-container"), className)}
          style={{ ...style, justifyContent: align }}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className={clsx(
                type === "list" ? styling("radio-button-container") : styling("radio-button-card"),
                selectedOption.index === index && styling("active")
              )}
              onClick={() => handleOptionClick(option, index)}
            >
              {type === "list" ? (
                <>
                  <p>{option.label || option}</p>
                  <div className={styling("radio-button")}>
                    <div className={styling("radio-button-inner")}></div>
                  </div>
                </>
              ) : (
                option.content
              )}
            </div>
          ))}
        </div>
        <input type="hidden" {...formProps} />
        {error && (
          <div className={styling("error-container")}>
            <p className={styling("message")}>{error}</p>
          </div>
        )}
      </div>
    );
  }
);

export default withInputBase(RadioButtons);
