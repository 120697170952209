import { useCallback, useEffect, useMemo, useState } from "react";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { newGUID } from "src/utility/generators";
import clsx from "clsx";
import styles from "./DraggableList.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

const DraggableList = ({
  items = [],
  setItems = () => {},
  layout = "horizontal",
  draggableContainerClass = "",
  dropBoxContainerClass = "",
}) => {
  const id = useMemo(() => newGUID(), []);
  const [draggableItems, setDraggableItems] = useState(items);

  useEffect(() => {
    setDraggableItems(items.map((item) => ({ key: newGUID(), ...item })));
  }, [items]);

  const handleOnDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;

      const items = Array.from(draggableItems);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setItems(items);
      setDraggableItems(items);
    },
    [draggableItems, setItems]
  );

  const handleRemoveItem = useCallback(
    (key) => {
      const items = draggableItems.filter((item) => item.key !== key);
      setItems(items);
      setDraggableItems(items);
    },
    [draggableItems, setItems]
  );


  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId={id} direction={layout}>
        {(provider) => (
          <div ref={provider.innerRef} {...provider.droppableProps} className={dropBoxContainerClass}>
            {draggableItems.map((item, index) => (
              <Draggable key={item.key} draggableId={item.key} index={index}>
                {(provider) => (
                  <div
                    ref={provider.innerRef}
                    {...provider.draggableProps}
                    {...provider.dragHandleProps}
                    className={clsx(styles["draggableElement"], draggableContainerClass)}
                  >
                    <div className={styles["trash-icon"]} onClick={() => handleRemoveItem(item.key)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provider.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
