import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SignOn.module.css";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { formatDateToPrettyTime } from "src/utility/date";
import { truncate } from "src/utility/format";
import Button from "src/components/UI/buttons/Button";
import useToast from "src/hooks/global/useToast";
import useFetch from "src/hooks/global/useFetch";
import FindEmployer from "./FindEmployer";
import moment from "moment";
import useStyling from "src/hooks/global/useStyling";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import useAlerts from "src/contexts/Alerts";

const SignOn = ({ shift, setShowDetails = () => {} }) => {
  const { location = {}, address = {}, position = {} } = shift;
  const [time, setTime] = useState(<FontAwesomeIcon icon={faSpinner} spin />);
  const [checkInTime, setCheckInTime] = useState("");
  const [pin, setPin] = useState(["", "", "", ""]);
  const inputsRef = useRef([]);
  const toast = useToast();
  const styling = useStyling(styles);
  const navigate = useNavigate();

  const { confirm } = useAlerts();

  const { post: signOn, updateCache } = useFetch({
    options: {
      onSuccess: (data) => {
        setPin(["", "", "", ""]);
        updateCache("Timesheet.GetTimesheetForShift", (oldData = []) => {
          return [data[0], ...oldData];
        });
      },
    },
  });

  const { post: signOff } = useFetch({
    options: {
      onMutate: () => {
        navigate("/completed-shift", { state: { shift: { ...shift, status: "completed" } } });
      },
    },
  });

  const {
    data: [timesheet],
  } = useFetch({
    request: {
      entity: "Timesheet",
      method: "get",
      criteria: {
        shift: shift._id,
        user: shift.user,
      },
      id: "Timesheet.GetTimesheetForShift",
    },
    dependency: shift._id,
  });

  const handleChange = (value, index) => {
    if (value.length > 1) {
      return;
    }
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    // Move to the next input field if available
    if (value && index < 3) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !pin[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handleSignOff = useCallback(async () => {
    if (
      !(await confirm({
        label: "Are you sure are ready to clock out?",
        text: <p>Please inform your manager that you have finished for the day.</p>,
        inverse: true,
        confirmText: "Sign Off",
      }))
    )
      return;

    signOff({
      entity: "Timesheet",
      method: "signOff",
      data: {
        shift,
      },
    });
  }, [signOff, shift, confirm]);

  useEffect(() => {
    const { start_time } = shift;
    const prettyCheckInTime = formatDateToPrettyTime(moment(start_time).subtract(15, "minutes"));
    setCheckInTime("Check in opens: " + prettyCheckInTime);

    if (!timesheet) {
      setTime("0h 00m 00s");
      return;
    }

    setCheckInTime("Checked in at: " + moment(timesheet.sign_on_time).format("hh:mm A"));
    setInterval(() => {
      const { start_time } = shift;
      const currentTime = moment();
      const diff = moment.duration(currentTime.diff(start_time));
      const hours = Math.max(0, diff.hours()).toString();
      const minutes = Math.max(0, diff.minutes()).toString().padStart(2, "0");
      const seconds = Math.max(0, diff.seconds()).toString().padStart(2, "0");
      setTime(`${hours}h ${minutes}m ${seconds}s`);
    }, 1000);
  }, [shift, timesheet]);

  const handleSignOn = () => {
    if (!pin.every((v) => v)) {
      return;
    }

    // Check that time is within 15 minutes of start time
    const { start_time } = shift;
    const fifteenMinutesBefore = moment(start_time).subtract(15, "minutes");
    const currentTime = moment();

    if (currentTime.isBefore(fifteenMinutesBefore)) {
      toast.error("You can only sign on 15 minutes before your shift starts");
      return;
    }

    // Confirm pin
    const shiftPin = Number(pin.join(""));

    if (shiftPin !== shift.pin) {
      toast.error("Unfortunately, that's not the right pin. Try again!");
      return;
    }

    signOn({
      entity: "Timesheet",
      method: "signOn",
      data: {
        shift,
      },
    });
  };

  return (
    <div className={styling("container")}>
      <div className={styling("location-container")} onClick={() => setShowDetails(shift)}>
        <div className={styling("location-logo")}>
          <img src={location.logo} alt="Locations logo" />
        </div>
        <div className={styling("location-details")}>
          <h4>{location.name}</h4>
          <p>{truncate(address.full_address, 50)}</p>
        </div>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
      <div className={styling("pin-container")}>
        <div className={styling("time")}>
          <h3>{time}</h3>
          <p>{checkInTime}</p>
        </div>
        <div className={styling("shift-details")}>
          <p>
            {position.name}, {shift.shiftPeriod}
          </p>
        </div>
        {!timesheet ? (
          <div className={styling("pin")}>
            <p>Ask for their pin</p>
            <div className={styling("pin-inputs")}>
              {pin.map((digit, index) => (
                <input
                  key={index}
                  pattern="\d*"
                  inputMode="numeric"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(el) => (inputsRef.current[index] = el)}
                  className={styling("pin-input")}
                  disabled={Boolean(timesheet)}
                />
              ))}
            </div>
            <Button
              theme="primary"
              onClick={handleSignOn}
              className={styling("sign-on")}
              disabled={!pin.every((v) => v) || Boolean(timesheet)}
            >
              Sign on
            </Button>
          </div>
        ) : (
          <div className={styling("finished-buttons")}>
            <Button onClick={handleSignOff} theme="primary" className={styling("sign-off")}>
              Finish Shift
            </Button>
          </div>
        )}
      </div>
      <FindEmployer location={address} />
    </div>
  );
};

export default SignOn;
