import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./CVCertItem.module.css";
import { truncate } from "src/utility/format";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useSpring } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import { animated } from "@react-spring/web";
import { useCallback, useEffect } from "react";
import useFetch from "src/hooks/global/useFetch";
import useAuth from "src/hooks/global/useAuth";

const CVCertItem = ({ id, type, profile, setShowEditCerts, ...record }) => {
  const { user } = useAuth();
  const {
    post: updateUser,
    refresh,
    updateCache,
  } = useFetch({
    options: {
      onMutate: () => {
        updateCache("Profile.GetUserProfile", (oldData) => {
          const data = oldData?.[0];
          if (!data) return oldData;
          const updatedData = { ...data };
          updatedData[type] = data[type].filter((cert) => cert.id !== id);
          return [updatedData];
        });
      },
      onSuccess: () => {
        refresh("Profile.GetUserProfile");
      },
    },
  });

  const title = record.venue || record.name;
  const description = record.description;
  const subtext = record.position || `${record.issuing_body || ""}`;

  const [swipeTab, swipeApi] = useSpring(
    () => ({
      from: { x: 0 },
      to: { y: 0 },
    }),
    []
  );

  useEffect(() => {
    swipeApi.start({
      x: 0,
    });
  }, [id, swipeApi]);

  const deleteCert = useCallback(() => {
    const oldData = profile[type] || [];
    const updatedData = oldData.filter((cert) => cert.id !== id);

    swipeApi.start({
      x: 0,
      immediate: true,
    });

    updateUser({
      entity: "Profile",
      criteria: { user: user._id },
      method: "update",
      data: {
        [type]: updatedData,
      },
    });
  }, [id, updateUser, user._id, type, profile, swipeApi]);

  const bindSwipe = useDrag(
    ({ velocityX, movement: [dx, dy], direction: [xDir], distance }) => {
      const trigger = velocityX > 0.2; // Adjust velocity threshold for swipe detection
      const config = { friction: 50, tension: 500 }; // Fine-tune for smoother animation
      const swipeThreshold = 100; // Distance in pixels to consider it a swipe
      const direction = dx > 0 ? "right" : "left";
      const isHorizontalSwipe = Math.abs(dx) > Math.abs(dy); // Ensure swipe is more horizontal than vertical

      // Ensure movement is sufficient and mostly horizontal to trigger swipe animation
      if (isHorizontalSwipe && distance > swipeThreshold) {
        if (direction === "right") {
          swipeApi.start({
            x: 120,
            immediate: velocityX > 0.2, // Make movement immediate if fast enough
            config,
          });
        } else {
          // For left swipes, or to reset
          swipeApi.start({
            x: 0,
            config,
          });
        }
      } else if (!trigger || !isHorizontalSwipe) {
        // Reset position if not a horizontal swipe or trigger condition not met
        swipeApi.start({
          x: 0,
          config,
        });
      }
    },
    {
      filterTaps: true, // This option can help in distinguishing between taps and drags/swipes
      axis: "x", // Consider horizontal movements only for the swipe gesture
    }
  );

  return (
    <div className={styles["cert-tabs-container"]}>
      <animated.div {...bindSwipe()} style={swipeTab} className={styles["cert-tab"]}>
        <h4>{title}</h4>
        <p className={styles["cert-desc"]}>{truncate(description, 70)}</p>
        <p className={styles["cert-subtext"]}>{subtext}</p>
        <FontAwesomeIcon
          icon={faPencil}
          className={styles["edit-tab"]}
          onClick={() =>
            setShowEditCerts({ visible: true, mode: "Edit", record: { id, description, subtext, ...record } })
          }
        />
      </animated.div>
      <div className={styles["tab-delete-container"]} onClick={deleteCert}>
        <FontAwesomeIcon icon={faTrash} />
      </div>
    </div>
  );
};

export default CVCertItem;
