import clsx from "clsx";
import styles from "./NotificationWrapper.module.css";
import NotificationWithLink from "./NotificationWithLink";
import NotificationInfoOnly from "./NotificationInfoOnly";
import React from "react";
import { formatDateToPrettyTime } from "src/utility/date";

const notificationTypeMap = {
  link: NotificationWithLink,
  info: NotificationInfoOnly,
};

const NotificationWrapper = ({ read = false, type, createdAt, ...props }) => {

  return (
    <div className={clsx(styles["container"], !read && styles["unread"])}>
      {notificationTypeMap[type] ? React.createElement(notificationTypeMap[type], props) : <></>}
      <div className={styles["time"]}>
        <p>{formatDateToPrettyTime(createdAt)}</p>
      </div>
    </div>
  );
};

export default NotificationWrapper;
