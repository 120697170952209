import useProfile from "src/hooks/shifter/useProfile";
import CVTabs from "./CVTabs";
import styles from "./DigitalCV.module.css";
import useStyling from "src/hooks/global/useStyling";
import noImage from "src/assets/png/no_img_placeholder.jpeg";
import Skeleton from "react-loading-skeleton";
import { useRef, useState } from "react";
import FileUploader from "src/components/UI/inputs/FileUploader";
import useFetch from "src/hooks/global/useFetch";
import useAuth from "src/hooks/global/useAuth";
import RatingDisplay from "src/components/misc/RatingDisplay";
import CentralHeader from "src/components/misc/CentralHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import CVEditBio from "./components/CVEditBio";
import CVEmptyState from "./components/CVEmptyState";
import { truncate } from "src/utility/format";

const DigitalCV = () => {
  const styling = useStyling(styles);
  const { user } = useAuth();
  const { isLoading, profile, updateProfilePic } = useProfile();
  const fileUploaderRef = useRef();

  const [editBio, setEditBio] = useState(false);

  const { data: shiftHistory } = useFetch({
    request: {
      entity: "ShiftHistory",
      criteria: { user: user?._id, status: "completed" },
    },
    dependency: user?._id,
  });

  return (
    <>
      <FileUploader visible={false} ref={fileUploaderRef} onUploadComplete={updateProfilePic} />
      <div className={styling("container")}>
        <CentralHeader chevron text={"Digital CV"} link={"/profile"} />
        <div className={styling("content")}>
          <div className={styling("picture-container")}>
            {isLoading ? (
              <Skeleton circle height={"150px"} width={"150px"} className={styles["progress-img-skeleton"]} />
            ) : (
              <img
                src={profile?.profile_picture || noImage}
                alt="user profile"
                onClick={() => fileUploaderRef.current?.click()}
              />
            )}
          </div>
          <div className={styling("info-bar")}>
            <div className={styling("info-item")}>
              <RatingDisplay rating={profile?.rating} />
              <p>Rating</p>
            </div>
            <div className={styling("info-item")}>
              <p>{shiftHistory.length}</p>
              <p>Shifts Worked</p>
            </div>
          </div>
          <CVEditBio show={editBio} setShow={setEditBio} />
          <div className={styling("bio-container")} onClick={() => setEditBio(true)}>
            <div className={styling("bio-heading")}>
              <h4>Bio</h4>
              <FontAwesomeIcon icon={faPencil} className={styling("edit-icon")} />
            </div>
            {profile?.bio ? (
              <p>{truncate(profile?.bio, 130)}</p>
            ) : (
              <CVEmptyState text={"Add a bio to highlight why business owners should choose you."} button="Add bio" />
            )}
          </div>
          <CVTabs />
        </div>
      </div>
    </>
  );
};

export default DigitalCV;
