import { useState } from "react";
import styles from "./Inbox.module.css";
import Toggle from "src/components/UI/tabs/Toggle";
import { faMessages, faBell } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Messages from "./components/Messages/Messages";
import Notifications from "../../../components/misc/Notifications/Notifications";
import usePushNotification from "src/hooks/global/usePushNotifications";
import useSearchParams from "src/hooks/global/useSearchParams";

const Inbox = () => {
  const [tab, setTab] = useState(0);
  const { unreadNotifications, readNotifications, markAsRead } = usePushNotification();

  useSearchParams(["tab"], ({ tab }) => {
    setTab(tab === "notifications" ? 1 : 0);
  }, []);

  return (
    <div className={styles["container"]}>
      <div className={styles["tabs-container"]}>
        <Toggle
          tab={tab}
          setTab={setTab}
          tabs={[
            { text: "Messages", icon: <FontAwesomeIcon icon={faMessages} /> },
            {
              text: `Notifications ${unreadNotifications.length ? `(${unreadNotifications.length})` : ``}`,
              icon: <FontAwesomeIcon icon={faBell} />,
            },
          ]}
        />
      </div>
      <div className={styles["content"]}>
        {tab === 0 ? (
          <Messages />
        ) : (
          <Notifications
            unreadNotifications={unreadNotifications}
            readNotifications={readNotifications}
            markAsRead={markAsRead}
          />
        )}
      </div>
    </div>
  );
};
export default Inbox;
