import Button from "src/components/UI/buttons/Button";
import styles from "./FindEmployer.module.css";

const FindEmployer = ({ location = {} }) => {
  return (
    <div className={styles["container"]}>
      <h5>{location.full_address}</h5>
      <Button theme={"secondary-outline"} className={styles['button']}>Find on Google Maps</Button>
    </div>
  );
};

export default FindEmployer;
