import { useEffect, useCallback } from "react";
import clsx from "clsx";
import useDisplay from "./useDisplay";
import { useWindowSize } from "react-use";

const useStyling = (styles) => {
  const { isMobile, isTablet, isLaptop } = useDisplay();

  const windowSize = useWindowSize();

  const windowSizeClasses = useCallback(
    (classNames = []) => {
      const { height } = windowSize;

      const extraSmall = height <= 667;
      const small = height > 667 && height <= 780;
      const medium = height > 780 && height <= 860;
      const large = height > 860 && height <= 940;
      const extraLarge = height > 940;

      return classNames.map((className) => {
        if (extraSmall) return styles[`${className}-xs`];
        if (small) return styles[`${className}-sm`];
        if (medium) return styles[`${className}-md`];
        if (large) return styles[`${className}-lg`];
        if (extraLarge) return styles[`${className}-xl`];
        return null;
      });
    },
    [windowSize, styles]
  );

  const getResponsiveClassName = useCallback(
    (...classNames) => {
      const responsiveClassNames = classNames.flatMap((className) => {
        if (!className) return [];

        if (isMobile) {
          return clsx(styles[className], styles[`mobile-${className}`], className);
        } else if (isTablet) {
          return clsx(styles[className], styles[`tablet-${className}`], className);
        } else if (isLaptop) {
          return clsx(styles[className], styles[`laptop-${className}`], className);
        } else {
          return clsx(styles[className], className);
        }
      });

      return clsx(...responsiveClassNames, ...windowSizeClasses(classNames));
    },
    [isMobile, isTablet, isLaptop, styles, windowSizeClasses]
  );

  useEffect(() => {
    // This useEffect ensures the component re-renders whenever isMobile, isTablet, or isLaptop changes
    // No additional logic needed here as we are just leveraging the re-render effect
  }, [isMobile, isTablet, isLaptop]);

  return getResponsiveClassName;
};

export default useStyling;
