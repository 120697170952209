import styles from "./StandardLayoutLink.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const StandardLayoutLink = ({ icon, text, link }) => {
  const navigate = useNavigate();

  return (
    <div className={styles["account-link"]} onClick={() => navigate(link)}>
      <div className={styles["link-details"]}>
        <div className={styles["link-icon"]}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <h5>{text}</h5>
      </div>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
};

export default StandardLayoutLink;
