import { useAutoAnimate } from "@formkit/auto-animate/react";
import styles from "./NewPosition.module.css";
import Each from "src/components/func/Each";
import Button from "src/components/UI/buttons/Button";
import { newGUID } from "src/utility/generators";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import NewPositionQualification from "./NewPositionQualification";
import useStyling from "src/hooks/global/useStyling";

const NewPositionQualifications = ({ qualifications, setQualifications }) => {
  const [parent] = useAutoAnimate();
  const styling = useStyling(styles);

  return (
    <div className={styling("container")}>
      <div className={styling("heading")}>
        <h1>
          What are some of the <span>qualifications</span> your employee will require?
        </h1>
        <p>No qualifications required? Just skip this step.</p>
      </div>
      <div className={styling("content", "short-content")}>
        <div
          className={styling("locations-container")}
          ref={parent}
          style={
            qualifications.length > 1
              ? {
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }
              : {}
          }
        >
          <Each
            of={qualifications}
            render={({ key, ...qualification }, index) => (
              <NewPositionQualification
                key={key}
                {...qualification}
                setQualifications={setQualifications}
                index={index}
                qualifications={qualifications}
              />
            )}
          />
        </div>
        <Button
          className={styling("add-location-button")}
          icon={faPlus}
          onClick={() => qualifications.length < 6 && setQualifications((prev) => [...prev, { id: newGUID() }])}
          disabled={qualifications.length >= 6}
        >
          Add New Qualification
        </Button>
      </div>
    </div>
  );
};

export default NewPositionQualifications;
