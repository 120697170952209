import { useUncontrolled } from "uncontrollable";
import styles from "./CentralHeader.module.css";
import useStyling from "src/hooks/global/useStyling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const CentralHeader = (props) => {
  const styling = useStyling(styles);

  const navigate = useNavigate();
  const { setShow, text, chevron, close } = useUncontrolled(props, {
    show: "setShow",
  });

  return (
    <div
      className={styling("heading", !chevron && !close && "left")}
      onClick={() => {
        props.link && navigate(props.link);
        setShow(false);
      }}
    >
      {chevron && <FontAwesomeIcon icon={faChevronLeft} className={styling("close-icon")} />}
      {close && <FontAwesomeIcon icon={faTimes} className={styling("close-icon", "larger-icon")} />}
      <h4>{text}</h4>
    </div>
  );
};

export default CentralHeader;
