import SideLayout from "src/components/layout/SideLayout";
import styles from "../EmploymentDetails.module.css";
import useStyling from "src/hooks/global/useStyling";
import { useCallback, useMemo } from "react";
import Button from "src/components/UI/buttons/Button";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import useFetch from "src/hooks/global/useFetch";
import useAuth from "src/hooks/global/useAuth";
import Input from "src/components/UI/inputs/Input";
import Address from "src/components/UI/inputs/Address";
import FormV2 from "src/components/func/FormV2";

const EDContactDetails = (props) => {
  const styling = useStyling(styles);

  const { user } = useAuth();
  const {
    post,
    refresh,
    isLoading: userIsLoading,
    updateCache,
  } = useFetch({
    options: {
      onSuccess: (data) => {
        updateCache("Address.GetUserAddressInProfile", data);
        refresh("initAuth");
      },
    },
  });

  const {
    data: [address],
    refetch,
  } = useFetch({
    request: {
      entity: "Address",
      method: "get",
      criteria: { user: user?._id },
      id: "Address.GetUserAddressInProfile",
    },
    dependency: user?._id,
    options: {
      select: (data) => {
        return data.map((address) => ({
          ...address,
          post_code: Number(address.post_code),
        }));
      },
    },
  });

  const {
    post: postAddress,
    isLoading: addressIsLoading,
    isError,
  } = useFetch({
    options: {
      onSuccess: (data) => {
        refetch();
      },
    },
  });

  const initial = useMemo(() => ({ ...user, ...address, address }), [user, address]);

  const handleSubmit = useCallback(
    async (data) => {
      await post({
        entity: "User",
        method: "update",
        criteria: { _id: user._id },
        data,
      });

      return await postAddress({
        entity: "Address",
        method: "update",
        criteria: { user: user._id },
        options: { upsert: true },
        data: {
          ...data?.address,
          user: user?._id,
        },
      });
    },
    [post, user, postAddress]
  );

  return (
    <SideLayout heading={"Contact Details"} {...props}>
      <div className={styling("form-container")}>
        <FormV2 onSubmit={handleSubmit} initial={initial}>
          <Input name={"email"} />
          <Input name={"phone_number"} />
          <Address name={"address"} />
          <Button
            loading={userIsLoading || addressIsLoading}
            error={isError}
            theme={"secondary"}
            type="submit"
            className={styling("save-btn")}
            icon={faSave}
          >
            Save Contact Details
          </Button>
          <Button
            theme={"secondary-transparent"}
            className={styling("save-btn")}
            onClick={() => {
              props.setShow(false);
            }}
          >
            Cancel
          </Button>
        </FormV2>
      </div>
    </SideLayout>
  );
};

export default EDContactDetails;
