import React, { createContext, useContext, useCallback, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NavigationContext = createContext();

export const useNavigation = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [pageHistory, setPageHistory] = useState([]);
  const [, setCurrentPage] = useState(null);

  const isSameAsLastPage = useCallback(
    (path) => pageHistory.length && pageHistory[pageHistory.length - 1]?.path === path,
    [pageHistory]
  );

  const setLastVisitedPage = useCallback(
    (path, payload = {}) => {
      setCurrentPage((prevPage) => {
        if (prevPage && !isSameAsLastPage(path)) {
          setPageHistory((prevHistory) => {
            const newHistory = [...prevHistory, prevPage].slice(-5);
            return newHistory;
          });
        }
        return { path, payload };
      });
    },
    [isSameAsLastPage]
  );

  useEffect(() => {
    setLastVisitedPage(location.pathname);
  }, [location.pathname, setLastVisitedPage]);

  const goBack = useCallback(() => {
    setPageHistory((prevHistory) => {
      if (prevHistory.length > 1) {
        const lastVisited = prevHistory[prevHistory.length - 2];
        const updatedHistory = prevHistory.slice(0, -2);
        setCurrentPage(lastVisited);
        navigate(lastVisited.path, { state: lastVisited.payload });
        return updatedHistory;
      } else if (prevHistory.length === 1) {
        const lastVisited = prevHistory[0];
        navigate(lastVisited.path, { state: lastVisited.payload });
        return [];
      }
      return prevHistory;
    });
  }, [navigate]);

  return (
    <NavigationContext.Provider value={{ setLastVisitedPage, goBack, pageHistory }}>
      {children}
    </NavigationContext.Provider>
  );
};
