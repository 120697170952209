import { useCallback, useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPaperPlane, faPlus } from "@fortawesome/pro-regular-svg-icons";

import styles from "./MessageWindow.module.css";
import useFetch from "src/hooks/global/useFetch";
import useSocket from "src/hooks/global/useSocket";
import useUI from "src/hooks/global/useUI";
import Input from "src/components/UI/inputs/Input";
import Each from "src/components/func/Each";
import MessageBubbleShifter from "./MessageBubbleShifter";
import useMessagesCriteria from "../../hooks/useMessagesCriteria";
import useAuth from "src/hooks/global/useAuth";
import useStyling from "src/hooks/global/useStyling";
import useReadMessages from "../../hooks/useReadMessages";
import useAsyncEffect from "src/hooks/global/useAsyncEffect";
import useSearchParams from "src/hooks/global/useSearchParams";
import useShiftlyLocation from "src/hooks/business/useShiftlyLocation";

const MessageWindow = ({ activeConversation = {}, setActiveConversation }) => {
  const [message, setMessage] = useState("");

  const { activeLocation } = useShiftlyLocation();
  const { user } = useAuth();
  const { businessMode } = useUI();
  const styling = useStyling(styles);

  const scrollRef = useRef();
  const conversationCreatedRef = useRef();

  const { sendEvent } = useSocket();
  const criteria = useMessagesCriteria(activeConversation);

  const { user: conversationUser = {}, location = {} } = activeConversation;

  const { post: getFullConversation } = useFetch({
    options: {
      onSuccess: (data) => {
        setActiveConversation((prev) => data[0] || prev);
      },
    },
  });

  const {
    data: [profile = {}],
  } = useFetch({
    request: {
      entity: "Profile",
      method: "get",
      criteria: {
        user: activeConversation?.user?._id,
      },
    },
    dependency: activeConversation?.user?._id,
  });

  useAsyncEffect(async () => {
    if (activeConversation._id && (!activeConversation?.location?._id || !activeConversation?.user?._id)) {
      getFullConversation({
        entity: "Conversation",
        method: "getAndPopulate",
        populate: ["location", "user"],
        criteria: {
          _id: activeConversation._id,
        },
      });
    }
  }, [activeConversation]);

  const { data: conversations, isLoading } = useFetch({
    request: {
      entity: "Conversation",
      method: "getExistingConversations",
      criteria,
      id: "Conversation.GetExistingConversations",
    },
    dependency: criteria,
  });

  const {
    updateCache,
    data: messages,
    refresh,
  } = useFetch({
    request: {
      entity: "Message",
      method: "getMessagesWithAccess",
      data: {
        conversation_id: activeConversation?._id,
        ...criteria,
      },
      enabled: Boolean(activeConversation?._id),
      id: "Message.GetMessagesWithAccess",
    },
    dependency: { conversation_id: activeConversation?._id, criteria },
  });

  //--------------------------- Mark Messages As Read ------------------------------

  const readMessage = useReadMessages(activeConversation);

  // ---------------------------Create New Conversation ---------------------------
  const { post: createNewConversation } = useFetch({
    options: {
      onSuccess: (data = []) => {
        refresh("Conversation.GetExistingConversations");
        if (data?.[0]) setActiveConversation(data[0]);
      },
    },
  });

  useSearchParams(
    ["location", "user"],
    ({ location: locationID, user: userID }) => {
      if (
        (!locationID && !userID) ||
        isLoading ||
        conversationCreatedRef.current ||
        (!user && !userID) ||
        (!activeLocation && !locationID)
      )
        return;

      const conversation = conversations.find((conv) => conv.location?._id === locationID || conv.user?._id === userID);

      if (conversation) {
        conversationCreatedRef.current = true;
        setActiveConversation(conversation);
        return;
      }

      const user_id = userID || user._id;
      const location_id = locationID || activeLocation?._id;

      createNewConversation({
        entity: "Conversation",
        method: "create",
        data: {
          user: user_id,
          location: location_id,
          start_date: new Date(),
          last_sent: new Date(),
        },
      });
      conversationCreatedRef.current = true;
    },
    [conversations, user, isLoading, activeLocation]
  );

  const { post: saveMessage } = useFetch({
    options: {
      onMutate: ({ data }) => {
        updateCache("Message.GetMessagesWithAccess", (oldData = []) => [...oldData, data]);
      },
      onSuccess: ([mes] = []) => {
        sendEvent("send_message", { ...mes });
      },
    },
  });

  //--------------------------- Scrolling ----------------------------------------
  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      setTimeout(() => {
        if (scrollRef.current === null) return;
        scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
      }, 300);
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, activeConversation]);

  //--------------------------- Sending Messages --------------------------------
  const handleSendMessage = useCallback(() => {
    if (!activeConversation || !message) return;

    const user_id = activeConversation?.user?._id || activeConversation?.user || user?._id;
    const location_id = activeConversation?.location?._id || activeConversation?.location || activeLocation?._id;

    const newMessage = {
      content: message,
      conversation: activeConversation._id,
      user: user_id,
      location: location_id,
      for_id: !businessMode ? location_id : user_id,
      unread: true,
      sent_date: new Date(),
    };

    saveMessage({
      entity: "Message",
      data: { ...newMessage },
      method: "create",
    });

    scrollToBottom();
    setMessage("");
  }, [activeConversation, user, message, saveMessage, scrollToBottom, activeLocation, businessMode]);

  //--------------------------- Receive Messages --------------------------------
  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  useEffect(() => {
    const id = setTimeout(() => {
      if (!activeConversation._id) return;

      readMessage(activeConversation._id);
    }, 200);

    return () => {
      clearTimeout(id);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversation, messages]);

  if (!activeConversation) return null;

  return (
    <div className={styling("container", activeConversation?._id && "visible")}>
      <div
        className={styling("heading", activeConversation?._id && "visible")}
        onClick={() => {
          setActiveConversation();
        }}
      >
        <div className={styling("heading-chevron")}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div className={styling("heading-image")}>
          <img src={businessMode ? profile.profile_picture : location?.logo} alt="Location Logo" />
        </div>
        <div className={styling("heading-title")}>
          {businessMode ? `${conversationUser.first_name} ${conversationUser.last_name}` : location?.name}
        </div>
      </div>
      <div className={styling("messages-container")} ref={scrollRef}>
        <div className={styling("messages")}>
          <Each
            of={messages}
            render={({ key, ...msg }, index) => <MessageBubbleShifter key={key} {...msg} after={messages[index + 1]} />}
          />
        </div>
      </div>
      <div className={styling("message-bar")}>
        <FontAwesomeIcon icon={faPlus} />
        <div className={styling("messages-input")}>
          <Input
            placeholder="Type message here..."
            value={message}
            setValue={setMessage}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            onFocus={scrollToBottom}
          />
        </div>
        <FontAwesomeIcon icon={faPaperPlane} className={styling("send-button")} onClick={handleSendMessage} />
      </div>
    </div>
  );
};

export default MessageWindow;
