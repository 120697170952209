import Toggle from "src/components/UI/tabs/Toggle";
import styles from "../Home/ProfileHome.module.css";
import { useEffect, useMemo, useState } from "react";
import Each from "src/components/func/Each";
import CVCertItem from "./CVCertItem";
import Button from "src/components/UI/buttons/Button";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import useProfile from "src/hooks/shifter/useProfile";
import CVEditExperience from "./components/CVEditExperience";
import CVEditQualification from "./components/CVEditQualification";

const CVTabs = () => {
  const [tab, setTab] = useState(0);
  const [visibleContent, setVisibleContent] = useState({ data: [], title: "" });
  const [showEditCerts, setShowEditCerts] = useState({ visible: false });
  const [parent] = useAutoAnimate();
  const { profile } = useProfile();

  useEffect(() => {
    switch (tab) {
      case 0:
        setVisibleContent({ data: profile?.work_experience || [], title: "Experience", type: "work_experience" });
        break;
      case 1:
        setVisibleContent({ data: profile?.qualifications || [], title: "Qualifications", type: "qualifications" });
        break;
      default:
        break;
    }
  }, [tab, setVisibleContent, profile?.work_experience, profile?.qualifications]);

  const sharedProps = useMemo(
    () => ({
      show: showEditCerts.visible,
      setShow: () => setShowEditCerts({ visible: false }),
      record: showEditCerts.record,
      mode: showEditCerts.mode,
    }),
    [showEditCerts]
  );

  return (
    <>
      {visibleContent?.title === "Experience" && <CVEditExperience {...sharedProps} />}
      {visibleContent?.title === "Qualifications" && <CVEditQualification {...sharedProps} />}

      <div className={styles["cert-tabs-container"]}>
        <div className={styles["cert-tabs"]}>
          <Toggle tab={tab} setTab={setTab} tabs={[{ text: "Experience" }, { text: "Qualifications" }]} />
        </div>
        <div className={styles["cert-tabs-content"]} ref={parent}>
          <Each
            of={visibleContent?.data}
            render={({ key, ...cert }) => (
              <CVCertItem
                key={key}
                {...cert}
                type={visibleContent.type}
                profile={profile}
                setShowEditCerts={setShowEditCerts}
              />
            )}
          />
          <div className={styles["certs-add-cert"]}>
            <Button
              className={styles["certs-add-cert-tbn"]}
              icon={faPlus}
              theme={"secondary"}
              onClick={() => {
                setShowEditCerts({ visible: true, mode: "Add", record: {} });
              }}
            >
              Add {visibleContent?.title}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CVTabs;
