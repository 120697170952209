import Searchbar from "src/pages/Shifter/Search/components/SearchBar/Searchbar";
import { useLocation } from "react-router-dom";

const MobileLocationSelector = () => {
  const { pathname } = useLocation();

  if (pathname.includes("business-account")) return null;

  return <Searchbar />;
};

export default MobileLocationSelector;
