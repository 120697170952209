import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ApplicantProfile.module.css";
import { faArrowLeft, faArrowRight, faCheck, faCheckDouble, faStar, faTimes } from "@fortawesome/pro-solid-svg-icons";
import Button from "src/components/UI/buttons/Button";
import { faComment } from "@fortawesome/pro-regular-svg-icons";
import Overlay from "src/components/UI/layout/Overlay";
import { calculateAgeInYears } from "src/utility/date";
import useFetch from "src/hooks/global/useFetch";
import { useRef, useState } from "react";
import Swiper from "src/components/layout/Swiper";
import Each from "src/components/func/Each";
import Toggle from "src/components/UI/tabs/Toggle";
import { truncate } from "src/utility/format";
import { useNavigate } from "react-router-dom";
import useStyling from "src/hooks/global/useStyling";
import withDisplay from "src/components/HOC/withDisplay";
import useShiftlyLocation from "src/hooks/business/useShiftlyLocation";

const ApplicantProfile = ({
  applicant = {},
  show = false,
  setShow = () => {},
  setShowConfirmModal = () => {},
  actionApplication = () => {},
  paymentMethod,
  showActions = true,
  isMobile,
}) => {
  const { activeLocation } = useShiftlyLocation();
  const { profile, user, _id, shift } = applicant || {};
  const [workTab, setWorkTab] = useState(0);
  const [swiper, setSwiper] = useState(null);
  const navigate = useNavigate();
  const styling = useStyling(styles);
  const shiftsWorkedRef = useRef(null);

  const intialRatingData = [
    {
      _id: "1",
      rating: 5,
      comment: ` Be the first to give ${user?.first_name} a review after completing this shift at ${activeLocation?.name}!`,
      logo: activeLocation?.logo,
    },
    {
      _id: "2",
      rating: 5,
      comment: ` Be the first to give ${user?.first_name} a review after completing this shift at ${activeLocation?.name}!`,
      logo: activeLocation?.logo,
    },
  ];

  const { data: shiftHistory } = useFetch({
    request: {
      entity: "ShiftHistory",
      criteria: { user: user?._id },
    },
    dependency: user?._id,
  });

  const { data: ratings } = useFetch({
    request: {
      entity: "Rating",
      criteria: { user: user?._id },
    },
    dependency: user?._id,
    options: {
      placeholderData: intialRatingData,
      select: (data) => {
        if (data.length) return data;

        return intialRatingData;
      },
    },
  });

  const { data: conversations } = useFetch({
    request: {
      entity: "Conversation",
      method: "getExistingConversations",
      criteria: {
        location: activeLocation?._id,
      },
      id: "Conversation.GetExistingConversations",
    },
    dependency: activeLocation?._id,
  });

  const { post: createNewConversation, refresh } = useFetch({
    options: {
      onSuccess: (data = []) => {
        refresh("Conversation.GetExistingConversations");
        navigate("/messaging?user=" + user?._id);
      },
    },
  });

  return (
    <>
      <Overlay open={show} setOpen={setShow} />
      <div className={styling("container", show && "visible")}>
        <div className={styling("wrapper")}>
          <FontAwesomeIcon icon={faTimes} className={styling("close-icon")} onClick={() => setShow(false)} />
          {/* Header */}
          <div className={styling("header")}>
            <div className={styling("cover-image")}>
              <img src={activeLocation?.images?.[1]} alt="cover" />
            </div>
            <div className={styling("profile-picture")}>
              <img src={profile?.profile_picture} alt="profile" />
            </div>
          </div>
          {/* User Details */}
          <div className={styling("user-details")}>
            <div className={styling("info-container")}>
              <div className={styling("personal")}>
                <h1 className={styling("name")}>
                  {user?.first_name} {user?.last_name}, {calculateAgeInYears(user?.date_of_birth)}
                </h1>
                <div className={styling("verified")}>
                  <FontAwesomeIcon icon={faCheckDouble} />
                </div>
              </div>
              <div className={styling("bio")}>
                <p>{profile?.bio}</p>
              </div>
              {showActions && (
                <>
                  {paymentMethod ? (
                    <div className={styling("action-buttons")}>
                      <Button
                        className={styling("action-button")}
                        theme="secondary-outline"
                        icon={faTimes}
                        onClick={() => actionApplication({ application_id: _id, user, shift, action: "declined" })}
                      >
                        Decline
                      </Button>
                      {isMobile && (
                        <SendMessageButton
                          conversations={conversations}
                          user={user}
                          createNewConversation={createNewConversation}
                          activeLocation={activeLocation}
                          navigate={navigate}
                        />
                      )}
                      <Button
                        theme="primary"
                        icon={faCheck}
                        className={styling("action-button")}
                        onClick={() => {
                          setShow(false);
                          setShowConfirmModal(true);
                        }}
                      >
                        Accept
                      </Button>
                    </div>
                  ) : (
                    <div className={styling("payment-method")}>
                      <p>Please add payment method to accept shifts.</p>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={styling("pictures-container")}>
              <SendMessageButton
                conversations={conversations}
                user={user}
                createNewConversation={createNewConversation}
                activeLocation={activeLocation}
                navigate={navigate}
              />
            </div>
          </div>
          {/* Status Bar */}
          <div className={styling("status-bar")}>
            <div className={styling("status", "status-rating")}>
              <p>{profile?.rating}</p>
              <FontAwesomeIcon icon={faStar} />
              <p>Rating</p>
            </div>
            <div
              className={styling("status", "status-shifts-worked")}
              onClick={() => {
                shiftsWorkedRef.current.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <p>{shiftHistory?.length} Shifts Worked</p>
            </div>
            <div className={styling("referal-container")}>
              <div className={styling("referal-control")} onClick={() => swiper?.slidePrev()}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>
              <div className={styling("referal-card")}>
                <Swiper
                  loop={true}
                  setSwiper={setSwiper}
                  hasPagination={false}
                  slides={ratings.map((review) => (
                    <div className={styling("slide-container")}>
                      <div className={styling("referal-image")}>
                        <img src={review?.logo} alt="Logo" />
                      </div>
                      <div className={styling("referal-text")}>
                        <em>{review.comment}</em>
                      </div>
                      <div className={styling("referal-rating")}>
                        {Array.from({ length: review.rating }).map((_, index) => (
                          <FontAwesomeIcon key={index} icon={faStar} />
                        ))}
                      </div>
                    </div>
                  ))}
                />
              </div>
              <div className={styling("referal-control")} onClick={() => swiper?.slideNext()}>
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
          </div>
          {/* Skills */}
          <div className={styling("skills-section")}>
            <div className={styling("skill")}>
              <h4>Qualifications</h4>
              <Each
                of={profile?.qualifications}
                render={(cert) => (
                  <div key={cert.name} className={styling("skill-row")}>
                    <h6>{truncate(`${cert.name} - ${cert.issuing_body}`)}</h6>
                    <p>{truncate(cert.description, 100)}</p>
                  </div>
                )}
              />
            </div>
          </div>
          {/* Work */}
          <div className={styling("work-container")} ref={shiftsWorkedRef}>
            <Toggle tab={workTab} setTab={setWorkTab} tabs={[{ text: "Shift History" }, { text: "Work Experience" }]} />
            {workTab === 0 && (
              <div className={styling("shift-history")}>
                <h3>{shiftHistory.length} Shifts Worked</h3>
                {shiftHistory.length ? (
                  <div className={styling("works")}></div>
                ) : (
                  <div className={styling("no-shifts-completed")}>
                    <em>{user?.first_name} hasn't completed her first shift yet.</em>
                    <strong>Get her started!</strong>
                  </div>
                )}
              </div>
            )}
            {workTab === 1 && (
              <div className={styling("work-experience")}>
                <h3>{profile.work_experience?.length || 0} Previous Employers</h3>
                {profile.work_experience?.length ? (
                  <div className={styling("works")}>
                    <Each
                      of={profile?.work_experience || []}
                      render={(work) => (
                        <div key={work.id} className={styling("work-item")}>
                          <p className={styling("work-title")}>{work.position}</p>
                          <p className={styling("work-sub")}>{work.venue}</p>
                          <p className={styling("work-desc")}>{truncate(work.description, 80)}</p>
                        </div>
                      )}
                    />
                  </div>
                ) : (
                  <div className={styling("no-shifts-completed")}>
                    <em>{user?.first_name} listed her work history yet.</em>
                    <strong>Check back here later!</strong>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const SendMessageButton = ({ conversations, user, createNewConversation, activeLocation, navigate }) => (
  <Button
    theme="secondary"
    icon={faComment}
    onClick={async () => {
      const existingConversation = conversations.find((conversation) => {
        return conversation.user?._id === user?._id;
      });

      if (existingConversation) {
        navigate("/messaging?user=" + user?._id);
        return;
      }

      createNewConversation({
        entity: "Conversation",
        method: "create",
        data: {
          user: user,
          location: activeLocation?._id,
          start_date: new Date(),
          last_sent: new Date(),
        },
      });
    }}
  >
    Send Message
  </Button>
);

export default withDisplay(ApplicantProfile);
