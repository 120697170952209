import { DataGrid } from "@mui/x-data-grid";
import { useMemo } from "react";
import styles from "./Table.module.css";
import clsx from "clsx";
const Table = ({ columns = [], rows = [], ...props }) => {
  const tableColumns = useMemo(() => {
    return columns.map((column) => {
      const col = { ...column };
      if (column.render) {
        col.renderCell = ({ row }) => {
          return (
            <div className={clsx(styles["cell"], styles[column.align || "left"])}>{(() => column.render(row))()}</div>
          );
        };
      }

      return col;
    });
  }, [columns]);

  return (
    <DataGrid
      columns={tableColumns}
      rows={rows}
      autoPageSize
      disableSelectionOnClick
      disableRowSelectionOnClick
      disableDensitySelector
      sx={{
        "& .MuiDataGrid-cell": {
          outline: "none !important",
          overflow: "visible",
        },
        "& .MuiDataGrid-row": {
          outline: "none !important",
          overflow: "visible",
        },
      }}
      {...props}
    />
  );
};

export default Table;
