import Button from "src/components/UI/buttons/Button";
import styles from "./Prompt.module.css";
import usePushNotification from "src/hooks/global/usePushNotifications";

import { useCallback } from "react";
import useUI from "src/hooks/global/useUI";
import clsx from "clsx";
import { motion } from "framer-motion";
import notificationsImage from "src/assets/png/NotificationsPrompt.png";

const Prompt = ({ show, setShow }) => {
  const { requestPermission } = usePushNotification();
  const { updateSetting } = useUI();

  const handlePromptForNotifications = useCallback(async () => {
    updateSetting({ setting: "push_notifications_prompted", value: true });

    await requestPermission();
    setShow(false);
  }, [updateSetting, requestPermission, setShow]);

  return (
    <motion.div
      initial={{
        y: "100%",
      }}
      animate={{
        y: show ? 0 : "100%",
      }}
      transition={{
        duration: 0.3,
      }}
      className={clsx(styles["container"])}
    >
      <div className={styles["icon"]}>
        <img src={notificationsImage} alt="Notification Bell" />
      </div>
      <div className={styles["content"]}>
        <h2>Did you get the job?</h2>
        <p>Get notified when you're approved for this shift, or when an employer wants to contact you.</p>
      </div>
      <div className={styles["buttons"]}>
        <Button onClick={handlePromptForNotifications}>Yes, let me know!</Button>
        <p
          onClick={() => {
            setShow(false);
          }}
        >
          Maybe later
        </p>
      </div>
    </motion.div>
  );
};

export default Prompt;
