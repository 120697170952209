import styles from "./ShiftsHome.module.css";
import useFetch from "src/hooks/global/useFetch";
import useAuth from "src/hooks/global/useAuth";
import clsx from "clsx";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import Button from "src/components/UI/buttons/Button";
import Illustration from "src/assets/svg/Illustrations/NoShifts.svg";
import { useMemo, useState } from "react";
import Each from "src/components/func/Each";
import SignOn from "./components/SignOn";
import UpcomingShifts from "./components/UpcomingShifts";
import ShiftDetails from "../Search/components/ShiftDetails/ShiftDetails";
import BottomLink from "src/components/misc/BottomLink";
import useSearchParams from "src/hooks/global/useSearchParams";

const ShiftsHome = () => {
  const { user } = useAuth();
  const [showDetails, setShowDetails] = useState(false);

  const { data: shifts, isLoading } = useFetch({
    request: {
      entity: "Shift",
      method: "getWithCalculatedFields",
      criteria: {
        status: "confirmed",
        user: user?._id,
      },
      data: {
        user,
      },
      id: "Shift.GetUpcomingShifts",
    },
    dependency: user?._id,
  });

  const { post: fetchShift } = useFetch({
    options: {
      onSuccess: (data) => {
        setShowDetails(data[0] || false);
      },
    },
  });

  const todayShift = useMemo(() => {
    return shifts?.filter((shift) => new Date(shift.date).toDateString() === new Date().toDateString());
  }, [shifts]);

  useSearchParams(["shift"], ({ shift: shiftID }) => {
    if (shiftID) {
      fetchShift({
        entity: "Shift",
        method: "getWithCalculatedFields",
        criteria: { _id: shiftID },
      });
    }
  });

  return (
    <>
      <ShiftDetails showDetails={showDetails} setShowDetails={setShowDetails} minimised />
      <div className={styles["container"]}>
        <div className={clsx(styles["shifts-section"], !isLoading && !shifts.length && styles["no-shifts"])}>
          {isLoading ? (
            <>
              <LoadingContainer />
            </>
          ) : shifts.length > 0 ? (
            <>
              {todayShift.length > 0 && (
                <>
                  <div className={styles["heading-today"]}>
                    <h3>Today</h3>
                    <div className={styles["badge"]}>{todayShift.length}</div>
                  </div>
                  <Each
                    of={todayShift}
                    render={({ key, ...shift }) => (
                      <SignOn key={key} shift={shift} todayCount={todayShift.length} setShowDetails={setShowDetails} />
                    )}
                  />
                </>
              )}
              <UpcomingShifts
                shifts={shifts.filter((s) => !todayShift.map((sh) => sh._id).includes(s._id))}
                setShowDetails={setShowDetails}
              />
            </>
          ) : (
            <div className={styles["no-shifts"]}>
              <div className={styles["no-heading"]}>
                <h3>Upcoming shifts</h3>
                <p>You have no upcoming shifts.</p>
              </div>

              <div className={styles["no-image"]}>
                <img src={Illustration} alt={"No upcoming shifts"} />
              </div>
              <div className={styles["no-button"]}>
                <Button link={"/"} theme={"primary"}>
                  Search for shifts
                </Button>
              </div>
            </div>
          )}
          <div className={styles["bottom-links"]}>
            <BottomLink
              floating={false}
              links={[
                { name: "Shift History", path: "/shift-history" },
                { name: "Payments", path: "/payments" },
                { name: "Trashed Shifts", path: "/trashed-shifts" },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ShiftsHome;

const LoadingContainer = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles["loading-container"]}
    >
      <div className={styles["loading-card"]}>
        <div className={styles["loading-spinner"]}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      </div>
    </motion.div>
  );
};
