import { faPencil } from "@fortawesome/pro-light-svg-icons";
import Dropdown from "src/components/UI/inputs/Dropdown";
import useShiftlyLocation from "src/hooks/business/useShiftlyLocation";
import { useNavigate } from "react-router-dom";

const BusinessLocationSelector = ({ locationFilter, setlocationFilter, setShowNav = () => {} }) => {
  const { allLocations, activeLocation } = useShiftlyLocation();
  const navigate = useNavigate();
  return (
    <>
      <Dropdown
        specialButton={{
          icon: faPencil,
          action: () => {
            navigate("/business-account/locations/edit-location", { state: { location: activeLocation } });
            setShowNav(false);
          },
        }}
        options={allLocations.map((location) => ({ label: location.name, value: location._id }))}
        value={locationFilter}
        setValue={setlocationFilter}
      />
    </>
  );
};

export default BusinessLocationSelector;
