import { useMemo, useRef } from "react";
import IntegrationBase from "./components/IntegrationBase";
import useBusiness from "src/hooks/business/useBusiness";
import useFetch from "src/hooks/global/useFetch";
import useSearchParams from "src/hooks/global/useSearchParams";
import LoadingSpinner from "src/components/animations/LoadingSpinner";
import useIntegration from "src/hooks/business/useIntegration";

const buildDeputyOAuthUrl = () => {
  const params = new URLSearchParams({
    client_id: process.env.REACT_APP_DEPUTY_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_FRONT_END_URL + "integrations/deputy",
    response_type: "code",
    scope: "longlife_refresh_token",
  });

  return `https://once.deputy.com/my/oauth/login?${params.toString()}`;
};

const Deputy = () => {
  const { activeBusiness } = useBusiness();
  const link = useMemo(() => buildDeputyOAuthUrl(), []);
  const connectedRef = useRef(false);

  const { activeIntegration, refetch, isLoading: fetchingIntegration } = useIntegration("deputy");

  const { post: createIntegration, isLoading } = useFetch({
    options: {
      onSuccess: () => {
        refetch();
      },
    },
  });

  useSearchParams(
    ["code"],
    ({ code }) => {
      if (code && !activeIntegration && !fetchingIntegration && !connectedRef.current && activeBusiness?._id) {
        connectedRef.current = true;
        createIntegration({
          entity: "Integration",
          method: "init",
          data: {
            business: activeBusiness?._id,
            code,
            type: "deputy",
          },
        });
      }
    },
    [activeIntegration, activeBusiness, fetchingIntegration]
  );

  const { data } = useFetch({
    request: {
      entity: "Integration",
      method: "getElements",
      data: {
        type: "deputy",
        business: activeBusiness?._id,
      },
    },
    dependency: activeBusiness?._id,
    options: {
      enabled: Boolean(activeIntegration),
    },
  });

  return fetchingIntegration ? (
    <LoadingSpinner />
  ) : (
    <IntegrationBase
      name={"Deputy"}
      type={"deputy"}
      description={
        <p>
          With our Shiftly - Deputy integration, you can effortlessly import your shifts from Deputy into Shiftly for
          display purposes. This allows you to easily view all your scheduled shifts in one convenient place—your
          Shiftly calendar.
        </p>
      }
      connectionLink={link}
      isLoading={isLoading}
      activeIntegration={activeIntegration}
      thirdPartyLocations={data.locations || []}
      thirdPartyPositions={data.positions || []}
    ></IntegrationBase>
  );
};

export default Deputy;
