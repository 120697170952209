import { useCallback, useRef, useState } from "react";
import styles from "./Portal.module.css";
import clsx from "clsx";
import Input from "src/components/UI/inputs/Input";
import FormFlex from "src/components/UI/layout/FormFlex";
import Button from "src/components/UI/buttons/Button";
import Link from "src/components/func/Link";
import { motion } from "framer-motion";
import useAuth from "src/hooks/global/useAuth";
import withDisplay from "src/components/HOC/withDisplay";
import FormV2 from "src/components/func/FormV2";

const SignUpBusiness = ({ setPage, isLaptop }) => {
  const [tab, setTab] = useState(0);
  const formRef = useRef();
  const { signup, signupIsLoading: isLoading } = useAuth();

  const onSubmit = useCallback(
    (data) => {
      return signup(data, "owner");
    },
    [signup]
  );

  const handleNextClick = useCallback(async () => {
    return (await formRef.current?.validateFields(["first_name", "last_name", "email"])) && setTab(1);
  }, []);

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={styles["container"]}
    >
      <div className={styles["content"]}>
        <div className={styles["heading"]}>
          <h2>
            Welcome to <span>Shiftly</span>
          </h2>
          <p>Create an account to access more with shiftly. Don't worry, it'l only take a few seconds!</p>
          {isLaptop && (
            <div className={styles["icons"]}>
              <div className={clsx(styles["icon"], tab === 0 && styles["active"])}>
                <div className={styles["circle"]}>
                  <p>1</p>
                </div>
                <p>Your Details</p>
              </div>
              <div className={clsx(styles["icon"], tab === 1 && styles["active"])}>
                <div className={styles["circle"]}>
                  <p>2</p>
                </div>
                <p>Password</p>
              </div>
            </div>
          )}
        </div>
        <FormV2 onSubmit={onSubmit} ref={formRef}>
          <div className={styles["content-container"]}>
            <motion.div
              animate={{
                x: tab === 0 ? 0 : "-100%",
                opacity: tab === 0 ? 1 : 0,
              }}
              transition={{
                duration: 0.3,
              }}
              className={clsx(styles["form-content"], styles["details-container"], tab === 0 && styles["activeSlide"])}
            >
              <FormFlex>
                <Input name={"first_name"} />
                <Input name={"last_name"} />
              </FormFlex>
              <div className={styles["divider"]}></div>
              <Input name={"email"} onEnterPress={handleNextClick} />
            </motion.div>
            <motion.div
              initial={{
                x: "100%",
                opacity: 0,
              }}
              animate={{
                x: tab === 1 ? 0 : "100%",
                opacity: tab === 1 ? 1 : 0,
              }}
              transition={{
                duration: 0.3,
              }}
              className={clsx(styles["form-content"], styles["password-container"], tab === 1 && styles["activeSlide"])}
            >
              <Input type={"password"} label={"Password"} placeholder={"Password"} name={"password"} />
              <Input
                type={"password"}
                label={"Confirm Password"}
                placeholder={"Password"}
                name={"confirmPassword"}
                submitOnEnter
                required
                validate={(v, data) => {
                  if (v !== data.password) return "Passwords do not match";
                  return true;
                }}
              />
            </motion.div>
          </div>
          <div className={styles["buttons-container"]}>
            <Button theme={"secondary-outline"} disabled={tab === 0} onClick={() => setTab(0)}>
              Previous
            </Button>
            <Button
              loading={isLoading}
              theme={"primary"}
              onClick={() => (tab === 1 ? formRef.current?.submit() : handleNextClick())}
            >
              {tab === 1 ? "Signup" : "Next"}
            </Button>
          </div>

          <div className={styles["divider"]}></div>
          <div className={styles["signup"]}>
            <p>Already have an account?</p>
            <span>
              <Link onClick={() => setPage("login")}>Login</Link>
            </span>
          </div>
        </FormV2>
      </div>
    </motion.div>
  );
};

export default withDisplay(SignUpBusiness);
