import Button from "src/components/UI/buttons/Button";
import styles from "./ShiftDetails.module.css";
import { useNavigate } from "react-router-dom";
const ShiftDetailsContactEmployer = ({ logo, location, ...props }) => {
  const navigate = useNavigate();
  return (
    <div className={styles["employer-container"]}>
      <div className={styles["employer-logo"]}>
        <img src={logo} alt="Employer Logo" />
      </div>
      <h4>Contact Employer</h4>
      <p>Find out more about this shift</p>
      <Button
        className={styles["employer-send-button"]}
        theme={"secondary"}
        onClick={() => navigate("/inbox?location=" + location?._id)}
      >
        Send Private Message
      </Button>
    </div>
  );
};

export default ShiftDetailsContactEmployer;
