import { useCallback, useMemo } from "react";
import useAuth from "../global/useAuth";
import useFetch from "../global/useFetch";

const useProfile = ({ criteria, dependency } = {}) => {
  const { user } = useAuth();

  const {
    post,
    updateCache,
    isLoading: updatingProfile,
    isError,
  } = useFetch({
    options: {
      onSuccess: (data) => {
        updateCache("Profile.GetUserProfile", data);
      },
    },
  });

  const {
    data: [profile],
    isLoading,
  } = useFetch({
    request: {
      entity: "Profile",
      method: "get",
      criteria: { user: user?._id, ...criteria },
      id: "Profile.GetUserProfile",
    },
    dependency: dependency || user?._id,
  });

  const updateProfile = useCallback(
    async ({ criteria = {}, data = {} } = {}) => {
      return await post({
        entity: "Profile",
        method: "update",
        criteria: { user: user?._id, ...criteria },
        data,
      });
    },
    [user, post]
  );

  const updateProfilePic = useCallback(
    (url) => {
      url[0] &&
        updateProfile({
          data: { profile_picture: url[0] },
        });
    },
    [updateProfile]
  );

  return useMemo(
    () => ({ profile, isLoading, updateProfile, updateProfilePic, updatingProfile, isError }),
    [profile, updateProfilePic, isLoading, updateProfile, updatingProfile, isError]
  );
};

export default useProfile;
